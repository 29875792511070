import React, { useState } from "react";
import _ from "lodash";
import { Alert, Button, ButtonGroup, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import * as ciAPI from "api2/customer-invoices";
import { AllError, FormGroup } from "components/formik";
import { DraggableModalDialog } from "../DraggableModalDialog";

function SendInvoiceModal({ handleClose, companyId, invoiceData }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const formikProps = {
    initialValues: {
      method: "email",
      emails: invoiceData.customer_emails,
    },
    onSubmit: async (values, { setErrors }) => {
      setLoading(true);
      return ciAPI.invoice
        .send(companyId, invoiceData.id, { delivery_method: values.method, emails: values.emails })
        .then((response) => {
          toast.success(t("msg:sent"));
          handleClose(true);
        })
        .catch((error) => {
          setLoading(false);
          setErrors(error.data);
          // handleActionErrors(error);
        });
    },
  };

  const emailsChanged = (newEmails) => {
    return !_.isEqual(newEmails, formikProps.initialValues.emails);
  };

  return (
    <Modal show onHide={handleClose} size="lg" scrollable dialogAs={DraggableModalDialog}>
      <Formik {...formikProps}>
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title className="m-0">{t("ci:actions.sendInvoiceToCustomer")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DeliveryMethodSwitcher
                t={t}
                deliveryMethod={values.method}
                changeDeliveryMethod={(newMethod) => setFieldValue("method", newMethod)}
              />
              {values.method === "email" && (
                <>
                  <div className="mt-4">
                    <FormGroup.MultiEmail
                      name="emails"
                      label={t("common:contact.emailLong")}
                      helpText={t("ci:helpEmail")}
                      showAddMe={false}
                      required
                    />
                  </div>
                  {emailsChanged(values.emails) && <Alert variant="info">{t("ci:rememberCustomerEmail")}</Alert>}
                </>
              )}
              <AllError className="mt-2" errors={errors} />
            </Modal.Body>
            <Modal.Footer className="space">
              <Button type="button" variant="secondary" onClick={handleClose}>
                {t("actions.cancel")}
              </Button>
              {loading ? (
                <Button type="button" variant="red" disabled>
                  <i className="fas fa-spinner fa-pulse" /> {t("common:actions.processing")}...
                </Button>
              ) : (
                <Button type="submit" variant="red">
                  <span>
                    <i className="fas fa-exclamation" /> {t("actions.yesExecute")}
                  </span>
                </Button>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

function DeliveryMethodSwitcher({ deliveryMethod, changeDeliveryMethod, t }) {
  return (
    <div>
      <ButtonGroup aria-label="Status filter">
        <Button variant="switch" active={deliveryMethod === "email"} onClick={() => changeDeliveryMethod("email")}>
          <i className="fas fa-at" /> {t("ci:actions.sendEmail")}
        </Button>
        <Button
          variant="switch"
          active={deliveryMethod === "einvoice"}
          onClick={() => changeDeliveryMethod("einvoice")}
        >
          <i className="fas fa-desktop" /> {t("ci:actions.sendEInvoice")}
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default SendInvoiceModal;
