import React from "react";
import { useNavigate, useNavigationType, useOutletContext, useParams } from "react-router-dom";

import useInitialAsync from "hooks/useInitialAsync";
import { RocketLoader } from "components/ui/loaders";
import CustomerInvoiceForm from "components/forms/CustomerInvoiceForm";
import * as ciAPI from "api2/customer-invoices";

function CustomerInvoiceEditPage() {
  const company = useOutletContext();
  const navigate = useNavigate(); // useNavigationType
  const navigateType = useNavigationType();
  const { id: verificationId } = useParams();
  const { loading, item } = useInitialAsync(
    ({ cancelToken }) => {
      return ciAPI.invoice.details(company.id, verificationId, { cancelToken });
    },
    {},
    [company, verificationId]
  );
  const onUpdate = (invoice) => {
    if (navigateType !== "POP") {
      if (invoice?.draft === false && invoice?.delivery_method === "manual") {
        navigate(`/invoicing/customer-invoices/${invoice.id}/preview`, { replace: true });
      } else {
        navigate(-1);
      }
    } else {
      navigate("/invoicing/customer-invoices", { replace: true });
    }
  };

  if (loading) {
    return <RocketLoader />;
  }
  const connectedProducts = item.connected_products || {};
  delete item.connected_products;
  return (
    <CustomerInvoiceForm
      invoice={item}
      forCreditInvoice={!!item.credit_for}
      invoiceConnectedProducts={connectedProducts}
      company={company}
      onSave={onUpdate}
    />
  );
}

export default CustomerInvoiceEditPage;
