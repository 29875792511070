import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { OfferTable } from "components/tables";
import * as offerAPI from "api2/offers";
import { useOutletContext } from "react-router-dom";

function OfferListPage({ tableId = "offers" }) {
  const company = useOutletContext();
  const initialFilters = { mode: "active" };
  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => offerAPI.companyOffers(company.id, filters, config)}
          initialFilters={initialFilters}
        >
          <OfferTable companyId={company.id} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default OfferListPage;
