import React from "react";
import { Navigate } from "react-router-dom";

import {
  AuthLayout,
  BankIDLoginPage,
  EmailPasswordLoginPage,
  EmpNotificationTurnedOffPage,
  LogoutPage,
  PasswordForgotPage,
  PasswordResetPage,
  PasswordResetSuccessPage,
} from "pages/auth";
import { useTranslation } from "react-i18next";
import StatusListLayout from "components/layouts/StatusListLayout";
import ExportPage from "pages/companies/ExportPage/ExportPage";
import CompanySettingsLayout from "components/layouts/CompanySettingLayout";
import CompanySettingProvider from "state/providers/CompanySettingProvider";
import AgencySettingsLayout from "components/layouts/AgencySettingLayout";
import TimeTrackerLayout, { TimeTrackerCommonFilters } from "components/layouts/TimeTrackerLayout";
import ProtectedRoute from "./helpers/ProtectedRoute";
import {
  CompanyListPage,
  DayToDayOperationsListPage,
  GlobalDraftSIListPage,
  ProfitabilityListPage,
  YearEndClosureListPage,
} from "../pages/companies";
import {
  AgencyAdminPage,
  AgencyConsultPermPage,
  AgencyFormPage,
  AgencyListPage,
  AgencyScheduleTemplatePage,
  AgencyUserListPage,
  OfficeListPage,
} from "../pages/agencies";
import FilePreviewPage from "../pages/file-preview/FilePreviewPage";
import {
  AccountPlanListPage,
  AdminFormPage,
  AnnualReportFormPage,
  AuditorListPage,
  BankMatchingRulesPage,
  CostCenterListPage,
  EmployeeListPage,
  FinancialYearListPage,
  GeneralFormPage,
  HistoryListPage,
  InvoicingFormPage,
  LogoFormPage,
  ProjectListPage,
  SIEFileListPage,
  TimeReportingFormPage,
  TimeTrackerSettingsPage,
} from "../pages/companies/settings";
import BMDLayout from "../components/layouts/BMDLayout";
import AdminLayout from "../components/layouts/AdminLayout";
import { AdminUsageReportPage, AdminUserListPage, ImportCompaniesCSVPage } from "../pages/admin";
import { EventAddPage, EventListPage, GlobalSearchPage, TrashListPage } from "../pages/dashboard/admin";
import StatsLayout from "../components/layouts/StatsLayout";
import {
  StatsCIPage,
  StatsCompanyPage,
  StatsOtherPage,
  StatsOutlaysPage,
  StatsSalariesPage,
  StatsSIPage,
  StatsUsersPage,
  StatsVerificationPage,
} from "../pages/dashboard/stats";
import {
  TimeTrackerByCompanyPage,
  TimeTrackerCalendarPage,
  TimeTrackerConsultReportPage,
  TimeTrackerLogsPage,
  TimeTrackerTimeReportPage,
} from "../pages/time-tracker";
import DashboardLayout from "../components/layouts/DashboardLayout";
import HomePage from "../pages/dashboard/home";
import CashFlowPage from "../pages/dashboard/cashflow";
import {
  ApproveEmployeeAbsenceListPage,
  ApproveOutlayListPage,
  ApproveSalaryListPage,
  ApproveSupplierInvoiceListPage,
  DirectPaymentAddPage,
  DirectPaymentListPage,
  EmployeeAbsenceListPage,
  GlobalSupplierInvoiceEditPage,
  HolidaySocAddPage,
  MileageAddPage,
  MileageEditPage,
  OutlayListPage,
  OutlayPreviewPage,
  PayDirectPaymentListPage,
  PayOutlayListPage,
  PaySalaryListPage,
  PaySupplierInvoiceListPage,
  PayTaxDirectPaymentListPage,
  ReceiptAddPage,
  ReceiptEditPage,
  SalaryAddPage,
  SalaryEditPage,
  SalaryListPage,
  SalaryPreviewPage,
  SupplierInvoiceAddPage,
  SupplierInvoiceEditPage,
  SupplierInvoiceListPage,
  SupplierInvoicePreviewPage,
  SupplierListPage,
  TaxAuthorityAddPage,
  TaxAuthorityListPage,
  TaxAuthorityPreviewPage,
  TaxDirectPaymentAddPage,
  TaxDirectPaymentListPage,
} from "../pages/dashboard/expenses";
import PageNotFound from "../pages/PageNotFound";
import {
  AssetListPage,
  BankMatchingPage,
  IsoBasketListPage,
  SilverbackAddPage,
  SilverbackEditPage,
  SilverbackListPage,
} from "../pages/dashboard/others";
import { SelectionProvider } from "../state/providers/SelectionProvider";
import { CustomerInvoiceEditPage } from "../pages/dashboard/invoicing/CustomerInvoiceEditPage";
import {
  ContractInvoiceAddPage,
  ContractInvoiceDuplicatePage,
  ContractInvoiceEditPage,
  ContractInvoiceListPage,
  ContractTemplateAddPage,
  ContractTemplateDuplicatePage,
  ContractTemplateEditPage,
  ContractTemplateListPage,
  CustomerInvoiceAddPage,
  CustomerInvoiceCreditPage,
  CustomerInvoiceDuplicatePage,
  CustomerInvoiceListPage,
  CustomerInvoicePreviewPage,
  CustomerListPage,
  OfferAddPage,
  OfferDuplicatePage,
  OfferEditPage,
  OfferListPage,
  OrderAddPage,
  OrderDuplicatePage,
  OrderEditPage,
  OrderListPage,
  ProductListPage,
  RotRutListPage,
} from "../pages/dashboard/invoicing";
import InvoicingLayout from "../components/layouts/InvoicingLayout";
import ApproveLayout from "../components/layouts/ApproveLayout";
import SupplierInvoiceLayout from "../components/layouts/SupplierInvoiceLayout";
import AccountingReportsLayout from "../components/layouts/AccountingReportsLayout";
import {
  BalanceReportPage,
  LedgerReportPage,
  ProfitLoss12ReportPage,
  ProfitLossReportPage,
  ReconciliationPage,
  TimeReportListPage,
  VATReportListPage,
  VerificationListPage,
} from "../pages/dashboard/reports";
import {
  CompanyArchiveAddPage,
  CompanyArchiveEditPage,
  CompanyArchiveListPage,
  ConsultArchiveAddPage,
  ConsultArchiveEditPage,
  ConsultArchiveListPage,
  FileArchiveAddPage,
  FileArchiveEditPage,
  FileArchiveListPage,
  SalaryArchiveAddPage,
  SalaryArchiveEditPage,
  SalaryArchiveListPage,
} from "../pages/dashboard/archive";
import CompanyArchiveLayout from "../components/layouts/CompanyArchiveLayout";
import UploadLayout from "../components/layouts/UploadLayout";
import { UploadFileArchivePage, UploadOutlayPage, UploadSupplierInvoicePage } from "../pages/dashboard/upload";
import GlobalSupplierListPage from "../pages/dashboard/others/GlobalSupplierListPage/GlobalSupplierListPage";
import PayLayout from "../components/layouts/PayLayout";
import PendingLayout from "../components/layouts/PendingLayout";
import OnboardingProgressPage from "../pages/companies/OnboardingProgressPage";
import OnboardingWizardPage from "../pages/companies/OnboardingWizard";
import BankConnectionsPage from "../pages/companies/settings/BankConnectionsPage";
import EuVatPage from "../pages/companies/EuVatPage";
import CompanyArchiveAuditorPage from "../pages/dashboard/archive/CompanyArchiveListPage/CompanyArchiveAuditorPage";
import CompanyArchiveBasicPage from "../pages/dashboard/archive/CompanyArchiveListPage/CompanyArchiveBasicPage";
import CompanyArchiveYearlyPage from "../pages/dashboard/archive/CompanyArchiveListPage/CompanyArchiveYearlyPage";
import BudgetLayout from "../components/layouts/BudgetLayout";
import BudgetPLYearly from "../pages/companies/settings/BudgetPage/BudgetPLYearly";
import BudgetPLMonthly from "../pages/companies/settings/BudgetPage/BudgetPLMonthly";
import AgaPdfReductionAddPage from "../pages/dashboard/expenses/AgaPdfReductionAddPage/AgaPdfReductionAddPage";
import { OverviewPage } from "../pages/companies/ManagementDashboard";
import ManagementDashboardLayout from "../components/layouts/ManagementDashboardLayout/ManagementDashboardLayout";
import CompanyCardLayout from "../components/layouts/OfficeSupport/CompanyCardLayout";
import OfficeSupportLayout from "../components/layouts/OfficeSupport";
import {
  BusinessProfilePage,
  ClientDetailsPage,
  OfficeSupportCompanyListPage,
  OfficeSupportCompanyNotesPage,
  OfficeSupportKYCPage,
  RolesPage,
  StatusListConfigPage,
} from "../pages/office-support";
import { useCompanyState } from "../hooks/useCompany";
import OfficeSupportCompanyAssessmentsPage from "../pages/office-support/OfficeSupportCompanyDetailsPage/OfficeSupportCompanyAssessmentsPage";
import OfficeSupportExportPage from "../pages/office-support/OfficeSupportExportPage/OfficeSupportExportPage";
import StatusListPage from "../pages/office-support/StatusListPage/StatusListPage";
import ApprovalFlowsPage from "../pages/companies/settings/ApprovalFlowsPage";

const BS_CREATE = "Create";
const BS_CHANGE = "Change";
const BS_PREVIEW = "Preview";
const BS_DUPLICATE = "Duplicate";
const section = { props: { section: true } };

const allowedModesPending = [
  { value: "payments", label: "common:statuses.all", variant: "toggle" },
  { value: "sent_bank", label: "common:statuses.sent_bank", variant: "toggle-blue" },
  { value: "paid", label: "common:statuses.paid", variant: "toggle-green" },
];

const supplierInvoices = {
  path: "supplier-invoices",
  children: [
    { path: "form", element: <SupplierInvoiceAddPage />, breadcrumb: BS_CREATE },
    {
      path: ":id",
      breadcrumb: null,
      children: [
        { path: "preview", element: <SupplierInvoicePreviewPage />, breadcrumb: BS_PREVIEW },
        { path: "change", element: <SupplierInvoiceEditPage />, breadcrumb: BS_CHANGE },
        { path: "drafts", element: <GlobalSupplierInvoiceEditPage />, breadcrumb: BS_CHANGE },
      ],
    },
  ],
};

const salaries = {
  path: "salaries",
  children: [
    { path: "aga-pdf-reduction", element: <AgaPdfReductionAddPage />, breadcrumb: "AGA pdf & reduction" },
    { path: "holiday-soc", element: <HolidaySocAddPage />, breadcrumb: "Holiday all & soc." },
    { path: "form", element: <SalaryAddPage />, breadcrumb: BS_CREATE },
    {
      path: ":id",
      breadcrumb: null,
      children: [
        { path: "preview", element: <SalaryPreviewPage />, breadcrumb: BS_PREVIEW },
        { path: "change", element: <SalaryEditPage />, breadcrumb: BS_CHANGE },
      ],
    },
  ],
};

const customerInvoices = {
  path: "invoicing",
  children: [
    {
      element: <InvoicingLayout />,
      breadcrumb: null,
      children: [
        { path: "customers", element: <CustomerListPage /> },
        { path: "products", element: <ProductListPage /> },
        {
          path: "customer-invoices",
          element: (
            <SelectionProvider>
              <CustomerInvoiceListPage />
            </SelectionProvider>
          ),
        },
        {
          path: "rot-rut",
          element: <RotRutListPage />,
          breadcrumb: "ROT/RUT",
        },
        {
          path: "contract-invoices",
          element: <ContractInvoiceListPage />,
        },
        {
          path: "contract-templates",
          element: <ContractTemplateListPage />,
        },
        {
          path: "offer",
          element: <OfferListPage />,
          breadcrumb: "Offer",
        },
        {
          path: "order",
          element: <OrderListPage />,
          breadcrumb: "Order",
        },
      ],
    },
    {
      path: "customer-invoices",
      breadcrumb: null,
      children: [
        { path: "form", element: <CustomerInvoiceAddPage />, breadcrumb: BS_CREATE },
        {
          path: ":id",
          breadcrumb: null,
          children: [
            { path: "preview", element: <CustomerInvoicePreviewPage />, breadcrumb: BS_PREVIEW },
            { path: "change", element: <CustomerInvoiceEditPage />, breadcrumb: BS_CHANGE },
            { path: "duplicate", element: <CustomerInvoiceDuplicatePage />, breadcrumb: BS_DUPLICATE },
            { path: "credit", element: <CustomerInvoiceCreditPage />, breadcrumb: "Credit" },
          ],
        },
      ],
    },
    {
      path: "contract-invoices",
      breadcrumb: null,
      children: [
        { path: "form", element: <ContractInvoiceAddPage />, breadcrumb: BS_CREATE },
        {
          path: ":id",
          breadcrumb: null,
          children: [
            { path: "change", element: <ContractInvoiceEditPage />, breadcrumb: BS_CHANGE },
            { path: "duplicate", element: <ContractInvoiceDuplicatePage />, breadcrumb: BS_DUPLICATE },
          ],
        },
      ],
    },
    {
      path: "contract-templates",
      breadcrumb: null,
      children: [
        { path: "form", element: <ContractTemplateAddPage />, breadcrumb: BS_CREATE },
        {
          path: ":id",
          breadcrumb: null,
          children: [
            { path: "change", element: <ContractTemplateEditPage />, breadcrumb: BS_CHANGE },
            { path: "duplicate", element: <ContractTemplateDuplicatePage />, breadcrumb: BS_DUPLICATE },
          ],
        },
      ],
    },
    {
      path: "offer",
      breadcrumb: null,
      children: [
        { path: "form", element: <OfferAddPage />, breadcrumb: BS_CREATE },
        {
          path: ":id",
          breadcrumb: null,
          children: [
            { path: "change", element: <OfferEditPage />, breadcrumb: BS_CHANGE },
            { path: "duplicate", element: <OfferDuplicatePage />, breadcrumb: BS_DUPLICATE },
          ],
        },
      ],
    },
    {
      path: "order",
      breadcrumb: null,
      children: [
        { path: "form", element: <OrderAddPage />, breadcrumb: BS_CREATE },
        {
          path: ":id",
          breadcrumb: null,
          children: [
            { path: "change", element: <OrderEditPage />, breadcrumb: BS_CHANGE },
            { path: "duplicate", element: <OrderDuplicatePage />, breadcrumb: BS_DUPLICATE },
          ],
        },
      ],
    },
  ],
};

const accountingReports = {
  element: <AccountingReportsLayout />,
  children: [
    {
      path: "balance",
      children: [
        { index: true, element: <BalanceReportPage /> },
        {
          path: "reconciliation",
          breadcrumb: null,
          children: [
            {
              path: ":number",
              breadcrumb: null,
              children: [
                { index: true, element: <PageNotFound />, breadcrumb: null },
                {
                  path: ":date",
                  element: <ReconciliationPage />,
                  breadcrumb: "Reconciliation",
                },
              ],
            },
          ],
        },
      ],
    },
    { path: "profit-and-loss", element: <ProfitLossReportPage /> },
    { path: "profit-and-loss/year", element: <ProfitLoss12ReportPage />, breadcrumb: null },
    {
      path: "ledger",
      element: <LedgerReportPage />,
      children: [{ path: ":number", element: <LedgerReportPage />, breadcrumb: null }],
    },
  ],
};

const outlays = {
  path: "outlays",
  breadcrumb: "Outlays",
  children: [
    {
      path: "receipt",
      breadcrumb: null,
      children: [
        { path: "form", element: <ReceiptAddPage />, breadcrumb: BS_CREATE },
        {
          path: ":id",
          breadcrumb: null,
          children: [
            { path: "preview", element: <OutlayPreviewPage type="R" />, breadcrumb: BS_PREVIEW },
            { path: "change", element: <ReceiptEditPage />, breadcrumb: BS_CHANGE },
          ],
        },
      ],
    },
    {
      path: "mileage",
      breadcrumb: null,
      children: [
        { path: "form", element: <MileageAddPage />, breadcrumb: BS_CREATE },
        {
          path: ":id",
          breadcrumb: null,
          children: [
            { path: "preview", element: <OutlayPreviewPage type="M" />, breadcrumb: BS_PREVIEW },
            { path: "change", element: <MileageEditPage />, breadcrumb: BS_CHANGE },
          ],
        },
      ],
    },
  ],
};

const archiveRoutes = {
  children: [
    {
      path: "file-archive",
      children: [
        { index: true, element: <FileArchiveListPage /> },
        { path: "form", element: <FileArchiveAddPage />, breadcrumb: BS_CREATE },
        {
          path: ":id",
          breadcrumb: null,
          children: [{ path: "change", element: <FileArchiveEditPage />, breadcrumb: BS_CHANGE }],
        },
      ],
    },
    {
      path: "company-archive",
      children: [
        {
          element: <CompanyArchiveLayout />,
          children: [
            { index: true, element: <CompanyArchiveListPage /> },
            {
              path: "basic",
              element: <CompanyArchiveBasicPage />,
            },
            {
              path: "yearly",
              element: <CompanyArchiveYearlyPage />,
            },
            {
              path: "auditor",
              element: <CompanyArchiveAuditorPage />,
            },
          ],
        },
        { path: "form", element: <CompanyArchiveAddPage />, breadcrumb: BS_CREATE },
        {
          path: ":id",
          breadcrumb: null,
          children: [{ path: "change", element: <CompanyArchiveEditPage />, breadcrumb: BS_CHANGE }],
        },
      ],
    },
    {
      path: "salary-archive",
      children: [
        { index: true, element: <SalaryArchiveListPage /> },
        { path: "form", element: <SalaryArchiveAddPage />, breadcrumb: BS_CREATE },
        {
          path: ":id",
          breadcrumb: null,
          children: [{ path: "change", element: <SalaryArchiveEditPage />, breadcrumb: BS_CHANGE }],
        },
      ],
    },
  ],
};

const employeeAbsences = {
  path: "employee-absence",
  children: [{ index: true, element: <EmployeeAbsenceListPage /> }],
};

function CompanyNameBreadcrumb({ match }) {
  const { t } = useTranslation("bread");
  const { company } = useCompanyState();
  return <span>{company ? company.name : t("Office Support")}</span>;
}

export const routes = [
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      { path: "login", element: <BankIDLoginPage /> },
      { path: "standard-login", element: <EmailPasswordLoginPage /> },
      { path: "logout", element: <LogoutPage /> },
      { path: "password-forgot", element: <PasswordForgotPage /> },
      { path: "password-reset", element: <PasswordResetPage /> },
      { path: "password-ok", element: <PasswordResetSuccessPage /> },
      { path: "employee-notification-turned-off", element: <EmpNotificationTurnedOffPage /> },
    ],
  },
  {
    element: <ProtectedRoute />,
    breadcrumb: null,
    children: [
      { path: "companies", element: <CompanyListPage />, breadcrumb: null },
      { path: "agencies", element: <AgencyListPage />, breadcrumb: null },
      {
        path: "office-support-companies",
        element: <OfficeSupportCompanyListPage />,
        breadcrumb: null,
      },
      { path: "office-support-export/", element: <OfficeSupportExportPage />, breadcrumb: "Export" },
      { path: "office-support-kyc/", element: <OfficeSupportKYCPage />, breadcrumb: "KYC" },
      {
        path: "file-preview/",
        breadcrumb: null,
        children: [
          {
            path: ":companyId/",
            breadcrumb: null,
            children: [{ path: ":fileId", element: <FilePreviewPage />, breadcrumb: null }],
          },
        ],
      },
      { path: "trash", element: <TrashListPage /> },
      { path: "eu-vat", element: <EuVatPage /> },
      { path: "onboarding", element: <OnboardingProgressPage /> },
      { path: "onboarding/:companyId", element: <OnboardingWizardPage />, breadcrumb: null },
      {
        path: "companies",
        breadcrumb: null,
        children: [
          {
            path: ":companyId",
            breadcrumb: null,
            children: [
              {
                path: "settings",
                ...section,
                element: (
                  <CompanySettingProvider>
                    <CompanySettingsLayout />
                  </CompanySettingProvider>
                ),
                children: [
                  { index: true, element: <GeneralFormPage />, ...section },
                  { path: "employees", element: <EmployeeListPage /> },
                  { path: "invoicing", element: <InvoicingFormPage /> },
                  { path: "projects", element: <ProjectListPage /> },
                  { path: "cost-centers", element: <CostCenterListPage /> },
                  { path: "financial-years", element: <FinancialYearListPage /> },
                  { path: "account-plan", element: <AccountPlanListPage /> },
                  { path: "logo", element: <LogoFormPage /> },
                  { path: "bank-matching-rules", element: <BankMatchingRulesPage /> },
                  { path: "bank-connections", element: <BankConnectionsPage /> },
                  { path: "SIE", element: <SIEFileListPage /> },
                  { path: "time-reporting", element: <TimeReportingFormPage /> },
                  { path: "auditors", element: <AuditorListPage /> },
                  { path: "annual-report", element: <AnnualReportFormPage /> },
                  { path: "history", element: <HistoryListPage />, breadcrumb: "history" },
                  { path: "consult", element: <AdminFormPage />, breadcrumb: "Admin" },
                  { path: "export", element: <ExportPage />, breadcrumb: "Export" },
                  {
                    path: "budget",
                    element: <BudgetLayout />,
                    children: [
                      {
                        path: "profit-and-loss",
                        element: <BudgetPLMonthly />,
                        breadcrumb: null,
                      },
                      {
                        path: "profit-and-loss/year",
                        element: <BudgetPLYearly />,
                        breadcrumb: null,
                      },
                    ],
                  },
                  { path: "tt", element: <TimeTrackerSettingsPage />, breadcrumb: "TT" },
                  { path: "approval-flows", element: <ApprovalFlowsPage />, breadcrumb: "approvalFlows", ...section },
                ],
              },
            ],
          },
        ],
      },
      { path: "consult-draft-si", element: <GlobalDraftSIListPage />, breadcrumb: null },
      {
        path: "manage",
        element: <BMDLayout />,
        breadcrumb: null,
        children: [
          { path: "day-to-day", element: <DayToDayOperationsListPage />, breadcrumb: null },
          { path: "year-end-closure", element: <YearEndClosureListPage />, breadcrumb: null },
          { path: "profitability", element: <ProfitabilityListPage />, breadcrumb: null },
        ],
      },
      {
        path: "management",
        element: <ManagementDashboardLayout />,
        breadcrumb: null,
        children: [{ path: "overview", element: <OverviewPage />, breadcrumb: null }],
      },
      {
        path: "status-list",
        element: <StatusListLayout />,
        breadcrumb: null,
        children: [{ path: ":section", element: <StatusListPage />, breadcrumb: "navigation:statusList" }],
      },
      {
        path: "admin",
        ...section,
        element: <AdminLayout />,
        children: [
          { path: "users", element: <AdminUserListPage /> },
          { path: "events", element: <EventListPage /> },
          { path: "events/form", element: <EventAddPage /> },
          { path: "suppliers", element: <GlobalSupplierListPage /> },
          { path: "import-companies", element: <ImportCompaniesCSVPage /> },
          { path: "search", element: <GlobalSearchPage /> },
          { path: "usage-report", element: <AdminUsageReportPage /> },
          {
            path: "stats",
            element: <StatsLayout />,
            children: [
              { index: true, element: <Navigate to="companies" /> },
              { path: "companies", element: <StatsCompanyPage forYear={false} /> },
              { path: "companies-yearly", element: <StatsCompanyPage forYear /> },
              { path: "verifications", element: <StatsVerificationPage /> },
              { path: "customer-invoices", element: <StatsCIPage /> },
              { path: "supplier-invoices", element: <StatsSIPage /> },
              { path: "outlays", element: <StatsOutlaysPage /> },
              { path: "salaries", element: <StatsSalariesPage /> },
              { path: "users", element: <StatsUsersPage /> },
              { path: "other", element: <StatsOtherPage /> },
            ],
          },
        ],
      },
      {
        path: "agencies",
        children: [
          {
            path: ":agencyId",
            breadcrumb: null,
            children: [
              {
                path: "settings",
                breadcrumb: "Settings",
                element: <AgencySettingsLayout />,
                children: [
                  { index: true, element: <AgencyFormPage />, ...section },
                  { path: "users", element: <AgencyUserListPage /> },
                  { path: "consults", element: <AgencyConsultPermPage /> },
                  { path: "schedule-templates", element: <AgencyScheduleTemplatePage /> },
                  { path: "admin", element: <AgencyAdminPage /> },
                  { path: "statistics", element: <StatsCompanyPage insideAgency /> },
                  { path: "companies", element: <StatsCompanyPage insideAgency forYear={false} /> },
                  { path: "companies-yearly", element: <StatsCompanyPage forYear insideAgency /> },
                  { path: "offices", element: <OfficeListPage />, breadcrumb: "Offices" },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "office-support-companies",
        children: [
          {
            element: <OfficeSupportLayout />,
            children: [
              { path: "*", element: <PageNotFound />, breadcrumb: null },
              {
                element: <CompanyCardLayout />,
                breadcrumb: "Office Support",
                children: [
                  { path: "client-details", breadcrumb: CompanyNameBreadcrumb, element: <ClientDetailsPage /> },
                  { path: "business-profile", breadcrumb: CompanyNameBreadcrumb, element: <BusinessProfilePage /> },
                  { path: "roles", breadcrumb: CompanyNameBreadcrumb, element: <RolesPage /> },
                  {
                    path: "assessment",
                    breadcrumb: CompanyNameBreadcrumb,
                    element: <OfficeSupportCompanyAssessmentsPage forReassessment={false} />,
                  },
                  {
                    path: "reassessment",
                    breadcrumb: CompanyNameBreadcrumb,
                    element: <OfficeSupportCompanyAssessmentsPage forReassessment />,
                  },
                  {
                    path: "notes",
                    breadcrumb: CompanyNameBreadcrumb,
                    element: <OfficeSupportCompanyNotesPage />,
                  },
                  {
                    path: "documents",
                    breadcrumb: CompanyNameBreadcrumb,
                    children: [
                      { index: true, element: <ConsultArchiveListPage /> },
                      { path: "form", element: <ConsultArchiveAddPage />, breadcrumb: null },
                      {
                        path: ":id",
                        breadcrumb: null,
                        children: [{ path: "change", element: <ConsultArchiveEditPage />, breadcrumb: null }],
                      },
                    ],
                  },
                  { path: "status-list", breadcrumb: CompanyNameBreadcrumb, element: <StatusListConfigPage /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "tt",
        element: <TimeTrackerLayout />,
        ...section,
        children: [
          { path: "*", element: <PageNotFound />, breadcrumb: null },
          { path: "logs", element: <TimeTrackerLogsPage /> },
          { path: "time-report", element: <TimeTrackerTimeReportPage /> },
          { path: "consult-report", element: <TimeTrackerConsultReportPage /> },
          {
            breadcrumb: "TT",
            element: <TimeTrackerCommonFilters />,
            ...section,
            children: [
              { path: "calendar", element: <TimeTrackerCalendarPage /> },
              { path: "by-company", element: <TimeTrackerByCompanyPage /> },
            ],
          },
        ],
      },
      {
        element: <DashboardLayout />,
        breadcrumb: "Dashboard",
        children: [
          { index: true, element: <HomePage /> },
          { path: "archive", ...section, ...archiveRoutes },
          { ...customerInvoices },
          {
            path: "approve",
            children: [
              {
                element: <ApproveLayout />,
                ...section,
                children: [
                  { path: "outlays", element: <ApproveOutlayListPage /> },
                  {
                    path: "supplier-invoices",
                    element: <ApproveSupplierInvoiceListPage />,
                  },
                  {
                    path: "salaries",
                    element: <ApproveSalaryListPage />,
                  },
                  {
                    path: "employee-absences",
                    element: <ApproveEmployeeAbsenceListPage />,
                  },
                ],
              },
              { ...outlays },
              { ...supplierInvoices },
              { ...salaries },
            ],
          },
          {
            path: "pay",
            children: [
              {
                children: [
                  {
                    element: <PayLayout />,
                    ...section,
                    children: [
                      {
                        path: "outlays",
                        element: <PayOutlayListPage />,
                      },
                      {
                        path: "supplier-invoices",
                        element: <PaySupplierInvoiceListPage />,
                      },
                      {
                        path: "salaries",
                        element: <PaySalaryListPage />,
                      },
                      {
                        path: "direct-payments",
                        element: <PayDirectPaymentListPage />,
                      },
                      {
                        path: "taxes",
                        element: <PayTaxDirectPaymentListPage />,
                      },
                    ],
                  },
                  {
                    path: "direct-payments",
                    children: [{ path: "form", element: <DirectPaymentAddPage /> }],
                  },
                  {
                    path: "taxes",
                    breadcrumb: "Taxes",
                    children: [
                      { path: "form", element: <TaxDirectPaymentAddPage /> },
                      {
                        path: ":id",
                        breadcrumb: null,
                        children: [
                          {
                            path: "preview",
                            element: <TaxAuthorityPreviewPage />,
                            breadcrumb: BS_PREVIEW,
                          },
                        ],
                      },
                    ],
                  },
                  { ...outlays },
                  { ...supplierInvoices },
                  { ...salaries },
                ],
              },
            ],
          },
          {
            path: "payments",
            children: [
              {
                children: [
                  {
                    element: <PendingLayout />,
                    ...section,
                    children: [
                      {
                        path: "outlays",
                        element: (
                          <OutlayListPage
                            tableId="pendOutlays"
                            initialMode="sent_bank"
                            allowedModes={allowedModesPending}
                          />
                        ),
                      },
                      {
                        path: "supplier-invoices",
                        element: (
                          <SupplierInvoiceListPage
                            tableId="paymentsSupplierInvoices"
                            initialMode="sent_bank"
                            allowedModes={allowedModesPending}
                          />
                        ),
                      },
                      {
                        path: "salaries",
                        element: (
                          <SalaryListPage
                            tableId="pendSalaries"
                            initialMode="sent_bank"
                            allowedModes={allowedModesPending}
                          />
                        ),
                      },
                      {
                        path: "direct-payments",
                        element: (
                          <DirectPaymentListPage
                            tableId="pendDirectPayments"
                            initialMode="sent_bank"
                            allowedModes={allowedModesPending}
                          />
                        ),
                      },
                      {
                        path: "taxes",
                        element: (
                          <TaxDirectPaymentListPage
                            tableId="pendTaxDP"
                            initialMode="sent_bank"
                            allowedModes={allowedModesPending}
                          />
                        ),
                      },
                      {
                        path: "iso",
                        breadcrumb: "ISO Payments",
                        element: <IsoBasketListPage />,
                      },
                    ],
                  },
                  {
                    path: "direct-payments",
                    children: [{ path: "form", element: <TaxDirectPaymentAddPage /> }],
                  },
                  {
                    path: "tax-direct-payments",
                    breadcrumb: "Taxes",
                    children: [{ path: "form", element: <TaxDirectPaymentAddPage /> }],
                  },
                  { ...outlays },
                  { ...supplierInvoices },
                  { ...salaries },
                ],
              },
            ],
          },
          {
            path: "upload",
            element: <UploadLayout />,
            ...section,
            children: [
              { path: "supplier-invoices", element: <UploadSupplierInvoicePage /> },
              {
                path: "outlays",
                element: <UploadOutlayPage />,
              },
              { path: "file-archives", element: <UploadFileArchivePage /> },
            ],
          },
          {
            path: "reports",
            breadcrumb: "See reports",
            children: [
              { path: "cashflow", element: <CashFlowPage /> },
              { path: "outlays", element: <OutlayListPage /> },
              { path: "supplier-invoices", element: <SupplierInvoiceListPage /> },
              { path: "salaries", element: <SalaryListPage /> },
              { path: "verifications", element: <VerificationListPage /> },
              { path: "time-report", element: <TimeReportListPage /> },
              { ...customerInvoices, breadcrumb: "See reports", ...section },
              { ...outlays, breadcrumb: "See reports", ...section },
              { ...supplierInvoices, breadcrumb: "See reports", ...section },
              { ...salaries, breadcrumb: "See reports", ...section },
              { ...accountingReports, breadcrumb: "See reports", ...section },
              { ...employeeAbsences },
            ],
          },
          { path: "inventory-register", element: <AssetListPage /> },
          {
            path: "consult",
            children: [
              { path: "verifications", element: <VerificationListPage /> },
              { path: "inventory-register", element: <AssetListPage /> },
              { path: "outlays", element: <OutlayListPage /> },
              { ...outlays, ...section },
              {
                path: "supplier-invoices",
                element: <SupplierInvoiceLayout />,
                children: [
                  { index: true, element: <SupplierInvoiceListPage /> },
                  { path: "suppliers", element: <SupplierListPage /> },
                ],
              },
              { ...supplierInvoices, ...section },
              { path: "salaries", element: <SalaryListPage /> },
              { ...salaries, ...section },
              { ...customerInvoices, ...section },
              {
                path: "bank-matching",
                element: <BankMatchingPage />,
                children: [{ path: ":account", element: <BankMatchingPage />, breadcrumb: null }],
              },
              { path: "cashflow", element: <CashFlowPage /> },
              { path: "vat", element: <VATReportListPage />, breadcrumb: "VAT Reports" },
              {
                path: "silverback",
                children: [
                  { index: true, element: <SilverbackListPage /> },
                  { path: "form", element: <SilverbackAddPage />, breadcrumb: BS_CREATE },
                  {
                    path: ":id",
                    breadcrumb: null,
                    children: [
                      {
                        path: "change",
                        element: <SilverbackEditPage />,
                        breadcrumb: BS_CHANGE,
                      },
                    ],
                  },
                ],
              },
              {
                path: "taxes",
                children: [
                  { index: true, element: <TaxAuthorityListPage /> },
                  { path: "form", element: <TaxAuthorityAddPage />, breadcrumb: BS_CREATE },
                  {
                    path: ":id",
                    breadcrumb: null,
                    children: [
                      {
                        path: "preview",
                        element: <TaxAuthorityPreviewPage />,
                        breadcrumb: BS_PREVIEW,
                      },
                    ],
                  },
                ],
              },
              {
                path: "global",
                ...section,
                children: [{ path: "suppliers", element: <GlobalSupplierListPage /> }],
              },
              { ...accountingReports, ...section },
              { ...archiveRoutes, ...section },
              { ...employeeAbsences },
            ],
          },
          { path: "*", element: <PageNotFound /> },
        ],
      },
    ],
  },
];
