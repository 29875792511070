import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";
import { Card, Modal, Button } from "react-bootstrap";
import _ from "lodash";

import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import { RocketLoader } from "components/ui/loaders";
import useAsync from "hooks/useAsync";
import ApprovalFlowPreview from "components/others/ApprovalFlowPreview";
import { approvalFlows } from "api2/companies";
import * as selectAPI from "api2/selects";
import cx from "classnames";

function ApprovalFlowPickerModal({ handleClose, companyId, initialFlowId, setFieldValue }) {
  const { t } = useTranslation("ver");
  const [flowPickedId, setPickedFlowId] = useState(initialFlowId);

  const employeeDataSource = useCallback(
    (cancelToken) =>
      selectAPI.companyEmployees(companyId, {}, "user_id", { cancelToken }).then((data) => {
        const _data = data.map((emp) => ({
          ...emp,
          first_name: emp.user_first_name,
          last_name: emp.user_last_name,
        }));
        return _.keyBy(_data, (emp) => emp.user_id);
      }),
    [companyId]
  );
  const [{ data: employeesByUserId, loading: loadingApprovers }] = useAsync(employeeDataSource, {});

  const dataSource = useCallback((cancelToken) => approvalFlows.list(companyId, { status: "active" }), [companyId]);
  const [{ data, loading }] = useAsync(dataSource, []);

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={() => handleClose()}
      dialogClassName="task-modal"
      dialogAs={DraggableModalDialog}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">
          {t("common:actions.change")} {t("others:approvalFlow")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingApprovers || loading ? (
          <RocketLoader />
        ) : (
          <>
            {data.map((flow) => (
              <Card className={cx("approval-flow-card d-table mb-1", { default: flow.is_default })}>
                <Card.Body className="p-1 pt-0">
                  <div style={{ display: "flex" }}>
                    <input
                      type="checkbox"
                      checked={flow.id === flowPickedId}
                      onClick={() => setPickedFlowId(flow.id)}
                    />
                    <h6 className={cx("pl-1", { "flow-picker-default-title": flow.is_default })}>{flow.title}</h6>
                  </div>
                  <div className="ml-1 pl-2">
                    <ApprovalFlowPreview
                      companyId={companyId}
                      title={flow.title}
                      steps={flow.steps}
                      employeesByUserId={employeesByUserId}
                      fullNameMode
                    />
                  </div>
                </Card.Body>
              </Card>
            ))}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="link" onClick={handleClose}>
          {t("common:actions.cancel")}
        </Button>
        <Button
          type="button"
          variant="blue"
          onClick={() => {
            setFieldValue("approval_flow_id", flowPickedId);
            handleClose();
          }}
          disabled={initialFlowId === flowPickedId}
        >
          {t("common:actions.select")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ApprovalFlowPickerModal;
