import React, { useCallback } from "react";
import _ from "lodash";

import { eInvoice as eInvoiceAPI } from "api2/integrations";
import useAsync from "hooks/useAsync";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EInvoiceReceiverModal from "components/modals/EInvoiceReceiverModal";
import useModal from "hooks/useModal";
import { toast } from "react-toastify";
import { PermCodeRequired } from "components/perms";
import { codesForCustomerInvoices } from "components/perms/PermCodes";

function EInvoiceReceiverBox({ companyId, receiverId, customerId, initialOrgNo, onConnect, onDisconnect }) {
  const { t } = useTranslation("common");
  const eInvoiceConnectModal = useModal();

  const dataSource = useCallback(
    (cancelToken) => {
      if (receiverId) {
        return eInvoiceAPI.detail(companyId, receiverId, { cancelToken });
      }
      return new Promise((resolve, reject) => {
        resolve({});
      });
    },
    [receiverId, companyId]
  );
  const [{ data: item, loading }, { updateData }] = useAsync(dataSource, {});

  const onSelected = (receiver) => {
    const data = {
      customer_id: customerId,
      external_receiver_id: receiver.external_id,
      name: receiver.name,
      gln: receiver.gln,
      org_no: receiver.org_no,
    };

    eInvoiceAPI
      .connect(companyId, data)
      .then((response) => {
        updateData(data);
        onConnect(response.data.id);
      })
      .catch(() => {
        toast.error(t("msg:canNotExecuteAction"));
      });

    eInvoiceConnectModal.close();
  };

  const clearReceiver = () => {
    updateData(null);
    eInvoiceAPI.remove(companyId, receiverId).catch(() => {
      toast.error(t("msg:canNotExecuteAction"));
    });
    onDisconnect();
  };

  return (
    <>
      <fieldset>
        <legend>E-Invoice receiver</legend>
        <div className="p-1 border-left">
          {!_.isEmpty(item) && (
            <div>
              <strong>{t("common:name")}:</strong> {item.name}
              <br />
              <strong>{t("common:orgNo")}:</strong> {item.org_no}
              <br />
              <strong>GLN/PEPPOL</strong> {item.gln}
              <br />
              <div className="text-success">{t("ci:eInvoiceReceiverAllowed")}</div>
              <Button size="sm" variant="secondary" className="mt-1" onClick={clearReceiver}>
                {t("common:actions.change")}
              </Button>
            </div>
          )}
          {!loading && _.isEmpty(item) && (
            <div>
              <div className="text-danger">{t("ci:eInvoiceReceiverMissing")}</div>
              <PermCodeRequired code={codesForCustomerInvoices.manage}>
                <Button
                  size="sm"
                  variant="primary"
                  className="mt-1"
                  onClick={() =>
                    eInvoiceConnectModal.open({
                      orgNo: initialOrgNo,
                    })
                  }
                >
                  {t("ci:eInvoiceReceiverConnect")}
                </Button>
              </PermCodeRequired>
            </div>
          )}
        </div>
      </fieldset>
      {eInvoiceConnectModal.show && (
        <EInvoiceReceiverModal
          companyId={companyId}
          handleClose={eInvoiceConnectModal.close}
          initialGln={eInvoiceConnectModal.data.gln}
          initialOrgNo={eInvoiceConnectModal.data.orgNo}
          onSelected={onSelected}
        />
      )}
    </>
  );
}

export default EInvoiceReceiverBox;
