import React from "react";

import useTable from "components/tables/btable/useTable";
import ProductForm from "./ProductForm";

function ProductEditWidget({ companyId, product }) {
  const {
    dataActions: { reload, updateRow },
  } = useTable();

  const successCallback = (newData) => {
    updateRow(newData, "id");
  };

  const deleteCallback = () => {
    reload();
  };

  return (
    <ProductForm
      companyId={companyId}
      product={product}
      successCallback={successCallback}
      deleteCallback={deleteCallback}
    />
  );
}

export default ProductEditWidget;
