import React, { useState } from "react";
import { Alert, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";

import * as documentAPI from "api/document";
import { NewDocuments } from "components/ui/documents/verification-documents";
import { RemoveButton, SubmitButton } from "components/ui/buttons";
import * as companyAPI from "api2/companies";
import { allowedFileExtensionsFor } from "utils/file";

function CompanyLogoForm({ companyId, currentLogo, canEditLogo }) {
  const { t } = useTranslation("company");
  const [newFiles, setNewFiles] = useState([{ key: _.uniqueId("nd.") }]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [logo, setLogo] = useState(currentLogo);

  const upload = async () => {
    const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
    if (!_newFiles.length) {
      toast.error(t("msg:fileMinOneRequired"));
      return;
    }
    setSubmitting(true);
    await companyAPI
      .logoUpdate(companyId, _newFiles[0])
      .then(async () => {
        const {
          data: { logo: newLogo },
        } = await companyAPI.logoDetail(companyId).catch(() => {});
        toast.success(t("msg:uploaded"), { autoClose: 2000 });
        setLogo(newLogo);
        setNewFiles([{ key: _.uniqueId("nd.") }]);
      })
      .catch((error) => {
        toast.error(t("msg:fixErrors"));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const remove = async () => {
    await companyAPI.logoUpdate(companyId, null).then(() => {
      toast.success(t("msg:deleted"), { autoClose: 2000 });
      setLogo(null);
    });
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>{t("currentLogo")}</Card.Title>
        {!logo ? (
          <Alert variant="warning">{t("noLogoUploaded")}</Alert>
        ) : (
          <div>
            <img alt="LOGO" src={logo} className="d-block" />
          </div>
        )}
        {canEditLogo && (
          <>
            <Card.Title>{t("uploadNewLogo")}</Card.Title>
            <NewDocuments
              documents={newFiles}
              fileTypes={allowedFileExtensionsFor.company_logo}
              multiple={false}
              onChange={({ file, index }) =>
                documentAPI.onNewFileChange({
                  file,
                  index,
                  allFiles: newFiles,
                  setFile: setNewFiles,
                  max: 1,
                })
              }
            />
          </>
        )}
      </Card.Body>
      {canEditLogo && (
        <Card.Footer>
          <SubmitButton title="actions.upload" onClick={upload} isSubmitting={isSubmitting} />
          {logo && (
            <RemoveButton
              className="float-right"
              onClick={remove}
              confirmMessage={t("confirm.removeLogo")}
              label={t("actions.removeLogo")}
            />
          )}
        </Card.Footer>
      )}
    </Card>
  );
}

export default CompanyLogoForm;
