import React, { useState } from "react";
import { Button } from "react-bootstrap";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import * as ciAPI from "api2/customer-invoices";
import CustomerAddModal from "components/modals/CustomerAddModal";
import useModal from "hooks/useModal";
import { FormGroup } from "components/formik";

const getCustomers = debounce((companyId, params, callback) => {
  params.mode = "active";
  params.page_size = 9999;
  ciAPI.customers
    .list(companyId, params)
    .then((response) => {
      return callback(response.data.map((item) => ({ ...item, value: item.id, label: item.name })));
    })
    .catch(() => callback([]));
}, 500);

function CustomerPicker({ customer, name, label, companyId, onReset, changeAllowed = true, ...props }) {
  const { t } = useTranslation("common");
  const customerModal = useModal();
  const [refreshCounter, setRefreshCounter] = useState(0);
  const onAddCustomer = (newCustomer) => {
    customerModal.close();
    setRefreshCounter((c) => c + 1);
  };

  return (
    <>
      {customer && (
        <div className="supplier-picker-details form-group">
          <div className="details">
            <label className="form-label">{label}</label>
            <div>
              {t("common:name")}: <strong>{customer.name}</strong>
            </div>
            {customer.address && (
              <div>
                {t("common:contact.address")}:{" "}
                <strong>
                  {customer.address} {customer.address2}
                </strong>
              </div>
            )}
            {customer.city && (
              <div>
                {t("common:contact.post")}:{" "}
                <strong>
                  {customer.zip_code} {customer.city}
                </strong>
              </div>
            )}
            {customer.emails.length ? (
              <div>
                {t("common:contact.emailLong")}: <strong>{customer.emails.join(", ")}</strong>
              </div>
            ) : null}
            {changeAllowed && (
              <button type="button" className="btn btn-sm btn-outline-dark" onClick={onReset}>
                {t("actions.change")}
              </button>
            )}
          </div>
        </div>
      )}
      <div className={cx({ "d-none": !!customer }, "customer-picker")}>
        <FormGroup.AsyncSelect
          key={`cus-${refreshCounter}`}
          className="customer-select"
          name={name}
          label={label}
          placeholder={t("common:actions.select")}
          loadOptions={(params, callback) => getCustomers(companyId, params, callback)}
          minSearchLength={0}
          required
          {...props}
        />
        <Button variant="second" onClick={customerModal.open}>
          <i className="fas fa-plus" /> {t("ci:actions.addCustomer")}
        </Button>
      </div>
      {customerModal.show && (
        <CustomerAddModal companyId={companyId} handleClose={customerModal.close} onSuccess={onAddCustomer} />
      )}
    </>
  );
}

export default CustomerPicker;
