import React, { useMemo } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as yup from "yup";

import { ConfirmButton, SubmitButton } from "components/ui/buttons";
import * as ciAPI from "api2/customer-invoices";
import * as options from "api/options";
import { paymentTermsOptionsWithDefault } from "api/options";
import { findCountryByCode, getCountries } from "utils/countries";
import { AllError, FormGroup } from "components/formik";
import { codesForCustomerInvoices } from "components/perms/PermCodes";
import { PermCodeRequired } from "components/perms";
import { useCompanyState } from "hooks/useCompany";
import { useModalOnPage } from "components/modals/hooks/useModalOnPage";
import { handleActionErrors } from "api/errors";
import EInvoiceReceiverBox from "./EInvoiceReceiverBox";
import { confirmInfo } from "../../modals/ConfirmModal";

function mapValuesToTable(values) {
  return {
    ...values,
    country: values.country.value,
    customer_type: values.customer_type.value,
    price_group: values.price_group.value,
    default_delivery_method: values.default_delivery_method.value,
    default_payment_terms: values.default_payment_terms.value,
    language: values.language.value,
  };
}

function CustomerForm({ companyId, customer, successCallback, resetOnSuccess = false }) {
  const { t } = useTranslation("ci");
  const { company } = useCompanyState();
  const countries = useMemo(() => getCountries(t), [t]);
  const isModal = useModalOnPage();

  const customerTypes = options.customerTypes.asList();
  const languageChoices = options.languageChoices.asList();
  const deliveryMethods = options.deliveryMethods.asList();

  const priceGroups = options.priceGroups.asList();
  const initialInvoicePaymentTermsInDays =
    customer.default_payment_terms !== 365 ? customer.default_payment_terms || 365 : 365;
  const formikProps = {
    initialValues: {
      ...customer,
      customer_type: options.customerTypes.getOption(customer.customer_type) || customerTypes[0],
      country: findCountryByCode(customer.country),
      price_group: options.priceGroups.getOption(customer.price_group || "A"),
      property_designation: customer.property_designation || "",
      residence_org_no: customer.residence_org_no || "",
      other_information: customer.other_information || "",
      default_delivery_method: options.deliveryMethods.getOption(customer.default_delivery_method),
      language: options.languageChoices.getOption(customer.language || "SV"),
      default_payment_terms: options.paymentTerms.getOption(initialInvoicePaymentTermsInDays),
    },
    enableReinitialize: true,
    validationSchema: yup
      .object()
      .shape({
        name: yup.string().required(),
        identification: yup.string(),
        address: yup.string().required(),
        address2: yup.string(),
        emails: yup
          .array()
          .of(yup.string().email())
          .when("default_delivery_method", ([dm], schema) => {
            return dm.value?.toLowerCase() === "email" ? schema.min(1) : schema;
          }),
        city: yup.string().required(),
        country: yup.object().required(),
        customer_number: yup.string(),
        customer_type: yup.object().required(),
        zip_code: yup.string().required(),
        vat_number: yup.string(),
        price_group: yup.object().nullable().required(),
        default_delivery_method: yup.object().nullable().required(),
        default_payment_terms: yup.object().nullable().notRequired(),
        your_reference: yup.string(),
        our_reference: yup.string(),
        other_information: yup.string(),
      })
      .test("checkVAT", function (values) {
        const debtorType = values.customer_type ? values.customer_type.value : "";
        const countryEU = values.country.isEU;
        if (debtorType === "company" && countryEU === true) {
          if (!values.vat_number) {
            return this.createError({
              path: "vat_number",
              message: t("common:errors.required"),
            });
          }
        }

        return true;
      }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await ciAPI.customers
        .save(companyId, {
          ...customer,
          ...values,
          customer_type: values.customer_type.value,
          country: values.country.value,
          emails: values.emails,
          price_group: values.price_group.value,
          default_delivery_method: values.default_delivery_method.value,
          default_payment_terms: values.default_payment_terms.value,
          language: values.language.value.toLowerCase(),
        })
        .then(async (response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (customer.id) {
            await successCallback({
              ...customer,
              ...values,
              country: values.country.value,
              customer_type: values.customer_type.value,
              price_group: values.price_group.value,
              default_delivery_method: values.default_delivery_method.value,
              default_payment_terms: values.default_payment_terms.value,
              language: values.language.value,
            });
            if (resetOnSuccess) {
              resetForm();
            }
          } else {
            resetForm();
            if (successCallback) {
              successCallback(response.data);
            }
          }
          if (response.data.vies_checked) {
            if (response.data.vies_name) {
              await confirmInfo(t("ci:confirm.viesNameOk", { name: `<strong>${response.data.vies_name}</strong>` }));
            } else {
              await confirmInfo(t("ci:confirm.viesNameMissing"));
            }
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  async function deleteCustomer() {
    await ciAPI.customers
      .remove(companyId, customer.id)
      .then(() => {
        toast.success(t("msg:deleted"), { autoClose: 2000 });
        document.body.dispatchEvent(new Event("customer/removed"));
      })
      .catch((error) => {
        handleActionErrors(error);
      });
  }

  async function activeToggle(newCustomer) {
    if (newCustomer.is_active) {
      await ciAPI.customers
        .toggleActivate(companyId, newCustomer.id)
        .then((response) => {
          toast.success(t("msg:deactivated"), { autoClose: 2000 });
          if (successCallback) {
            successCallback({ ...mapValuesToTable(newCustomer), is_active: false });
          }
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    } else {
      await ciAPI.customers.toggleActivate(companyId, newCustomer.id).then((response) => {
        toast.success(t("msg:activated"), { autoClose: 2000 });
        if (successCallback) {
          successCallback({ ...mapValuesToTable(newCustomer), is_active: true });
        }
      });
    }
  }

  return (
    <Formik {...formikProps}>
      {({ values, errors, setFieldValue, isSubmitting }) => {
        const debtorType = values.customer_type ? values.customer_type.value : "";
        const countryEU = values.country.isEU;
        const first2Chars = values.vat_number.slice(0, 2);
        const VATcountryCode = /[A-Za-z]+/.test(first2Chars) ? first2Chars : null;
        const showVATEUWarn =
          debtorType === "company" &&
          ((VATcountryCode && VATcountryCode !== values.country.value) ||
            (!!values.vat_number && !VATcountryCode && countryEU && values.country.value !== "SE"));
        const showEUCompanyVATNumberWarning = debtorType === "company" && values.country.isEU && !values.vat_number;
        return (
          <Form noValidate>
            <Row>
              <Col sm={6} xl={2}>
                <FormGroup.SimpleSelect name="customer_type" label={t("customerType")} options={customerTypes} />
              </Col>
              <Col sm={6} xl={2}>
                <FormGroup.Input
                  label={values.customer_type.value === "private" ? t("common:name") : t("common:companyName")}
                  name="name"
                  required
                />
              </Col>
              <Col sm={6} xl={2}>
                <FormGroup.Input
                  label={
                    values.customer_type.value === "private" ? t("common:contact.personalNumber") : t("common:orgNo")
                  }
                  name="identification"
                />
                <small className="d-block" style={{ marginTop: -14, marginBottom: 10 }}>
                  {values.customer_type.value === "private" ? "YYYYMMDD-XXXX" : "XXXXXX-XXXX"}
                </small>
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.Input
                  label={t("customerNumber")}
                  name="customer_number"
                  helpText={t("helpCustomerNumber")}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4} xl={3}>
                <FormGroup.Input label={t("common:contact.address")} name="address" required />
              </Col>
              <Col sm={4} xl={3}>
                <FormGroup.Input label={t("common:contact.address2")} name="address2" />
              </Col>
              <Col sm={2} xl={3}>
                <FormGroup.Input label={t("common:contact.zipCode")} name="zip_code" required />
              </Col>
            </Row>
            <Row>
              <Col sm={2} xl={2}>
                <FormGroup.Input label={t("common:contact.city")} name="city" required />
              </Col>
              <Col sm={2} xl={2}>
                <FormGroup.SimpleSelect
                  name="country"
                  label={t("common:contact.country")}
                  options={countries}
                  required
                />
              </Col>
              <Col sm={2} xl={2}>
                <FormGroup.Input label={t("common:contact.phone")} name="phone" />
              </Col>
              <Col sm={4} xl={3}>
                <FormGroup.MultiEmail
                  name="emails"
                  label={t("common:contact.emailLong")}
                  helpText={t("helpEmail")}
                  showAddMe={false}
                  required={values.default_delivery_method.value === "email"}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4} xl={3}>
                <FormGroup.Input label={t("common:yourReference")} name="your_reference" />
              </Col>
              <Col sm={4} xl={3}>
                <FormGroup.Input label={t("common:ourReference")} name="our_reference" />
              </Col>
              <Col sm={4} xl={3}>
                <FormGroup.Input
                  label={t("common:money.vatNo")}
                  name="vat_number"
                  required={values.customer_type.value === "company" && values.country.isEU === true}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={4}>
                <FormGroup.SimpleSelect
                  name="default_payment_terms"
                  label={t("common:paymentTerms")}
                  options={paymentTermsOptionsWithDefault()}
                />
              </Col>
              <Col xl={4}>
                <FormGroup.SimpleSelect name="price_group" label={t("priceGroup")} options={priceGroups} />
              </Col>
            </Row>
            <Row>
              <Col xl={4}>
                <FormGroup.SimpleSelect label={t("invoiceLang")} name="language" options={languageChoices} />
              </Col>
              <Col xl={4}>
                <FormGroup.SimpleSelect
                  name="default_delivery_method"
                  label={t("sendInvoiceBy")}
                  options={deliveryMethods}
                  menuPlacement={isModal ? "bottom" : "auto"}
                  menuPosition={isModal ? "absolute" : "fixed"}
                  required
                />
              </Col>
            </Row>
            {values.customer_type.value === "private" && (
              <Row>
                <Col xl={4}>
                  <FormGroup.Input label={t("ci:propertyDesignation")} name="property_designation" />
                </Col>
                <Col xl={4}>
                  <FormGroup.Input label={t("ci:residenceNo")} name="residence_org_no" />
                </Col>
              </Row>
            )}
            <Row>
              <Col xl={4}>
                <FormGroup.Input as="textarea" label={t("ci:otherInformation")} name="other_information" />
              </Col>
            </Row>
            {company.einvoice_enabled && customer.id && (
              <EInvoiceReceiverBox
                receiverId={values.einvoice_receiver}
                customerId={customer.id}
                companyId={companyId}
                initialOrgNo={values.identification}
                onConnect={(receiverId) => {
                  setFieldValue("default_delivery_method", deliveryMethods[2]);
                  successCallback({
                    ...mapValuesToTable(values),
                    einvoice_receiver: receiverId,
                  });
                }}
                onDisconnect={() => {
                  setFieldValue("default_delivery_method", deliveryMethods[0]);
                  successCallback({
                    ...mapValuesToTable(values),
                    einvoice_receiver: null,
                  });
                }}
              />
            )}
            {showVATEUWarn && <Alert variant="warning">{t("ci:errors.vatNumberCountry")}</Alert>}
            {showEUCompanyVATNumberWarning && <Alert variant="warning">{t("warnDebtor")}</Alert>}
            <AllError errors={errors} />
            <hr />
            <PermCodeRequired code={codesForCustomerInvoices.manage}>
              <SubmitButton isSubmitting={isSubmitting} />
              {customer.id && (
                <>
                  <ConfirmButton
                    variant="red"
                    confirmMessage={t("confirm.removeCustomer", {
                      customerName: customer.name,
                    })}
                    label={t("common:actions.remove")}
                    className="float-right ml-2"
                    icon="fas fa-trash-alt"
                    onClick={() => deleteCustomer(customer)}
                  />
                  {customer.is_active ? (
                    <ConfirmButton
                      variant="orange"
                      confirmMessage={t("confirm.deactivateCustomer", {
                        customerName: customer.name,
                      })}
                      label={t("common:actions.deactivate")}
                      className="float-right"
                      onClick={() => activeToggle(values)}
                    />
                  ) : (
                    <ConfirmButton
                      variant="orange"
                      label={t("common:actions.activate")}
                      confirmMessage={t("confirm.activateCustomer", {
                        customerName: customer.name,
                      })}
                      className="float-right"
                      onClick={() => activeToggle(values)}
                    />
                  )}
                </>
              )}
            </PermCodeRequired>
          </Form>
        );
      }}
    </Formik>
  );
}

export default CustomerForm;
