import React, { useContext, useMemo, useState, useEffect, useCallback } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Formik, Field, FieldArray, Form } from "formik";
import _ from "lodash";
import { useCompanyState } from "hooks/useCompany";
import { SelectionContext } from "state/providers/SelectionProvider";
import { NewDocuments } from "components/ui/documents/verification-documents";
import { SubmitButton } from "components/ui/buttons";
import Headers from "components/tables/btable/Headers";
import { Loader } from "components/ui/loaders";
import { FormGroup, TableGroup } from "components/formik";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import * as documentAPI from "api/document";
import { allowedFileExtensionsFor } from "utils/file";
import * as ciApi from "api2/customer-invoices";
import { formatDate, parseDate, isAfter, isBefore, isSameDayOrBefore } from "utils/date";
import "./RotRutModal.scss";
import cx from "classnames";
import AllError from "components/formik/AllError";

function CaseErrors({ caseItem, lockDate, updateCaseErrors }) {
  const { t } = useTranslation("ci");

  const errors = useMemo(() => {
    const errorList = [];
    if (!caseItem.selected) return errorList;
    if (caseItem.payment_date) {
      const today = new Date();
      const paymentDate = caseItem.payment_date;
      const bookingDate = parseDate(caseItem.booking_date);
      const lockingDate = parseDate(lockDate);

      if (paymentDate) {
        if (isAfter(paymentDate, today)) {
          errorList.push(t("rotRutErrorPaymentDateInFuture"));
        }

        if (bookingDate && isBefore(paymentDate, bookingDate)) {
          errorList.push(t("rotRutErrorPaymentDateBeforeBookingDate"));
        }

        if (lockingDate && isSameDayOrBefore(paymentDate, lockingDate)) {
          const formattedLockDate = lockingDate.toLocaleDateString();
          errorList.push(t("common:errors.accountingIsLocked", { company_lock_accounting_date: formattedLockDate }));
        }
      }
    } else {
      errorList.push(t("rotRutErrorPaymentDateMustBeProvided"));
    }

    return errorList;
  }, [caseItem.payment_date, caseItem.booking_date, lockDate, caseItem.selected, t]);

  useEffect(() => {
    if (caseItem.selected) {
      updateCaseErrors(caseItem.id, errors.length > 0);
    } else {
      updateCaseErrors(caseItem.id, false);
    }
  }, [errors, updateCaseErrors, caseItem.selected, caseItem.id]);

  if (errors.length === 0 || !caseItem.selected) {
    return null;
  }

  return (
    <tr>
      <td colSpan={8} className="has-errors">
        <ul>
          {errors.map((error, index) => (
            <li key={`${caseItem.id}-${error}-${index}`}>{error}</li>
          ))}
        </ul>
      </td>
    </tr>
  );
}

function DecisionsTable({ tableId, onSubmit, formikProps, setHasErrors }) {
  const { t } = useTranslation("company");
  const { toggleSelection } = useContext(SelectionContext);
  const {
    paymentAccounts: { asOptions: paymentAccountsOptions },
  } = useCompanyState();
  const {
    company: { lock_accounting_date },
  } = useCompanyState();
  const headers = useMemo(
    () => [
      { field: "invoice_no", label: t("common:invoiceNo"), canSort: false, width: "8%" },
      { field: "ssn", label: t("common:contact.personalNumber"), canSort: false, width: "12%" },
      { field: "customer_name", label: t("common:customer"), canSort: false, width: "23%" },
      { field: "approved_amount", label: t("ci:rotRutApprovedAmount"), canSort: false, width: "15%" },
      { field: "requested_amount", label: t("ci:rotRutRequestedAmount"), canSort: false, width: "15%" },
      { field: "left_to_pay", label: t("ci:rotRutLeftToPay"), canSort: false, width: "15%" },
      { field: "payment_date", label: t("common:dates.paymentDate"), canSort: false, width: "12%" },
    ],
    [t]
  );

  const [caseErrors, setCaseErrorsState] = useState({});
  const updateCaseErrors = useCallback((caseId, hasError) => {
    setCaseErrorsState((prevErrors) => ({
      ...prevErrors,
      [caseId]: hasError,
    }));
  }, []);

  useEffect(() => {
    const hasAnyErrors = Object.values(caseErrors).some((error) => error);
    setHasErrors(hasAnyErrors);
  }, [caseErrors, setHasErrors]);

  function toggleAll(values, setFieldValue) {
    const shouldSelectAll = values.decisions.some((decision) =>
      decision.cases.some((caseItem) => !caseItem.isDisabled && !caseItem.selected)
    );

    values.decisions.forEach((decision, decisionIndex) => {
      decision.cases.forEach((caseItem, caseIndex) => {
        if (!caseItem.isDisabled) {
          toggleSelection(tableId, `decisions.${decisionIndex}.cases.${caseIndex}`);
          setFieldValue(`decisions.${decisionIndex}.cases.${caseIndex}.selected`, shouldSelectAll);
        }
      });
    });
  }

  return (
    <Formik {...formikProps} onSubmit={onSubmit}>
      {({ values, setFieldValue, handleSubmit, errors, touched }) => (
        <Form>
          <Table className="rot-rut-matching-table">
            <thead>
              <Headers
                initialHeaders={headers}
                actionColumn={false}
                selectColumn
                onSelectAll={() => toggleAll(values, setFieldValue)}
                onSort={() => {}}
                tableId={tableId}
              />
            </thead>
            {values.decisions && values.decisions.length > 0 && (
              <FieldArray
                name="decisions"
                render={() => (
                  <>
                    {values.decisions.map((decision, decisionIndex) => (
                      <tbody key={`${decision.name}-${decisionIndex}`}>
                        <tr className="application-name">
                          <td colSpan="7">{decision.name}</td>
                          <TableGroup.DatePicker
                            name={`decisions.${decisionIndex}.payment_date`}
                            popperClassName="popper-in-modal"
                            isClearable={false}
                            tdProps={{ className: "datepicker-input" }}
                            onChange={(date) => {
                              const { cases } = values.decisions[decisionIndex];
                              cases.forEach((caseItem, caseIndex) => {
                                setFieldValue(`decisions.${decisionIndex}.cases.${caseIndex}.payment_date`, date);
                              });
                            }}
                          />
                        </tr>
                        {decision.cases && decision.cases.length > 0 && (
                          <FieldArray
                            name={`decisions.${decisionIndex}.cases`}
                            render={() => {
                              const totalApproved = decision.cases.reduce((sum, caseItem) => {
                                const approvedAmount = parseFloat(caseItem.approved_amount) || 0;
                                return sum + approvedAmount;
                              }, 0);
                              return (
                                <>
                                  {decision.cases.map((caseItem, caseIndex) => {
                                    const approvedAmount = parseFloat(caseItem.approved_amount);
                                    const requestedAmount = parseFloat(caseItem.requested_amount);
                                    const leftToPay = parseFloat(caseItem.left_to_pay);

                                    const hasApprovedVsRequestedMismatch = approvedAmount !== requestedAmount;
                                    const hasApprovedVsLeftToPayMismatch = approvedAmount !== leftToPay;
                                    const hasMismatch =
                                      hasApprovedVsRequestedMismatch || hasApprovedVsLeftToPayMismatch;

                                    const { isDisabled, selected } = caseItem;

                                    const mismatchMessages = [];
                                    if (hasApprovedVsRequestedMismatch) {
                                      mismatchMessages.push(t("ci:approvedAmountMismatchRequestedAmountTooltip"));
                                    }
                                    if (!hasApprovedVsRequestedMismatch && hasApprovedVsLeftToPayMismatch) {
                                      mismatchMessages.push(t("ci:approvedAmountMismatchLeftToPayTooltip"));
                                    }
                                    const approvedAmountTitle = () => {
                                      if (!isDisabled) {
                                        if (mismatchMessages.length > 0) {
                                          return mismatchMessages.join("\n");
                                        }
                                      }
                                      return t("ci:rotRutNoMatchFoundTooltip");
                                    };
                                    const requestedAmountTitle = () => {
                                      if (isDisabled) {
                                        return t("ci:rotRutNoMatchFoundTooltip");
                                      }
                                      if (hasApprovedVsRequestedMismatch) {
                                        return t("ci:approvedAmountMismatchRequestedAmountTooltip");
                                      }
                                      return "";
                                    };
                                    const leftToPayTitle = () => {
                                      if (isDisabled) {
                                        return t("ci:rotRutNoMatchFoundTooltip");
                                      }
                                      if (!hasApprovedVsRequestedMismatch && hasApprovedVsLeftToPayMismatch) {
                                        return t("ci:approvedAmountMismatchLeftToPayTooltip");
                                      }
                                      return "";
                                    };
                                    return (
                                      <React.Fragment key={`${caseItem.id || caseItem.invoice_no}-${caseIndex}`}>
                                        <tr
                                          className={cx({ disabled: isDisabled })}
                                          title={isDisabled ? t("ci:rotRutNoMatchFoundTooltip") : ""}
                                        >
                                          <td
                                            className="selectable"
                                            onClick={() => {
                                              if (!isDisabled) {
                                                toggleSelection(tableId, caseItem);
                                                setFieldValue(
                                                  `decisions.${decisionIndex}.cases.${caseIndex}.selected`,
                                                  !selected
                                                );
                                              }
                                            }}
                                          >
                                            <div className="checkbox custom-control checkbox-primary">
                                              <Field
                                                type="checkbox"
                                                name={`decisions.${decisionIndex}.cases.${caseIndex}.selected`}
                                                className="d-none"
                                                disabled={isDisabled}
                                              />
                                              <label className="custom-control-label" />
                                            </div>
                                          </td>
                                          <td>{caseItem.invoice_no}</td>
                                          <td>{caseItem.ssn}</td>
                                          <td>{caseItem.customer_name}</td>
                                          <td
                                            className={cx({
                                              warning: hasMismatch && !isDisabled,
                                            })}
                                            title={approvedAmountTitle()}
                                          >
                                            {approvedAmount.toFixed(2)} SEK
                                          </td>
                                          <td
                                            className={cx({
                                              warning: hasApprovedVsRequestedMismatch && !isDisabled,
                                            })}
                                            title={requestedAmountTitle()}
                                          >
                                            {requestedAmount.toFixed(2)} SEK
                                          </td>
                                          <td
                                            className={cx({
                                              warning:
                                                !hasApprovedVsRequestedMismatch &&
                                                hasApprovedVsLeftToPayMismatch &&
                                                !isDisabled,
                                            })}
                                            title={leftToPayTitle()}
                                          >
                                            {leftToPay.toFixed(2)} SEK
                                          </td>
                                          {selected && !isDisabled ? (
                                            <TableGroup.DatePicker
                                              name={`decisions.${decisionIndex}.cases.${caseIndex}.payment_date`}
                                              popperClassName="popper-in-modal"
                                              isClearable={false}
                                              tdProps={{ className: "datepicker-input" }}
                                            />
                                          ) : (
                                            <td />
                                          )}
                                        </tr>
                                        <CaseErrors
                                          caseItem={caseItem}
                                          lockDate={lock_accounting_date}
                                          updateCaseErrors={updateCaseErrors}
                                        />
                                      </React.Fragment>
                                    );
                                  })}
                                  <tr className="total-approved">
                                    <td colSpan="8">
                                      {t("ci:rotRutTotalApproved")}: <strong>{totalApproved.toFixed(2)} SEK</strong>
                                    </td>
                                  </tr>
                                  <tr className="empty">
                                    <td colSpan="8" />
                                  </tr>
                                </>
                              );
                            }}
                          />
                        )}
                      </tbody>
                    ))}
                  </>
                )}
              />
            )}
          </Table>
          <Row>
            <Col lg={4}>
              <FormGroup.SimpleSelect
                name="payment_account"
                label={t("common:paymentAccount")}
                options={paymentAccountsOptions}
                helpText={t("company:helpText.paymentAccount")}
              />
            </Col>
          </Row>
          <Button variant="primary" onClick={handleSubmit} className="d-none" id="submitButton" />
        </Form>
      )}
    </Formik>
  );
}

function RotRutModal({ companyId, show, onHide }) {
  const [newFiles, setNewFiles] = useState([{ key: _.uniqueId("nd.") }]);
  const [currentStep, setCurrentStep] = useState(1);
  const [data, setData] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const [formikErrors, setFormikErrors] = useState({});
  const [formikTouched] = useState({});
  const [hasErrors, setHasErrors] = useState(false);

  const { t } = useTranslation("common");
  const { getFlatten, clearSelection } = useContext(SelectionContext);
  const selected = getFlatten();

  const {
    paymentAccounts: { byId: paymentAccountsById },
  } = useCompanyState();

  const getStepClass = (step) => {
    if (currentStep === step) {
      return "active";
    }
    if (currentStep > step) {
      return "completed";
    }
    return "";
  };

  const handleUploadSubmit = () => {
    setSubmitting(true);
    ciApi.rotRut
      .uploadSkvFile(companyId, newFiles)
      .then((response) => {
        setCurrentStep(response.data.step);
        setData(response.data.decisions);
      })
      .catch((error) => {
        setFormikErrors(error.data);
        toast.error(t("msg:fixErrors"));
        clearSelection();
      })
      .finally(() => {
        setSubmitting(false);
        setNewFiles([{ key: _.uniqueId("nd.") }]);
        clearSelection();
      });
  };

  const handleBookSubmit = (values, { setErrors, setTouched }) => {
    const selectedCases = [];

    values.decisions.forEach((decision) => {
      decision.cases.forEach((caseItem) => {
        if (caseItem.selected) {
          selectedCases.push({
            id: caseItem.id,
            amount: caseItem.approved_amount,
            payment_date: formatDate(caseItem.payment_date),
            payment_account_id: values.payment_account.value,
          });
        }
      });
    });

    const payload = {
      cases: selectedCases,
    };

    ciApi.rotRut
      .bookPayments(companyId, payload)
      .then((response) => {
        toast.success(t("msg:saved"), { autoClose: 2000 });
        onHide();
        clearSelection();
      })
      .catch((error) => {
        if (error.data) {
          setErrors(error.data);
          setTouched(
            Object.keys(error.data).reduce((acc, key) => {
              acc[key] = true;
              return acc;
            }, {})
          );
          if (error.data["cases.__all__"]) {
            toast.error(error.data["cases.__all__"], { autoClose: 4000 });
          }

          Object.keys(error.data).forEach((key) => {
            if (key.startsWith("cases.")) {
              const errorMsg = error.data[key];
              toast.error(errorMsg, { autoClose: 4000 });
            }
          });
          if (error.data.payment_date) {
            toast.error(error.data.payment_date, { autoClose: 4000 });
          }
        } else {
          toast.error(t("msg:fixErrors"));
        }
      });
  };

  const renderStepContent = () => {
    if (isSubmitting) {
      return <Loader />;
    }

    if (currentStep === 1) {
      return (
        <>
          <NewDocuments
            fileTypes={allowedFileExtensionsFor.rot_rut}
            documents={newFiles}
            multiple={false}
            onChange={({ file, index }) =>
              documentAPI.onNewFileChange({
                file,
                index,
                allFiles: newFiles,
                setFile: setNewFiles,
                max: 1,
              })
            }
          />
          <p />
          <div className="error-upload-message">
            <AllError errors={formikErrors} touched={formikTouched} />
          </div>
        </>
      );
    }
    if (currentStep === 2) {
      return (
        <DecisionsTable
          tableId="decisionsTablee"
          onSubmit={handleBookSubmit}
          formikProps={{
            initialValues: {
              decisions: data.map((decision) => ({
                name: decision.name || "",
                cases: decision.cases.map((caseItem) => ({
                  id: caseItem.id || "",
                  invoice_no: caseItem.invoice_no || "",
                  ssn: caseItem.ssn || "",
                  customer_name: caseItem.customer_name || "",
                  approved_amount: caseItem.approved_amount || 0,
                  requested_amount: caseItem.requested_amount || 0,
                  left_to_pay: caseItem.left_to_pay || 0,
                  selected:
                    !!caseItem.id &&
                    caseItem.approved_amount === caseItem.requested_amount &&
                    caseItem.approved_amount === caseItem.left_to_pay,
                  isDisabled: !caseItem.id,
                  payment_date: null,
                  booking_date: caseItem.booking_date,
                })),
                payment_date: null,
              })),
              payment_account: paymentAccountsById[1930],
            },
            onSubmit: handleBookSubmit,
          }}
          setHasErrors={setHasErrors}
        />
      );
    }
    return null;
  };

  const onHideHeader = () => {
    onHide();
    setSubmitting(false);
    setNewFiles([{ key: _.uniqueId("nd.") }]);
    setCurrentStep(1);
    setFormikErrors({});
    clearSelection();
    setHasErrors(false);
  };

  return (
    <Modal
      show={show}
      onHide={onHideHeader}
      size="xl"
      scrollable
      dialogAs={DraggableModalDialog}
      className="upload-skv-file-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <ul className="stepper">
          <li className={getStepClass(1)}>
            <span>{t("ci:rotRutStepUploading")}</span>
            <div className="step-bar" />
          </li>
          <li className={getStepClass(2)}>
            <span>{t("ci:rotRutStepMatching")}</span>
            <div className="step-bar" />
          </li>
        </ul>
        <div className="step-upload-container">{renderStepContent()}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="link"
          onClick={() => {
            setSubmitting(false);
            setCurrentStep(1);
            onHide();
            setFormikErrors({});
            clearSelection();
            setHasErrors(false);
          }}
          className="step-upload-cancel-button"
        >
          {t("common:actions.cancel")}
        </Button>
        {currentStep === 1 ? (
          <SubmitButton
            onClick={handleUploadSubmit}
            icon="fa-upload"
            title={t("common:actions.upload")}
            variant="link"
            isSubmitting={isSubmitting}
          />
        ) : (
          <Button
            variant="primary"
            onClick={() => document.getElementById("submitButton").click()}
            title={!selected.length > 0 ? t("ci:bookPaymentSelectOne") : ""}
            disabled={hasErrors}
          >
            {t("common:actions.bookPayment")}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default RotRutModal;
