import client from "api2/client";
import qs from "qs";

export const companyDocuments = {
  list: (companyId, filters, config) =>
    client.get(`/documents/company/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  sectionBasic: (companyId, filters, config) =>
    client.get(`/documents/company/section/basic/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  sectionYearly: (companyId, filters, config) =>
    client.get(`/documents/company/section/yearly/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  sectionAuditor: (companyId, filters, config) =>
    client.get(`/documents/company/section/auditor/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  save: (companyId, files, data, config) => {
    if (data.id) {
      return client.put(`/documents/company/${data.id}/update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });
    files.forEach((file, i) => {
      if (file) {
        formData.append("files", file, file.name);
      }
    });
    return client.post(`/documents/company/create/`, formData, {
      headers: {
        "x-company": companyId,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  detail: (companyId, documentId, config) =>
    client.get(`/documents/company/${documentId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  duplicate: (companyId, documentId, config) =>
    client.post(
      `/documents/company/${documentId}/duplicate/`,
      {},
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    ),
  remove: (companyId, documentId, config) =>
    client.delete(`/documents/company/${documentId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  toggleShareAuditor: (companyId, documentId) =>
    client.patch(
      `/documents/company/${documentId}/toggle-share-auditor/`,
      {},
      {
        headers: {
          "x-company": companyId,
        },
      }
    ),
  toggleShareCustomer: (companyId, documentId, notifyCustomer) =>
    client.patch(
      `/documents/company/${documentId}/toggle-share-customer/`,
      {
        notify_customer: notifyCustomer,
      },
      {
        headers: {
          "x-company": companyId,
        },
      }
    ),
  fetchBalanceSheetAndProfitAndLoss: (companyId, financialYearId) =>
    client.post(
      `/documents/company/fetch/balance-sheet-and-profit-and-loss/`,
      {
        financial_year: financialYearId,
      },
      {
        headers: {
          "x-company": companyId,
        },
      }
    ),
  fetchSIEFile: (companyId, financialYearId) =>
    client.post(
      `/documents/company/fetch/sie-file/`,
      {
        financial_year: financialYearId,
      },
      {
        headers: {
          "x-company": companyId,
        },
      }
    ),
  fetchCurrentCustomerAndSupplierLedgers: (companyId, financialYearId) =>
    client.post(
      `/documents/company/fetch/current-customer-and-supplier-ledger/`,
      {
        financial_year: financialYearId,
      },
      {
        headers: {
          "x-company": companyId,
        },
      }
    ),
  toggleArchive: (companyId, documentId) =>
    client.patch(
      `/documents/company/${documentId}/toggle-archive/`,
      {},
      {
        headers: {
          "x-company": companyId,
        },
      }
    ),
  history: (companyId, documentId, config) => {
    return client.get(`/documents/company/${documentId}/history/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const archiveDocuments = {
  list: (companyId, filters, config) =>
    client.get(`/documents/archive/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  save: (companyId, file, data, config) => {
    if (data.id) {
      return client.put(`/documents/archive/${data.id}/update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });
    formData.append("file", file, file.name);
    return client.post(`/documents/archive/create/`, formData, {
      headers: {
        "x-company": companyId,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  createFromFiles: (companyId, files, config) => {
    const formData = new FormData();
    formData.append("merge_files", false);
    files.forEach((file, i) => {
      if (file) {
        formData.append("files", file, file.name);
      }
    });

    return client.post(`/documents/archive/create-from-files/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-company": companyId,
      },
      ...config,
    });
  },
  detail: (companyId, documentId, config) =>
    client.get(`/documents/archive/${documentId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  boundary: (companyId, documentId, filters, config) =>
    client.get(`/documents/archive/${documentId}/boundary/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  remove: (companyId, documentId, config) =>
    client.delete(`/documents/archive/${documentId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  duplicate: (companyId, documentId, config) =>
    client.post(
      `/documents/archive/${documentId}/duplicate/`,
      {},
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    ),
  verificationAppend: (companyId, documentId, verificationId) =>
    client.post(
      `/documents/archive/${documentId}/verification/append/`,
      {
        verification_id: verificationId,
      },
      {
        headers: {
          "x-company": companyId,
        },
      }
    ),
  supplierInvoiceCreate: (companyId, documentId) =>
    client.post(
      `/documents/archive/${documentId}/supplier-invoice/create/`,
      {},
      {
        headers: {
          "x-company": companyId,
        },
      }
    ),
  companyDocumentCreate: (companyId, documentId, category, title, financialYearId) =>
    client.post(
      `/documents/archive/${documentId}/company-document/create/`,
      {
        title,
        category,
        financial_year: financialYearId,
      },
      {
        headers: {
          "x-company": companyId,
        },
      }
    ),
  history: (companyId, documentId, config) => {
    return client.get(`/documents/archive/${documentId}/history/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const salaryDocuments = {
  list: (companyId, filters, config) =>
    client.get(`/documents/salary/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  save(companyId, files, data) {
    if (data.id) {
      return client.put(`/documents/salary/${data.id}/update/`, data, {
        headers: { "x-company": companyId },
      });
    }
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });
    if (data.type === "document") {
      files.forEach((file, i) => {
        if (file) {
          formData.append("files", file, file.name);
        }
      });
    }
    return client.post(`/documents/salary/create/`, formData, {
      headers: {
        "x-company": companyId,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  detail: (companyId, documentId, config) =>
    client.get(`/documents/salary/${documentId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  remove: (companyId, documentId, config) =>
    client.delete(`/documents/salary/${documentId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  duplicate: (companyId, documentId, config) =>
    client.post(
      `/documents/salary/${documentId}/duplicate/`,
      {},
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    ),
  toggleShareCustomer: (companyId, documentId, notifyCustomer) =>
    client.patch(
      `/documents/salary/${documentId}/toggle-share-customer/`,
      {
        notify_customer: notifyCustomer,
      },
      {
        headers: {
          "x-company": companyId,
        },
      }
    ),
  toggleArchive: (companyId, documentId) =>
    client.patch(
      `/documents/salary/${documentId}/toggle-archive/`,
      {},
      {
        headers: {
          "x-company": companyId,
        },
      }
    ),
  history: (companyId, documentId, config) => {
    return client.get(`/documents/salary/${documentId}/history/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const consultDocuments = {
  list: (companyId, filters, config) =>
    client.get(`/documents/consultant/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  byCategory: (companyId, categoryIds, filters, config) =>
    client.get(`/documents/consultant/by-categories/`, {
      params: { categories: categoryIds, ...filters },
      headers: {
        "x-company": companyId,
      },
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    }),
  detail: (companyId, documentId, config) =>
    client.get(`/documents/consultant/${documentId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  save(companyId, files, data) {
    if (data.id) {
      return client.put(`/documents/consultant/${data.id}/update/`, data, {
        headers: { "x-company": companyId },
      });
    }
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });
    files.forEach((file, i) => {
      if (file) {
        formData.append("files", file, file.name);
      }
    });
    return client.post(`/documents/consultant/create/`, formData, {
      headers: {
        "x-company": companyId,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  remove: (companyId, documentId, config) =>
    client.delete(`/documents/consultant/${documentId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  duplicate: (companyId, documentId, config) =>
    client.post(
      `/documents/consultant/${documentId}/duplicate/`,
      {},
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    ),
  toggleArchive: (companyId, documentId) =>
    client.patch(
      `/documents/consultant/${documentId}/toggle-archive/`,
      {},
      {
        headers: {
          "x-company": companyId,
        },
      }
    ),
  history: (companyId, documentId, config) => {
    return client.get(`/documents/consultant/${documentId}/history/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const comment = {
  list: (companyId, documentId) => {
    return client.get(`/documents/${documentId}/comments/`, {
      params: {
        page_size: 100,
      },
      headers: {
        "x-company": companyId,
      },
    });
  },
  remove: (companyId, documentId, commentId) => {
    return client.delete(`/documents/${documentId}/comments/${commentId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
    });
  },
  create: (companyId, documentId, text) => {
    return client.post(
      `/documents/${documentId}/comments/create/`,
      { text },
      {
        headers: {
          "x-company": companyId,
        },
      }
    );
  },
};

export const trashDocuments = {
  list: (filters, config) =>
    client.get(`/documents/trash/`, {
      params: filters,
      ...config,
    }),
  remove: (documentId, config) =>
    client.delete(`/documents/trash/${documentId}/delete/`, {
      ...config,
    }),
};

export const validFiles = {
  send: (files, allowedExtensions, maxSize) => {
    const formData = new FormData();
    allowedExtensions.forEach((item) => formData.append("allowed_extensions", item));
    formData.append("max_size", maxSize);
    files.forEach((file, i) => {
      if (file) {
        formData.append("files", file, file.name);
      }
    });
    return client.post(`/documents/valid-files/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};
