import React, { useRef, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import LocalFilePreviewModal from "components/modals/LocalFilePreviewModal";
import useModal from "hooks/useModal";
import { useTranslation } from "react-i18next";
import { validFiles as validFilesApi } from "api2/documents";
import { BASE_ALLOWED_FILE_SIZE } from "utils/file";

function UploadButton({ fileSize, fileTypes, onFileChange }) {
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);
  const previewModal = useModal();
  const { t } = useTranslation("common");

  function validate(selectedFile) {
    const extension = `.${selectedFile.name.split(".").pop().toLowerCase()}`;
    const backendValidation = () => {
      validFilesApi
        .send([selectedFile], fileTypes, fileSize)
        .then(() => {})
        .catch(() => {
          toast.error(t("msg:fileBackendValidationFailed", { fileName: selectedFile.name }));
        });
    };
    if (!fileTypes.includes(selectedFile.type) && !fileTypes.includes(extension)) {
      // allowed extension...
      toast.error(t("common:file.notSupported", { formats: fileTypes }), {
        autoClose: 4000,
      });
      return false;
    }
    if (selectedFile.size > fileSize) {
      // max file size is 20mb
      toast.error(`The file size is too big. Max ${(fileSize / (1024 * 1024)).toFixed(1)} MB allowed`, {
        autoClose: 4000,
      });
      return false;
    }
    backendValidation(); // add file even if backend validation failed - only popup msg
    return true;
  }

  function onFileSelected(event) {
    event.preventDefault();
    const selectedFile = (event.target.files || event.dataTransfer.files)[0];
    if (selectedFile) {
      const isValid = validate(selectedFile);
      if (isValid) {
        // handle
        setFile(selectedFile);
        onFileChange(selectedFile);
      }
    }
  }

  const onFileRemove = (event) => {
    event.stopPropagation();
    setFile(null);
    onFileChange(null);
  };

  return (
    <>
      <div className="upload-btn">
        <ButtonGroup size="sm">
          {!file ? (
            <Button type="button" variant="primary" onClick={() => fileRef.current.click()}>
              <i className="fas fa-upload" />
            </Button>
          ) : (
            <>
              <Button type="button" variant="success" onClick={previewModal.open}>
                <i className="fas fa-search" />
              </Button>
              <Button type="button" variant="red" onClick={onFileRemove}>
                <i className="fas fa-times" />
              </Button>
            </>
          )}
        </ButtonGroup>
        <input ref={fileRef} type="file" onChange={onFileSelected} />
      </div>
      {previewModal.show && <LocalFilePreviewModal file={file} handleClose={previewModal.close} />}
    </>
  );
}

UploadButton.defaultProps = {
  fileSize: BASE_ALLOWED_FILE_SIZE,
};

export default UploadButton;
