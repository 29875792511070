const types = [
  {
    label: "ROT",
    options: [
      { value: "construction", label: "Construction", type: "ROT" },
      { value: "electricity", label: "Electricity", type: "ROT" },
      { value: "glass_metal_work", label: "Glass/metal work", type: "ROT" },
      {
        value: "ground_drainage_work",
        label: "Ground drainage work",
        type: "ROT",
      },
      { value: "masonry", label: "Masonry", type: "ROT" },
      {
        value: "painting_wall_papering",
        label: "Painting/Wallpapering",
        type: "ROT",
      },
      { value: "hvac", label: "HVAC", type: "ROT" },
    ],
  },
  {
    label: "RUT",
    options: [
      { value: "baby_sitting", label: "Baby sitting", type: "RUT" },
      { value: "moving", label: "Moving", type: "RUT" },
      { value: "it_service", label: "IT-services", type: "RUT" },
      {
        value: "textile_clothing",
        label: "Clothing and textile care",
        type: "RUT",
      },
      { value: "personal_care", label: "Personal care", type: "RUT" },
      { value: "snow_plowing", label: "Snow plowing", type: "RUT" },
      { value: "cleaning", label: "Cleaning", type: "RUT" },
      { value: "gardening", label: "Gardening", type: "RUT" },
      { value: "white_goods", label: "WhiteGoods", type: "RUT" },
      { value: "furnituring", label: "Furnituring", type: "RUT" },
      { value: "house_supervision", label: "HouseSupervision", type: "RUT" },
    ],
  },
];

export function rotRutByValue(value) {
  for (let i = 0; i < types.length; i++) {
    for (let j = 0; j < types[i].options.length; j++) {
      if (types[i].options[j].value === value) {
        return types[i].options[j];
      }
    }
  }
  return null;
}

export default types;
