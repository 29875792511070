import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import * as offerAPI from "api2/offers";
import "./OfferModal.scss";
import { toast } from "react-toastify";

function OfferModal({ companyId, row, handleClose, reload }) {
  const { t } = useTranslation("common");

  return (
    <Modal
      animation={false}
      show
      onHide={handleClose}
      className="offer-modal"
      scrollable
      backdrop="static"
      keyboard={false}
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("ci:offer.modal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("ci:offer.modal.msg", { customerName: row.customer_name })}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="link"
          className="offer-modal-cancel"
          onClick={() => {
            handleClose();
          }}
        >
          {t("common:actions.cancel")}
        </Button>
        <div>
          <Button
            variant="secondary"
            className="offer-modal-action"
            onClick={() => {
              offerAPI
                .approveToInvoice(companyId, row.id)
                .then((response) => {
                  reload();
                  handleClose();
                })
                .catch((error) => {
                  if (error.data.__all__) {
                    toast.error(error.data.__all__);
                  } else {
                    toast.error(t("msg:canNotExecuteAction"));
                  }
                });
            }}
          >
            {t("common:actions.createInvoice")}
          </Button>
          <Button
            variant="primary"
            className="offer-modal-action action-spacing"
            onClick={() => {
              offerAPI
                .approveToOrder(companyId, row.id)
                .then((response) => {
                  reload();
                  handleClose();
                })
                .catch((error) => {
                  if (error.data.__all__) {
                    toast.error(error.data.__all__);
                  } else {
                    toast.error(t("msg:canNotExecuteAction"));
                  }
                });
            }}
          >
            {t("common:actions.createOrder")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
export default OfferModal;
