import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { TooltipActionButton, TooltipActionLink } from "components/ui/buttons";
import { confirmRemove } from "components/modals/ConfirmModal";
import * as ciAPI from "api2/customer-invoices";
import useTable from "components/tables/btable/useTable";
import { useResolvedPath } from "react-router-dom";
import { codesForCustomerInvoices } from "components/perms/PermCodes";
import { PermCodeRequired } from "components/perms";

function ContractInvoiceActions({ companyId, row }) {
  const { t } = useTranslation("ci");
  const url = useResolvedPath("").pathname;
  const {
    dataActions: { reload },
  } = useTable();

  const onDelete = async () => {
    const answer = await confirmRemove(
      t("confirm.removeContract", {
        contractNumber: row.id,
        customerName: row.customer_name,
      })
    );
    if (answer) {
      ciAPI.contractInvoices
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reload();
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };

  return (
    <PermCodeRequired code={codesForCustomerInvoices.manage}>
      <TooltipActionLink text={t("common:actions.change")} link={`${url}/${row.id}/change`} icon="fas fa-pen" />
      <TooltipActionLink text={t("common:actions.duplicate")} link={`${url}/${row.id}/duplicate`} icon="fas fa-copy" />
      <TooltipActionButton
        variant="action-red"
        text={t("common:actions.remove")}
        onClick={onDelete}
        icon="fas fa-trash"
      />
    </PermCodeRequired>
  );
}

export default ContractInvoiceActions;
