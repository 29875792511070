import client from "api2/client";

import { differenceInCalendarDays, parseISO } from "date-fns";
import _ from "lodash";
import * as options from "../api/options";
import { filesToBase64 } from "../utils/file";
import { fromBillectaAmount } from "../utils/calc/ci";

export function companyOffers(companyId, filters, config) {
  return client.get(`/offers/`, {
    params: filters,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export async function offerSave(companyId, data, newFiles, config) {
  data.attachments = await filesToBase64(newFiles);
  if (data.id) {
    return client.put(`/offers/${data.id}/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  }
  return client.post(`/offers/create/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function denyOffer(companyId, offerId, config) {
  return client.patch(`/offers/${offerId}/deny/`, null, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function approveToInvoice(companyId, offerId, config) {
  return client.post(`/offers/${offerId}/invoice/`, null, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function approveToOrder(companyId, offerId, config) {
  return client.post(`/offers/${offerId}/order/`, null, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export async function previewPdf(companyId, invoiceData, newFiles, config) {
  invoiceData.attachments = await filesToBase64(newFiles);
  return client.post(`/offers/preview/pdf/`, invoiceData, {
    responseType: "blob",
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function details(companyId, offerId, config) {
  const vatOptions = options.vatOptions.asList();
  return client
    .get(`/offers/${offerId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) => {
      const booking_date = response.data.booking_date ? parseISO(response.data.booking_date) : null;
      const due_date = response.data.due_date ? parseISO(response.data.due_date) : null;
      const payment_terms = differenceInCalendarDays(due_date, booking_date);
      const valid_date = response.data.valid_date ? parseISO(response.data.valid_date) : null;
      const valid_period = valid_date && differenceInCalendarDays(valid_date, booking_date);
      const currency = response.data.amount_currency;
      return {
        ...response.data,
        payment_terms,
        valid_period,
        customer: !_.isEmpty(response.data.customer_snapshot) && {
          value: response.data.customer,
          label: response.data.customer_snapshot.name,
          ...response.data.customer_snapshot,
        },
        connected_products: _.keyBy(response.data.connected_products, (p) => p.id),
        extra_receivers: response.data.extra_receivers
          ? response.data.extra_receivers.filter((item) => item.length > 0)
          : [],
        rot_rut: response.data.rot_rut
          ? {
              customers: response.data.rot_rut.customers.map((customer) => ({
                ...customer,
                amount: fromBillectaAmount(customer.amount, currency),
              })),
              property_designation: response.data.rot_rut.property_designation || "",
              residence_org_no: response.data.rot_rut.residence_org_no || "",
              work_start: response.data.rot_rut.work_start,
              work_end: response.data.rot_rut.work_end,
            }
          : null,
        booking_date,
        due_date: response.data.due_date ? parseISO(response.data.due_date) : null,
        records: response.data.records.map((record) => {
          record.key = _.uniqueId("ln");
          if (record.record_type === "product") {
            return {
              ...record,
              product: {
                ...record.product,
                vat_percent: options.vatOptions.getOption(record.product.vat_percent) || vatOptions[0],
                unit_price: fromBillectaAmount(record.product.unit_price, currency),
                account: record.product.account || 3001,
              },
              rot_rut: record.rot_rut
                ? {
                    ...record.rot_rut,
                    amount: fromBillectaAmount(record.rot_rut.amount, currency),
                  }
                : null,
            };
          }
          return record;
        }),
      };
    });
}

export function sendToCustomer(companyId, orderId) {
  return client.post(`/offers/${orderId}/send/`, null, {
    headers: {
      "x-company": companyId,
    },
  });
}

export function history(companyId, offerId, config) {
  return client.get(`/offers/${offerId}/history/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}
