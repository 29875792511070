import React from "react";
import { useField } from "formik";
import { FormGroup } from "components/formik";
import { codesForConsultManage, codesFor } from "components/perms/PermCodes";

function CompanyEmployeePicker({
  companyId,
  name,
  label,
  required = true,
  placeholder = "",
  isClearable = true,
  valueField = "id",
  onChange,
  excludedUserIds = [],
  options = [],
  ...props
}) {
  const filteredOptions = options.map((option) => ({
    ...option,
    isDisabled:
      excludedUserIds.includes(option.user_id) ||
      !(
        option.permission_codes.includes(codesFor.supplierInvoices.approve) &&
        !option.permission_codes.includes(codesForConsultManage.enabled)
      ),
  }));
  const [field, , helpers] = useField(name);

  const handleOnChange = (selected) => {
    helpers.setValue(selected);
    if (onChange) {
      onChange(selected);
    }
  };

  return (
    <FormGroup.SimpleSelect
      label={label}
      name={name}
      options={filteredOptions}
      required={required}
      placeholder={placeholder}
      isClearable={isClearable}
      isMulti
      value={field.value}
      onChange={handleOnChange}
      {...props}
    />
  );
}

export default CompanyEmployeePicker;
