import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import ContractInvoiceForm from "components/forms/ContractInvoiceForm";

function ContractInvoiceAddPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const invoice = {
    customer: null,
    template: null,
    ongoing: false,
    is_active: true,
    contract_date: new Date(),
    period_start: new Date(),
    period_end: null,
    invoice_interval: 1,
    payment_terms: 15,
    currency_code: "SEK",
    our_reference: "",
    your_reference: "",
    create_terms: 1,
    reversed_vat: false,
    reversed_vat_receiver_no: "",
    records: [],
    delivery_method: "email",
  };

  const onCreate = (newInvoice) => {
    navigate(-1);
  };

  return <ContractInvoiceForm invoice={invoice} company={company} onSave={onCreate} />;
}

export default ContractInvoiceAddPage;
