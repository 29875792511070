import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import useInitialAsync from "hooks/useInitialAsync";
import * as ciAPI from "api2/customer-invoices";
import { RocketLoader } from "components/ui/loaders";
import ContractTemplateForm from "components/forms/ContractTemplateForm";

function ContractTemplateEditPage() {
  const company = useOutletContext();
  const { id: contractId } = useParams();
  const navigate = useNavigate();
  const { loading, item } = useInitialAsync(
    ({ cancelToken }) => {
      return ciAPI.contractTemplates.details(company.id, contractId, { cancelToken });
    },
    {},
    [company.id, contractId]
  );

  const onUpdate = (invoice) => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }

  return <ContractTemplateForm invoice={item} company={company} onSave={onUpdate} />;
}

export default ContractTemplateEditPage;
