import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import CustomerInvoiceForm from "components/forms/CustomerInvoiceForm";

function CustomerInvoiceAddPage() {
  const navigate = useNavigate();
  const company = useOutletContext();
  const invoice = {
    customer: null,
    booking_date: new Date(),
    status: 0,
    sub_status: "",
    amount_currency: "SEK",
    vat_amount: 0,
    payment_terms: company.default_invoice_payment_terms,
    delivery_method: "email",
    reversed_vat: false,
    reversed_vat_receiver_no: "",
    records: [],
    rot_rut: null,
  };

  const onCreate = (newInvoice) => {
    if (newInvoice?.draft === false && newInvoice?.delivery_method === "manual") {
      navigate(`/invoicing/customer-invoices/${newInvoice.id}/preview`, { replace: true });
    } else {
      navigate(-1);
    }
  };

  return <CustomerInvoiceForm invoice={invoice} company={company} onSave={onCreate} />;
}

export default CustomerInvoiceAddPage;
