import { Dropdown } from "react-bootstrap";
import React, { useContext } from "react";
import { SelectionContext } from "state/providers/SelectionProvider";
import { useTranslation } from "react-i18next";
import { confirmExecute } from "components/modals/ConfirmModal";
import * as ciAPI from "api2/customer-invoices";
import useModal from "hooks/useModal";
import CustomerInvoiceMultiActionResultsModal from "components/modals/ResultsModal/CustomerInvoiceMultiActionResultsModal";
import { toast } from "react-toastify";
import useTable from "../btable/useTable";

function MultiActionButton({ companyId = "customerInvoices", mode }) {
  const { t } = useTranslation(["common", "ci"]);
  const { getFlatten, clearSelection } = useContext(SelectionContext);
  const actionModal = useModal();
  const {
    dataActions: { reload },
  } = useTable();
  const selected = getFlatten();

  if (!selected.length) {
    return null;
  }

  const remindSelected = async () => {
    const answer = await confirmExecute(t("ci:confirm.sendReminders"));
    if (!answer) {
      return;
    }
    const promises = [];
    selected.forEach((item, i) => {
      const promise = new Promise((resolve, reject) => {
        setTimeout(() => {
          return ciAPI.invoice
            .sendReminder(companyId, item.id)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => reject(error));
        }, i * 300);
      });
      promises.push(promise);
    });

    Promise.all(promises)
      .then(() => {
        toast.success(t("msg:sent"));
        reload();
        clearSelection();
      })
      .catch((error) => {
        if (error.data && error.data.__all__) {
          toast.error(error.data.__all__);
        } else {
          toast.error(t("msg:canNotExecuteAction"));
        }
      });
  };

  const removeSelected = async () => {
    const answer = await confirmExecute(t("ci:confirm.removeSelectedDraft"));
    if (answer) {
      actionModal.open({
        apiRequest: ciAPI.invoice.remove,
        successText: "common:statuses.removed",
      });
    }
  };

  const sendSelected = async () => {
    const answer = await confirmExecute(t("ci:confirm.sendSelectedDraft"));
    if (answer) {
      actionModal.open({
        apiRequest: ciAPI.invoice.attest,
        successText: "common:statuses.sent",
      });
    }
  };

  const onActionModalClose = () => {
    reload();
    clearSelection();
    actionModal.close();
  };

  return (
    <>
      <Dropdown className="float-right ml-2">
        <Dropdown.Toggle variant="primary" className="dropdown-toggle mr-0 waves-effect waves-light">
          {t("common:actions.actions")} <i className="fas fa-chevron-down" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-right">
          {mode === "open" ? (
            <Dropdown.Item onClick={remindSelected}>{t("ci:actions.sendReminders")}</Dropdown.Item>
          ) : (
            <>
              <Dropdown.Item onClick={removeSelected}>{t("ci:actions.removeSelectedDrafts")}</Dropdown.Item>
              <Dropdown.Item onClick={sendSelected}>{t("ci:actions.sendSelectedDrafts")}</Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {actionModal.show && !!actionModal.data && (
        <CustomerInvoiceMultiActionResultsModal
          selectedItems={selected}
          apiRequest={actionModal.data.apiRequest}
          successText={actionModal.data.successText}
          handleClose={onActionModalClose}
        />
      )}
    </>
  );
}

export default MultiActionButton;
