import { formatDate } from "utils/date";
import { formatDiscount, formatRotRutRecord, toBillectaAmount } from "utils/calc/ci";
import { roundMoney } from "../../../utils/money";

export function formatData(data) {
  const currency = data.currency_code.value;
  return {
    ...data,
    customer: data.customer.id,
    contract_date: formatDate(data.contract_date),
    period_start: formatDate(data.period_start),
    period_end: !data.ongoing && data.period_end ? formatDate(data.period_end) : null,
    payment_terms: data.payment_terms.value,
    create_terms: data.create_terms.value,
    currency_code: data.currency_code.value,
    records: data.records.map((record, idx) => {
      if (record.record_type === "product") {
        return {
          old: record.product.old || {}, // TODO: TO remove
          record_type: "product",
          message: null,
          rot_rut: formatRotRutRecord(record, currency),
          discount: formatDiscount(record, currency),
          product: {
            ...record.product,
            unit_price: roundMoney(toBillectaAmount(record.product.unit_price, currency)),
            quantity: roundMoney(record.product.quantity),
            currency_code: currency,
            vat_percent:
              record.product.vat_percent && !data.reversed_vat ? parseInt(record.product.vat_percent.value, 10) : 0,
          },
          sequence_no: idx + 1,
        };
      }
      return {
        old: record.old || {}, // TODO: TO remove
        record_type: "message",
        message: {
          ...record.message,
        },
        product: null,
        rot_rut: null,
        discount: null,
        sequence_no: idx + 1,
      };
    }),
  };
}
