import React, { useEffect, useMemo, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { Document } from "components/ui/documents-refactored/document-box";
import * as ciAPI from "api2/customer-invoices";
import * as offerAPI from "api2/offers";
import * as orderAPI from "api2/orders";
import { Loader } from "components/ui/loaders";
import { DraggableModalDialog } from "../DraggableModalDialog";

function CustomerInvoicePreviewModal({ onClose, companyId, invoiceData, forOffer = false, forOrder }) {
  const { t } = useTranslation("ci");
  const [data, setData] = useState({
    url: "",
    type: "",
    size: 0,
    loading: true,
    error: "",
  });
  const endpoint = useMemo(() => {
    if (forOffer) {
      return offerAPI.previewPdf;
    }
    if (forOrder) {
      return orderAPI.orderPreviewPdf;
    }
    return ciAPI.invoice.previewPdf;
  }, [forOrder, forOffer]);
  useEffect(() => {
    const signal = axios.CancelToken.source();
    endpoint(
      companyId,
      {
        ...invoiceData,
        invoice_id: invoiceData.id,
      },
      invoiceData.newFiles,
      { cancelToken: signal.token }
    )
      .then((response) => {
        const blob = response.data;
        setData({
          url: window.URL.createObjectURL(blob),
          type: blob.type,
          size: blob.size,
          loading: false,
          error: "",
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          setData({
            url: "",
            type: "",
            size: 0,
            loading: false,
            error: error.data && error.data.__all__ ? error.data.__all__ : t("common:file.cannotLoadFile"),
          });
        }
      });
    // const formData = new FormData();
    // const { newFiles } = invoiceData;
    // delete invoiceData.newFiles;
    // if (newFiles) {
    //   newFiles.forEach((file, i) => {
    //     if (file) {
    //       formData.append("attachments", file, file.name);
    //     }
    //   });
    // }
    // formData.append("invoice", JSON.stringify(invoiceData));
    // const properClient = forOrder ? client2 : client;
    // const properData = forOrder ? invoiceData : formData;
    // properClient
    //   .post(endpoint, properData, {
    //     responseType: "blob",
    //     cancelToken: signal.token,
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //       "x-company": companyId,
    //       // "Content-Type": undefined,
    //     },
    //   })
    //   .then((response) => {
    //     const blob = response.data;
    //     setData({
    //       url: window.URL.createObjectURL(blob),
    //       type: blob.type,
    //       size: blob.size,
    //       loading: false,
    //       error: "",
    //     });
    //   })
    //   .catch(async (error) => {
    //     if (!axios.isCancel(error)) {
    //       setData({
    //         url: "",
    //         type: "",
    //         size: 0,
    //         loading: false,
    //         error: error.data && error.data.__all__ ? error.data.__all__ : t("common:file.cannotLoadFile"),
    //       });
    //     }
    //   });
    return () => {
      signal.cancel("aborted");
    };
  }, [companyId, setData, endpoint, invoiceData, t]);

  return (
    <Modal show onHide={onClose} size="xl" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        {!forOffer && !forOrder ? (
          <Modal.Title className="m-0">{t("actions.previewInvoice")}</Modal.Title>
        ) : (
          <Modal.Title className="m-0">{forOffer ? t("actions.previewOffer") : t("actions.previewOrder")}</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        {data.loading && <Loader />}
        {data.url && <Document url={data.url} type={data.type} fullMode />}
        {!data.loading && data.error && <Alert variant="danger">{data.error}</Alert>}
      </Modal.Body>
    </Modal>
  );
}

export default CustomerInvoicePreviewModal;
