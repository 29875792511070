import React, { useCallback, useMemo, useState } from "react";

const INITIAL_STATE = {};

const SelectionContext = React.createContext({});

function SelectionProvider({ children }) {
  const [selection, setSelection] = useState(INITIAL_STATE);
  const [toggleAllValues, setToggleAllValues] = useState(INITIAL_STATE);

  const toggleSelection = useCallback(
    (tableId, row) => {
      const updated = { ...selection };
      if (!(tableId in updated)) {
        updated[tableId] = {};
      }
      if (row.id in updated[tableId]) {
        setToggleAllValues({ ...toggleAllValues, [tableId]: false });
        delete updated[tableId][row.id];
      } else {
        updated[tableId][row.id] = row;
      }
      setSelection(updated);
    },
    [selection, toggleAllValues]
  );

  const removeSelection = useCallback(
    (tableId, row) => {
      if (selection[tableId] && selection[tableId][row.id]) {
        toggleSelection(tableId, row);
      }
    },
    [selection, toggleSelection]
  );

  function clearSelection() {
    setSelection({});
    setToggleAllValues(false);
  }

  function excludeRowsPredicate(tableId, row, filters) {
    if (tableId === "prelimOutlays") {
      return row.sub_status === "prelim";
    }
    if (tableId === "unpaidSupplierInvoices") {
      return !row.in_signing && !row.deactivate_payment && row.amount_currency === "SEK";
    }
    if (tableId === "prelimSupplierInvoices") {
      return row.can_approve;
    }
    if (tableId === "unpaidOutlays") {
      return !row.in_signing;
    }
    if (tableId === "unpaidSalaries") {
      return !row.in_signing;
    }
    if (tableId === "unpaidDirectPayments") {
      return !row.in_signing;
    }
    if (tableId === "unpaidTaxDP") {
      return !(row.in_signing || row.disabled);
    }
    if (tableId === "timeReports") {
      return !row.notSelectable;
    }
    if (tableId === "customerInvoices") {
      return row.sub_status === "draft" || filters?.mode === "open";
    }
    if (tableId === "vatEu") {
      return row.status !== "error";
    }
    if (tableId === "customerInvoicesRotRut") {
      return Math.abs(row.customer_amount_to_pay) <= 1;
    }
    return true;
  }

  const toggleAll = useCallback(
    (tableId, data, filters) => {
      if (
        tableId in selection &&
        Object.keys(selection[tableId]).length ===
          data.filter((row) => excludeRowsPredicate(tableId, row, filters)).length
      ) {
        setToggleAllValues({ ...toggleAllValues, [tableId]: false });
        setSelection({ ...selection, [tableId]: {} });
      } else {
        const updated = { ...selection };
        if (!(tableId in updated)) {
          updated[tableId] = {};
        }
        data.forEach((row) => {
          if (!(row.id in updated[tableId])) {
            if (excludeRowsPredicate(tableId, row, filters)) {
              updated[tableId][row.id] = row;
            }
          }
        });
        setToggleAllValues({ ...toggleAllValues, [tableId]: true });
        setSelection(updated);
      }
    },
    [selection, toggleAllValues]
  );

  const getFlatten = useCallback(
    () => Object.values(selection).reduce((list, item) => [...list, ...Object.values(item)], []),
    [selection]
  );

  const stateValue = useMemo(
    () => ({
      selection,
      toggleSelection,
      removeSelection,
      toggleAll,
      toggleAllValues,
      clearSelection,
      getFlatten,
    }),
    [getFlatten, removeSelection, selection, toggleAll, toggleAllValues, toggleSelection]
  );

  return <SelectionContext.Provider value={stateValue}>{children}</SelectionContext.Provider>;
}

export { SelectionContext, SelectionProvider };
