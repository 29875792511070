import React, { useCallback, useMemo, useState } from "react";
import { psd2 as psd2Api } from "api2/integrations";
import { formatDate } from "utils/date";

const BasketContext = React.createContext(undefined);

const STAGE_INITIAL = "initial";
const STAGE_DECOUPLED = "decoupled";
const STAGE_SUMMARY = "summary";
const STAGE_SINGLE = "single";

function BasketProvider({ companyId, initialBasketId, children }) {
  const [basketId, setBasketId] = useState(initialBasketId || "");
  const [singlePayments, setSinglePayments] = useState([]);
  const [isSameDevice, setSameDevice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [bic, setBic] = useState("");
  const [stage, setStage] = useState(initialBasketId ? STAGE_SUMMARY : STAGE_INITIAL);
  const reset = useCallback(() => {
    setBasketId("");
    setError("");
    setStage(STAGE_INITIAL);
    setSameDevice(false);
  }, []);

  const getInitialList = useCallback(
    (verificationIds) => {
      setLoading(true);
      return psd2Api
        .paymentInitiation(companyId, verificationIds)
        .then((response) => {
          if (response.data.bic) {
            setBic(response.data.bic);
          }
          return response;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [companyId]
  );

  const startSinglePayments = useCallback((payments) => {
    setStage(STAGE_SINGLE);
    setSinglePayments(payments);
  }, []);

  const createBasket = useCallback(
    (paymentsData) => {
      setLoading(true);
      setError("");
      return psd2Api.basket
        .create(
          companyId,
          paymentsData.map((item) => ({
            verification: item.id,
            payment_date: formatDate(item.payment_date),
          })),
          isSameDevice
        )
        .then((response) => {
          if (response.data.error) {
            setBasketId(response.data.basket_id);
            setError(response.data.error);
            setStage(STAGE_SUMMARY);
            return null;
          }
          const redirectLink = response.data.redirect_link;
          if (redirectLink) {
            window.location = redirectLink;
          } else {
            setTimeout(() => {
              // some banks (nordea) needs a little delay before checking status
              setBasketId(response.data.basket_id);
              setStage(STAGE_DECOUPLED);
              setLoading(false);
            }, 1500);
          }
          return redirectLink;
        })
        .catch((response) => {
          if (response.data.__all__) {
            setError(response.data.__all__);
          } else {
            setError("");
          }
          setLoading(false);
          throw response;
        });
    },
    [companyId, isSameDevice]
  );

  const checkSigning = useCallback(() => {
    return psd2Api.basket
      .signCheck(companyId, basketId, isSameDevice)
      .then((response) => {
        if (response.data.status === 1) {
          setStage("summary");
        } else if (response.data.status === -1) {
          setError(response.data.error);
          setStage(STAGE_SUMMARY);
        }
        return response;
      })
      .catch((response) => {
        setError(response.data.error);
        setStage(STAGE_INITIAL);
        throw response;
      });
  }, [companyId, basketId, isSameDevice]);

  const checkBasket = useCallback(() => {
    setLoading(true);
    return psd2Api.basket
      .paymentsCheck(companyId, basketId)
      .finally(() => {
        setLoading(false);
      })
      .catch((response) => {
        setError(response.data.__all__);
      });
  }, [companyId, basketId]);

  const stateValue = useMemo(
    () => ({
      companyId,
      stage,
      loading,
      bic,
      error,
      basketId,
      singlePayments,
      isSameDevice,
      actions: {
        createBasket,
        getInitialList,
        checkSigning,
        checkBasket,
        startSinglePayments,
        setSameDevice,
        reset,
        setLoading,
      },
    }),
    [
      companyId,
      stage,
      bic,
      loading,
      error,
      basketId,
      singlePayments,
      createBasket,
      getInitialList,
      checkSigning,
      checkBasket,
      startSinglePayments,
      reset,
      setLoading,
      isSameDevice,
      setSameDevice,
    ]
  );
  return <BasketContext.Provider value={stateValue}>{children}</BasketContext.Provider>;
}

export { BasketContext };

export default BasketProvider;
