import React from "react";
import { Card } from "react-bootstrap";
import { useOutletContext, useSearchParams } from "react-router-dom";

import TableProvider from "components/tables/btable/provider";
import { SupplierInvoiceTable } from "components/tables";
import * as supplierInvoiceApi from "api2/supplier-invoices";

function SupplierInvoiceListPage({
  initialMode,
  allowedModes,
  selectable = false,
  tableId = "supplierInvoices",
  extraInitialFilters = {},
}) {
  const company = useOutletContext();
  const [search] = useSearchParams();
  const mode = search.get("mode");
  const initialFilters = {
    mode: initialMode || mode || "",
    foreign_invoices: search.get("foreign", false),
    ...extraInitialFilters,
  };
  const listAPI =
    tableId === "prelimSupplierInvoices"
      ? supplierInvoiceApi.companyApprovalSupplierInvoices
      : supplierInvoiceApi.companySupplierInvoices;
  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => listAPI(company.id, filters, config)}
          initialFilters={initialFilters}
        >
          <SupplierInvoiceTable
            companyId={company.id}
            selectable={selectable}
            forStatus={initialMode}
            allowedModes={allowedModes}
            approvalFlowEnabled={company.approval_flow_enabled}
          />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default SupplierInvoiceListPage;
