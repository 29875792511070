import { saveAs } from "file-saver";

export function getFileNameDispositionContent(response) {
  const disposition = response.headers["content-disposition"];
  if (disposition && (disposition.indexOf("inline" !== -1) || disposition.indexOf("attachment") !== -1)) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, "").replace(/^.*[\\/]/, "");
    }
  }
  return "";
}

export function saveBlobToComputer(response, defaultFileName) {
  const fileName = getFileNameDispositionContent(response) || defaultFileName;
  saveAs(response.data, fileName);
}

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve({
        content: reader.result,
        filename: file.name,
        content_type: file.type,
      });
    reader.onerror = (error) => reject(error);
  });
};

export async function filesToBase64(files) {
  const promises = [];
  for (let i = 0; i < files.length; i++) {
    promises.push(fileToBase64(files[i]));
  }
  return Promise.all(promises);
}

const SIE_EXTENSIONS = [".SIE", ".sie", ".Sie", ".SE", ".se", ".Se"];
const IMAGES_ALLOWED_FILE_EXTENSIONS = [".jpeg", ".jpg", ".png", ".heif", ".heic"];
const BASE_ALLOWED_FILE_EXTENSIONS = [...IMAGES_ALLOWED_FILE_EXTENSIONS, ".pdf"];
const BANK_FILES_EXTENSIONS = [".in", ".IN", ".bf", ".BF", ".kis", ".KIS", ".sus", ".SUS", ".dat", ".DAT"];
const ARCHIVE_ALLOWED_FILE_EXTENSIONS = [
  ...BASE_ALLOWED_FILE_EXTENSIONS,
  ".json",
  ".xml",
  ".csv",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ...SIE_EXTENSIONS,
  ...BANK_FILES_EXTENSIONS,
];
export const allowedFileExtensionsFor = {
  supplier_invoice: BASE_ALLOWED_FILE_EXTENSIONS,
  receipt: BASE_ALLOWED_FILE_EXTENSIONS,
  verification: BASE_ALLOWED_FILE_EXTENSIONS,
  salary: [".pdf"],
  salary_aga_pdf_reduction: BASE_ALLOWED_FILE_EXTENSIONS,
  salary_holiday_all_soc: BASE_ALLOWED_FILE_EXTENSIONS,
  file_archive: ARCHIVE_ALLOWED_FILE_EXTENSIONS,
  consult_archive: ARCHIVE_ALLOWED_FILE_EXTENSIONS,
  salaries_archive: ARCHIVE_ALLOWED_FILE_EXTENSIONS,
  company_archive: ARCHIVE_ALLOWED_FILE_EXTENSIONS,
  notes: BASE_ALLOWED_FILE_EXTENSIONS,
  sie_files: SIE_EXTENSIONS,
  asset: BASE_ALLOWED_FILE_EXTENSIONS,
  customer_invoice: [".pdf"],
  tax_authority: BASE_ALLOWED_FILE_EXTENSIONS,
  company_logo: [".jpg", ".jpeg", ".png"],
  specification: BASE_ALLOWED_FILE_EXTENSIONS,
  rot_rut: [".json"],
  import_companies: [".csv", ".xls"],
  import_customers: [".csv"],
  import_products: [".csv"],
  kyc: [".xls", ".xlsx", ".csv"],
};

export const BASE_ALLOWED_FILE_SIZE = 1024 * 1024 * 20; // 20 MB
export const EXTRA_ALLOWED_FILE_SIZE_FOR = { company_archive: 1024 * 1024 * 30 };
