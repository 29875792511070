import React, { useContext, useState } from "react";
import { Alert, Button, ButtonGroup, Card, Col, Row, ToggleButton } from "react-bootstrap";
import _ from "lodash";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useResolvedPath } from "react-router-dom";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import { NewDocuments } from "components/ui/documents/verification-documents";
import { SavedVerificationDocuments } from "components/ui/documents-refactored";
import * as documentAPI from "api/document";
import { AllError, CurrencyPicker, FormGroup } from "components/formik";
import * as outlayAPI from "api2/outlays";
import * as verAPI from "api2/verification";
import { formatDate, parseDate } from "utils/date";
import { RemoveButton, SubmitButton } from "components/ui/buttons";
import { FinancialYearDispatchContext } from "state/providers/FinancialYearProvider";
import * as options from "api/options";
import { useCompanyState } from "hooks/useCompany";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { useAuthState } from "hooks/useAuth";
import RepresentationForm from "components/forms/RepresentationForm";
import { codesFor, HasPermCode } from "components/perms";
import { confirmExecute } from "components/modals/ConfirmModal";
import { allowedFileExtensionsFor } from "utils/file";
import { handleActionErrors } from "api/errors";

function RescanAzoraBtn({ companyId, itemId, updateInvoice }) {
  const [loading, setLoading] = useState(false);
  const {
    accounts: { byId: accountById },
    currencies: { byId: currencies },
  } = useCompanyState();
  const { t } = useTranslation("common");
  const onScan = () => {
    setLoading(true);
    outlayAPI
      .rescanInvoice(companyId, itemId)
      .then((response) => {
        const output = response.data;
        output.booking_date = parseDate(output.booking_date);
        output.receipt_date = parseDate(output.receipt_date);
        output.amount_currency = currencies[output.amount_currency || "SEK"];
        if (response.data.account_id) {
          output.account = accountById[response.data.account_id];
        } else {
          output.account = null;
        }
        updateInvoice(output);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Button variant="secondary" onClick={onScan} disabled={loading}>
      {t("rescanInvoice")}
    </Button>
  );
}

function ReceiptForm({ company, outlay, onSave }) {
  const { t, i18n } = useTranslation("outlay");
  const {
    user: { name: userName, id: userId },
  } = useAuthState();
  const isCompanyConsult = HasPermCode(codesFor.consultManage.enabled);
  const currentPath = useResolvedPath("").pathname;
  const { checkYears } = useContext(FinancialYearDispatchContext);
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const draftRef = React.useRef(false);
  const investigateRef = React.useRef(false);

  const repActivities = options.repActivities.asList();
  const repPurposes = options.repPurposes.asList();

  const {
    receiptAccounts,
    costCenters: { byId: centerById, asOptions: centerOptions },
    projects: { byId: projectById, asOptions: projectOptions },
    currencies: { byId: currencies },
    employee: performerEmployee,
  } = useCompanyState();
  const [newFiles, setNewFiles] = useState([{ key: _.uniqueId("nd.") }]);
  const [isProjectRequired, setIsProjectRequired] = useState(false);
  const [isCenterRequired, setIsCenterRequired] = useState(false);

  const initial_amount_currency = {
    value: currencies[outlay.amount_currency].value,
    label:
      i18n.language === "sv"
        ? currencies[outlay.amount_currency].label_sv
        : currencies[outlay.amount_currency].label_en,
  };

  const formikProps = {
    initialValues: {
      ...outlay,
      account: outlay.account ? receiptAccounts.find((a) => a.number === outlay.account) : undefined,
      amount_currency: initial_amount_currency,
      project: outlay.project && projectById[outlay.project].is_active ? projectById[outlay.project] : null,
      cost_center:
        outlay.cost_center && centerById[outlay.cost_center].is_active ? centerById[outlay.cost_center] : null,
      representation: !_.isEmpty(outlay.representation)
        ? {
            activity: options.repActivities.getOption(outlay.representation.activity),
            purpose: options.repPurposes.getOption(outlay.representation.purpose),
            participants: outlay.representation.participants.filter((p) => !!p.name.trim() && !!p.company.trim()),
          }
        : null,
    },
    validationSchema: yup.object().shape({
      receipt_date: yup.date().nullable().required(),
      account: yup.object().nullable().required(),
      amount: yup.number().required(),
      vat_amount: yup.number().required(),
      cost_center: yup
        .object()
        .nullable()
        .when("account", ([account], schema) => {
          return account && account.cost_center_option === "mandatory" ? schema.required() : schema;
        }),
      project: yup
        .object()
        .nullable()
        .when("account", ([account], schema) => {
          return account && account.project_option === "mandatory" ? schema.required() : schema;
        }),
    }),
    onSubmit: async (values, { setErrors }) => {
      const executeAnyway = await checkYears(values.receipt_date);
      if (!executeAnyway) {
        return false;
      }
      let representation = {};

      const isDuplication = await outlayAPI
        .receiptCheckDuplicates(company.id, {
          created_by_id: userId,
          amount: values.amount,
          receipt_date: formatDate(values.receipt_date),
          receipt_id: values.id || null,
          vat_amount: values.vat_amount,
        })
        .then((response) => response.data.has_duplicates);
      if (isDuplication) {
        const answer = await confirmExecute(t("confirm.allowReceiptDuplicate"));
        if (!answer) {
          return false;
        }
      }

      if (values.account.number === 6072) {
        if (_.isEmpty(values.representation)) {
          toast.error("Representation invalid.", { autoClose: 4000 });
          return false;
        }
        representation = {
          activity: values.representation.activity.value,
          purpose: values.representation.purpose.value,
          participants: values.representation.participants.filter((p) => !!p.name.trim() && !!p.company.trim()),
        };
        if (representation.participants.length === 0) {
          toast.error("Representation invalid. Set at least 1 participant", {
            autoClose: 4000,
          });
          return false;
        }
      }
      const inApprovalSection = currentPath.includes("approve");
      await outlayAPI
        .receiptSave(company.id, {
          ...values,
          account: values.account.value,
          booking_date: formatDate(values.receipt_date),
          receipt_date: formatDate(values.receipt_date),
          amount_currency: values.amount_currency.value,
          vat_amount_currency: values.amount_currency.value,
          cost_center: values.cost_center && values.cost_center.value,
          project: values.project && values.project.value,
          draft: draftRef.current || false,
          investigate: investigateRef.current || false,
          representation: representation || {},
          next_only_owner: inApprovalSection,
        })
        .then(async (response) => {
          const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
          if (_newFiles.length) {
            await verAPI.document.uploadMany(company.id, response.data.id || outlay.id, _newFiles).catch((errors) => {
              handleActionErrors(errors, setErrors);
            });
          }
          toast.success(t("msg:saved"), { autoClose: 2000 });
          reloadMenuNumbers();
          if (onSave) {
            onSave(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
      draftRef.current = false;
      return true;
    },
  };

  const onRemove = () => {
    outlayAPI
      .remove(company.id, outlay.id, { next: true })
      .then((response) => {
        toast.success(t("msg:deleted"));
        onSave(response.data);
      })
      .catch((error) => {
        toast.error(t("msg:canNotExecuteAction"));
      });
  };

  function onAccountChange(account, setFieldValue) {
    setIsProjectRequired(account && account.project_option === "mandatory");
    setIsCenterRequired(account && account.cost_center_option === "mandatory");
    if (account && account.number === 6072) {
      setFieldValue("representation", {
        activity: repActivities[0],
        purpose: repPurposes[0],
        participants: [
          { name: userName || "", company: company.name },
          { name: "", company: "" },
        ],
      });
    }
  }

  const activeCenters = centerOptions.filter(filterActiveCC);
  const showCostCenters = activeCenters.length !== 0 || isCenterRequired;
  const showPaidBy = (!outlay.id && performerEmployee?.account) || (outlay.id && outlay.employee_account);
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, setFieldValue, setValues, handleSubmit, errors }) => {
        const activeProjects = filterActiveProjects(projectOptions, values.receipt_date);
        const showProjects = activeProjects.length !== 0;

        return (
          <Form id="receiptForm">
            <Card>
              <Card.Body>
                <Row>
                  <Col xl={6}>
                    {showPaidBy && (
                      <Row>
                        <Col>
                          <div className="form-group">
                            <label className="form-label">{t("paidWith")}</label>
                            <div>
                              <ButtonGroup toggle className="mb-2">
                                <ToggleButton
                                  type="checkbox"
                                  variant="switch"
                                  checked={values.paid_by === "P"}
                                  value="P"
                                  onChange={(e) => setFieldValue("paid_by", e.currentTarget.value)}
                                >
                                  {t("paidWithP")}
                                </ToggleButton>
                                <ToggleButton
                                  type="checkbox"
                                  variant="switch"
                                  checked={values.paid_by === "F"}
                                  value="F"
                                  onChange={(e) => setFieldValue("paid_by", e.currentTarget.value)}
                                >
                                  {t("paidWithF")}
                                </ToggleButton>
                              </ButtonGroup>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col xl={3}>
                        <FormGroup.BookingDatePicker
                          name="receipt_date"
                          label={t("common:dates.receiptDate")}
                          required
                        />
                      </Col>
                      <Col xl={9}>
                        <FormGroup.SimpleSelect
                          name="account"
                          label={t("expenseType")}
                          options={receiptAccounts}
                          required
                          onChange={(selected) => onAccountChange(selected, setFieldValue)}
                        />
                      </Col>
                    </Row>
                    <RepresentationForm t={t} />
                    <Row>
                      <Col lg={3}>
                        <FormGroup.MoneyInput name="amount" label={t("common:money.amount")} required />
                      </Col>
                      <Col lg={3}>
                        <FormGroup.MoneyInput
                          name="vat_amount"
                          label={t("common:money.vatAmount")}
                          disabled={company.vat_disabled}
                          required
                        />
                      </Col>
                      <Col>
                        <CurrencyPicker
                          name="amount_currency"
                          currentCode={values.amount_currency.value}
                          currentDate={values.receipt_date}
                          setFieldValue={setFieldValue}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Alert variant="warning">{t("returnReceiptInfo")}</Alert>
                      </Col>
                    </Row>
                    <Row>
                      {showProjects && (
                        <Col xl={4}>
                          <FormGroup.SimpleSelect
                            name="project"
                            label={t("common:project")}
                            options={activeProjects}
                            isClearable
                            required={isProjectRequired}
                          />
                        </Col>
                      )}
                      {showCostCenters && (
                        <Col xl={4}>
                          <FormGroup.SimpleSelect
                            name="cost_center"
                            label={t("common:costCenter")}
                            options={centerOptions}
                            required={isCenterRequired}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup.Input as="textarea" name="description" label={t("common:description")} rows={2} />
                      </Col>
                    </Row>
                    <div className="mt-3">
                      <hr />
                      <AllError errors={errors} />
                      <ButtonGroup>
                        {outlay.documents && !!outlay.documents.length && (
                          <RescanAzoraBtn
                            companyId={company.id}
                            itemId={values.id}
                            updateInvoice={(newValues) =>
                              setValues({
                                ...values,
                                ...newValues,
                              })
                            }
                          />
                        )}
                        {!outlay.sub_status && (
                          <>
                            <Button
                              variant="toggle-grey"
                              className="ml-2"
                              onClick={() => {
                                draftRef.current = true;
                                handleSubmit();
                              }}
                            >
                              <i className="fas fa-sticky-note" /> {t("common:actions.saveAsDraft")}
                            </Button>
                            {outlay.id && (
                              <RemoveButton
                                confirmMessage={t("confirm.remove", {
                                  employee: outlay.created_by,
                                })}
                                onClick={onRemove}
                                className="mr-2"
                              />
                            )}
                          </>
                        )}
                        {(!outlay.id || ["", "disputed"].includes(outlay.sub_status)) && (
                          <Button
                            variant="toggle-grey"
                            className="ml-2"
                            onClick={() => {
                              investigateRef.current = true;
                              handleSubmit();
                            }}
                          >
                            {t("common:actions.sendToConsult")}
                          </Button>
                        )}
                        <SubmitButton
                          isSubmitting={isSubmitting}
                          title={
                            outlay.sub_status !== "" && !isCompanyConsult ? "actions.save" : "actions.saveToApproval"
                          }
                        />
                      </ButtonGroup>
                    </div>
                  </Col>
                  <Col xl={6} className="verification-files">
                    {outlay.id && (
                      <SavedVerificationDocuments
                        verificationId={outlay.id}
                        companyId={company.id}
                        documents={outlay.documents}
                      />
                    )}
                    <NewDocuments
                      documents={newFiles}
                      fileTypes={allowedFileExtensionsFor.receipt}
                      multiple
                      onChange={({ file, index }) =>
                        documentAPI.onNewFileChange({
                          file,
                          index,
                          allFiles: newFiles,
                          setFile: setNewFiles,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ReceiptForm;
