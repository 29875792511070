import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import "./MandatoryOptionsBadge.scss";
import "./InitialsBadge.scss";

function ApprovalRequirementBadge({ minValue, stepApproverLen, mode = "preview", approvalOption, disabled = false }) {
  const { t } = useTranslation("company");

  const getText = () => {
    const isAllCondition =
      approvalOption === "all" ||
      minValue === stepApproverLen ||
      (minValue === 1 && stepApproverLen === 0 && approvalOption === "other");

    const isAnyCondition = approvalOption === "any" || minValue === 1;

    if (isAllCondition) {
      return t("approvalFlows.options_all");
    }
    if (isAnyCondition) {
      return t("approvalFlows.options_any");
    }

    return `${minValue}/${stepApproverLen}`;
  };

  const getTooltip = () => {
    if (disabled) {
      return "";
    }
    switch (approvalOption) {
      case "any":
        return t("approvalFlows.helpText_any");
      case "all":
        return t("approvalFlows.helpText_all");
      case "other":
        return t("approvalFlows.helpText_other");
      default:
        return "";
    }
  };

  if (mode === "form" && (minValue === null || approvalOption === null)) {
    return (
      <span className="mandatory-options-badge badge-error" title={t("approvalFlows.helpText_errorNoOptionSelected")}>
        ?
      </span>
    );
  }

  if (stepApproverLen === 1) {
    return null;
  }

  const badgeClass = cx("mandatory-options-badge", {
    "badge-any": approvalOption === "any",
    "badge-all": approvalOption === "all",
    "badge-other": approvalOption === "other",
    "badge-disabled": disabled,
  });

  return (
    <span className={badgeClass} title={getTooltip()}>
      {getText()}
    </span>
  );
}

export { ApprovalRequirementBadge };
