import React from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { SubmitButton } from "components/ui/buttons";
import { TableGroup } from "components/formik";
import { useCompanyState } from "hooks/useCompany";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import { DraggableModalDialog } from "../DraggableModalDialog";

function CCProjModal({ records, onSave, onCancel, bookingDate }) {
  const { t } = useTranslation("common");
  const {
    costCenters: { asOptions: centerOptions, byId: centerById },
    projects: { asOptions: projectOptions, byId: projectById },
  } = useCompanyState();
  const activeProjects = filterActiveProjects(projectOptions, bookingDate);
  const activeCenter = centerOptions.filter(filterActiveCC);
  const showProjects = activeProjects.length !== 0;
  const showCostCenter = activeCenter.length !== 0;

  const formikProps = {
    initialValues: {
      records: records.map((record) => ({
        ...record,
        product: record.product
          ? {
              ...record.product,
              project: record.product.project ? projectById[record.product.project] : null,
              cost_center: record.product.cost_center ? centerById[record.product.cost_center] : null,
            }
          : null,
      })),
    },
    onSubmit: async (values) => {
      onSave(
        values.records.map((record) => ({
          ...record,
          product: record.product
            ? {
                ...record.product,
                project: record.product.project ? record.product.project.value : null,
                cost_center: record.product.cost_center ? record.product.cost_center.value : null,
              }
            : null,
        }))
      );
    },
  };

  return (
    <Modal scrollable show onHide={onCancel} size="lg" dialogAs={DraggableModalDialog}>
      <Formik {...formikProps}>
        {({ values, setFieldValue, errors, isValid }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title className="m-0">{t("ccAndProj")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0" style={{ maxHeight: "60vh" }}>
              <Table bordered>
                <thead>
                  <tr>
                    <th>{t("common:description")}</th>
                    {showCostCenter && (
                      <th style={{ width: 250 }} className="text-right">
                        {t("common:costCenter")}
                      </th>
                    )}
                    {showProjects && (
                      <th style={{ width: 250 }} className="text-center">
                        {t("common:project")}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <FieldArray name="records">
                    <>
                      {values.records.map(
                        (record, index) =>
                          record.record_type === "product" && (
                            <React.Fragment key={record.key}>
                              <tr>
                                <td className="disabled">
                                  <span>{record.product.name}</span>
                                </td>
                                {showCostCenter && (
                                  <TableGroup.SimpleSelect
                                    options={centerOptions.filter(filterActiveCC)}
                                    isClearable
                                    name={`records[${index}].product.cost_center`}
                                  />
                                )}
                                {showProjects && (
                                  <TableGroup.SimpleSelect
                                    options={activeProjects}
                                    isClearable
                                    name={`records[${index}].product.project`}
                                  />
                                )}
                              </tr>
                            </React.Fragment>
                          )
                      )}
                      {!values.records.filter((item) => item.record_type === "product").length && (
                        <tr>
                          <td colSpan={8} className="p-2">
                            <span>{t("common:noResultsFound")}</span>
                          </td>
                        </tr>
                      )}
                    </>
                  </FieldArray>
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer className="space">
              <Button type="button" variant="secondary" onClick={onCancel}>
                {t("common:actions.cancel")}
              </Button>
              <SubmitButton disabled={!isValid} />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default CCProjModal;
