import qs from "qs";
import _ from "lodash";
import { differenceInCalendarDays } from "date-fns";
import { parseDate } from "utils/date";
import client from "./client";

export function userCompanies(filters, config) {
  return client.get(`/companies/`, {
    params: filters,
    ...config,
    paramsSerializer: (params) => {
      return qs.stringify(params, { indices: false });
    },
  });
}

export function staffCompanies(filters, config) {
  const today = new Date();
  const scanTaxStatus = (taxScanDate, isSoleType) => {
    if (isSoleType) {
      return "bg-grey";
    }
    if (taxScanDate) {
      const diffDays = differenceInCalendarDays(today, taxScanDate);
      if (diffDays === 0) return "success";
      if (diffDays === 1) return "warning";
      return "danger";
    }
    return "";
  };
  const scanBankStatus = (bankScanDate) => {
    if (bankScanDate) {
      const diffDays = differenceInCalendarDays(today, bankScanDate);
      if (diffDays === 0) return "success";
      if (diffDays === 1) return "warning";
      return "danger";
    }
    return "";
  };
  const lastReconStatus = (lastReconDate) => {
    if (lastReconDate) {
      let dueDate = new Date(lastReconDate.getFullYear(), lastReconDate.getMonth() + 1, 15);
      const lastDay = new Date(lastReconDate.getFullYear(), lastReconDate.getMonth() + 1, 0);
      const isLastDay = lastReconDate.getDate() === lastDay.getDate();
      if (isLastDay) dueDate = new Date(lastReconDate.getFullYear(), lastReconDate.getMonth() + 2, 15);
      const diffDays = differenceInCalendarDays(dueDate, today);
      if (diffDays > 5) return "success";
      if (diffDays > 0) return "warning";
      return "danger";
    }
    return "";
  };
  return client
    .get(`/companies/for-staff/`, {
      params: filters,
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    })
    .then((response) => {
      response.data.forEach((company) => {
        const { tax_scan_date, is_sole_type, bank_scan_date, last_reconciliation_date } = company;
        const taxScanDate = tax_scan_date ? parseDate(tax_scan_date) : null;
        const bankScanDate = bank_scan_date ? parseDate(bank_scan_date) : null;
        const lastReconDate = last_reconciliation_date ? parseDate(last_reconciliation_date) : null;

        company.scanTaxStatus = scanTaxStatus(taxScanDate, is_sole_type);
        company.scanBankStatus = scanBankStatus(bankScanDate);
        company.lastReconStatus = lastReconStatus(lastReconDate);
      });
      return response;
    });
}

export function officeSupportCompanies(filters, config) {
  return client.get(`/companies/for-support/`, {
    params: filters,
    ...config,
    paramsSerializer: (params) => {
      return qs.stringify(params, { indices: false });
    },
  });
}

export function importCsv(file, updateExisting) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("update_existing", updateExisting);
  return client.post("/companies/import-csv/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    timeout: 300000, // 5 min
  });
}

export function create(data, config) {
  return client.post(`/companies/create/`, data, {
    ...config,
  });
}

export function details(companyId, config) {
  return client.get(`/companies/company/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function selectCompany(companyId, forOfficeSupport, config) {
  return client.get(`/companies/select/${forOfficeSupport ? "support" : "accounting"}/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function lockAccountingDate(companyId, lockDate, config) {
  return client.patch(
    `/companies/config/lock-accounting/`,
    { lock_accounting_date: lockDate },
    {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }
  );
}

export function generalDetails(companyId, config) {
  return client.get(`/companies/config/general/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function invoicingDetails(companyId, config) {
  return client.get(`/companies/config/invoicing/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function partialUpdate(companyId, data, config) {
  return client.patch(`/companies/config/partial-update/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function logoDetail(companyId, config) {
  return client.get(`/companies/config/logo/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function logoUpdate(companyId, file, config) {
  const formData = new FormData();
  if (file) {
    formData.append("logo", file, file.name);
  }
  return client.post(`/companies/config/logo/update/`, formData, {
    headers: {
      "x-company": companyId,
      "Content-Type": "multipart/form-data",
      ...config,
    },
  });
}

export function advancedDetail(companyId, config) {
  return client.get(`/companies/config/advanced/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function advancedUpdate(companyId, data, config) {
  return client.put(`/companies/config/advanced/update/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function eInvoiceRegister(companyId, config) {
  return client.post(`/companies/register-einvoice/`, null, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function progenitorUpdate(companyId, data, config) {
  return client.post(
    `/companies/config/extra/progenitor-update/`,
    { company_id: companyId, ...data },
    {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }
  );
}

export function copySpecifications(companyId, config) {
  return client.post(`/companies/config/extra/copy-specifications/`, null, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function forceApprovalEmails(companyId, config) {
  return client.post(`/companies/config/extra/force-approval-emails/`, null, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export const employees = {
  list: (companyId, filters, config) => {
    return client.get(`/companies/config/employees/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  details: (companyId, employeeId, config) => {
    return client.get(`/companies/config/employees/${employeeId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  save: (companyId, data, config) => {
    if (data.id) {
      return client.put(`/companies/config/employees/${data.id}/update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    return client.post(`/companies/config/employees/create/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  delete: (companyId, employeeId, config) => {
    return client.delete(`/companies/config/employees/${employeeId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  sendWelcomeEmail: (companyId, employeeId, config) => {
    return client.post(`/companies/config/employees/${employeeId}/welcome-email-send/`, null, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  schedules: {
    list: (companyId, employeeId, filters, config) => {
      return client.get(`/companies/config/employees/${employeeId}/schedules/`, {
        params: filters,
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    save: (companyId, employeeId, data, config) => {
      if (data.id) {
        return client.put(`/companies/config/employees/${employeeId}/schedules/${data.id}/update/`, data, {
          headers: {
            "x-company": companyId,
          },
          ...config,
        });
      }
      return client.post(`/companies/config/employees/${employeeId}/schedules/create/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    delete: (companyId, employeeId, scheduleId, config) => {
      return client.delete(`/companies/config/employees/${employeeId}/schedules/${scheduleId}/delete/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
  },
  permissions: {
    details: (companyId, employeeId, config) => {
      return client.get(`/companies/config/employees/${employeeId}/permissions/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    update: (companyId, employeeId, permissions, config) => {
      return client.put(`/companies/config/employees/${employeeId}/permissions/update/`, permissions, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
  },
};
export const accounts = {
  list: (companyId, filters, config) => {
    return client.get(`/companies/config/accounts/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  update: (companyId, data, config) => {
    return client.put(`/companies/config/accounts/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};
export const financialYears = {
  list: (companyId, filters, config) => {
    return client.get(`/companies/config/financial-years/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  details: (companyId, financialYearId, config) => {
    return client.get(`/companies/config/financial-years/${financialYearId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  save: (companyId, data, config) => {
    if (data.id) {
      return client.put(`/companies/config/financial-years/${data.id}/update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    return client.post(`/companies/config/financial-years/create/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  delete: (companyId, financialYearId, config) => {
    return client.delete(`/companies/config/financial-years/${financialYearId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  exportSIE: (companyId, financialYearId, filters, config) => {
    return client.get(`/companies/config/financial-years/${financialYearId}/sie-file/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      responseType: "blob",
      ...config,
    });
  },
};

export const costCenters = {
  list: (companyId, filters, config) => {
    return client.get(`/companies/config/cost-centers/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  details: (companyId, costCenterId, config) => {
    return client.get(`/companies/config/cost-centers/${costCenterId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  save: (companyId, data, config) => {
    if (data.id) {
      return client.put(`/companies/config/cost-centers/${data.id}/update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    return client.post(`/companies/config/cost-centers/create/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  delete: (companyId, costCenterId, config) => {
    return client.delete(`/companies/config/cost-centers/${costCenterId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const projects = {
  list: (companyId, filters, config) => {
    return client.get(`/companies/config/projects/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  details: (companyId, projectId, config) => {
    return client.get(`/companies/config/projects/${projectId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  save: (companyId, data, config) => {
    if (data.id) {
      return client.put(`/companies/config/projects/${data.id}/update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    return client.post(`/companies/config/projects/create/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  delete: (companyId, projectId, config) => {
    return client.delete(`/companies/config/projects/${projectId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const auditors = {
  list: (companyId, filters, config) => {
    return client.get(`/companies/config/auditors/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  save: (companyId, data, config) => {
    return client.post(`/companies/config/auditors/create/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  delete: (companyId, auditorId, config) => {
    return client.delete(`/companies/config/auditors/${auditorId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const timeReports = {
  details: (companyId, config) => {
    return client.get(`/companies/config/time-reports/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  update: (companyId, data, config) => {
    return client.put(`/companies/config/time-reports/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const annualReports = {
  details: (companyId, config) => {
    return client.get(`/companies/config/annual-reports/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  update: (companyId, data, config) => {
    return client.put(`/companies/config/annual-reports/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  statusUpdate: (companyId, status, config) => {
    return client.patch(
      `/companies/config/annual-reports/status-update/`,
      { status },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
  googleSync: (companyId, config) => {
    return client.post(`/companies/config/annual-reports/google-sync/`, null, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  googleAnnualSync: (companyId, data, config) => {
    return client.post(`/companies/config/annual-reports/google-annual-sync/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const bankMatchingRules = {
  list: (companyId, filters, config) => {
    return client.get(`/companies/config/bank-matching-rules/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  details: (companyId, ruleId, config) => {
    return client.get(`/companies/config/bank-matching-rules/${ruleId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  save: (companyId, data, config) => {
    if (data.id) {
      return client.put(`/companies/config/bank-matching-rules/${data.id}/update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    return client.post(`/companies/config/bank-matching-rules/create/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  delete: (companyId, ruleId, config) => {
    return client.delete(`/companies/config/bank-matching-rules/${ruleId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  priorityUpdate: (companyId, data, config) => {
    return client.put(`/companies/config/bank-matching-rules/priority-update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const budgets = {
  details: (companyId, filters, config) => {
    return client
      .get(`/companies/config/budgets/`, {
        params: filters,
        headers: {
          "x-company": companyId,
        },
        ...config,
      })
      .then((response) => {
        response.data.current_budgets = _.reduce(
          response.data.current_budgets,
          (acc, values) => ({
            ...acc,
            [values.account]: values,
          }),
          {}
        );
        response.data.previous_budgets = _.reduce(
          response.data.previous_budgets,
          (acc, values) => ({
            ...acc,
            [values.account]: values,
          }),
          {}
        );
        return response;
      });
  },
  update: (companyId, data, config) => {
    return client.put(`/companies/config/budgets/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  updateMargin: (companyId, marginValue, config) => {
    return client.patch(
      `/companies/config/budgets/margin-update/`,
      { budget_margin: marginValue },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
};

export const exporter = {
  documentsList: (companyId, config) => {
    return client.get(`/companies/export/documents/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  financialYearList: (companyId, financialYearId, config) => {
    return client.get(`/companies/export/financial-year/`, {
      params: { financial_year: financialYearId },
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  create: (companyId, financialYearId, category, config) => {
    return client.post(
      `/companies/export/create/`,
      {
        category,
        financial_year: financialYearId,
      },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
  statusCheck: (companyId, exportId, config) => {
    return client.get(`/companies/export/${exportId}/status-check/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  upload: (companyId, file, financialYearId, category) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("financial_year", financialYearId);
    formData.append("category", category);
    return client.post(`/companies/export/upload/`, formData, {
      headers: {
        "x-company": companyId,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getDownloadLink: (companyId, exportId, category, config) => {
    let link = `/companies/export/${exportId}/download/`;
    if (category === "sal_document") {
      link = `/companies/export/${exportId}/download/salary/`;
    }
    return client.get(link, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  remove: (companyId, exportId, config) => {
    return client.delete(`/companies/export/${exportId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  notifyClient: (companyId, financialYearId, config) => {
    return client.post(
      `/companies/export/notify-client/`,
      { financial_year: financialYearId },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
};

export const sieFiles = {
  list: (companyId, filters, config) => {
    return client.get(`/companies/sie/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  upload: (companyId, file, data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });
    formData.append("file", file, file.name);
    return client.post(`/companies/sie/upload/`, formData, {
      headers: {
        "x-company": companyId,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  accountMatch: (companyId, data) => {
    return client.post(`/companies/sie/account-match/`, data, {
      timeout: 0,
      headers: {
        "x-company": companyId,
      },
    });
  },
  remove: (companyId, sieFileId) => {
    return client.delete(`/companies/sie/${sieFileId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
    });
  },
  summaryRevert: (companyId, sieFileId) => {
    return client.get(`/companies/sie/${sieFileId}/summary-revert/`, {
      headers: {
        "x-company": companyId,
      },
    });
  },
};

export const notifications = {
  list: (companyId, config) => {
    return client.get(`/companies/notifications/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const silverback = {
  list: (companyId, filters, config) => {
    return client.get(`/companies/silverback/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  save: (companyId, data, config) => {
    if (data.id) {
      return client.put(`/companies/silverback/${data.id}/update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    return client.post(`/companies/silverback/create/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  detail: (companyId, silverbackId, config) =>
    client.get(`/companies/silverback/${silverbackId}/detail/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  remove: (companyId, silverbackId, config) =>
    client.delete(`/companies/silverback/${silverbackId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
    }),
};

export const history = {
  list: (companyId, filters, config) => {
    return client.get(`/history/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const onboardings = {
  list: (filters, config) => {
    return client.get(`/companies/onboardings/`, {
      params: filters,
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false, skipNulls: true });
      },
    });
  },
  selectCompanyList: () => {
    return client.get(`/companies/onboardings/select-company/`);
  },
  create: (companyId) => {
    return client.post(`/companies/onboardings/${companyId}/create/`);
  },
  details: (companyId) => {
    return client.get(`/companies/onboardings/${companyId}/`);
  },
  steps: {
    // Step 1 Company information
    companyInformation: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/company-information/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    companyInformationFinish: (companyId, agencyId, data) => {
      return client.put(`/companies/onboardings/${companyId}/steps/company-information/finish/`, data, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    // Step 2 Consultant Settings
    consultantSettings: (companyId, agencyId, filters, config) => {
      return client.get(`/companies/onboardings/${companyId}/steps/consultant-settings/`, {
        headers: {
          "x-agency": agencyId,
        },
        params: filters,
        ...config,
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false, skipNulls: true });
        },
      });
    },
    consultantSettingsFinish: (companyId, agencyId, data) => {
      return client.put(`/companies/onboardings/${companyId}/steps/consultant-settings/finish/`, data, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    // Step 3 Previous System
    previousSystem: (companyId, agencyId, filters, config) => {
      return client.get(`/companies/onboardings/${companyId}/steps/previous-system/`, {
        headers: {
          "x-agency": agencyId,
        },
        params: filters,
        ...config,
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false, skipNulls: true });
        },
      });
    },
    previousSystemRollback: (companyId, agencyId) => {
      return client.delete(`/companies/onboardings/${companyId}/steps/previous-system/rollback/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    previousSystemOtherSystemPath: (companyId, agencyId) => {
      return client.post(
        `/companies/onboardings/${companyId}/steps/previous-system/other/`,
        {},
        {
          headers: {
            "x-agency": agencyId,
          },
        }
      );
    },
    previousSystemFortnoxPath: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/previous-system/fortnox/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    // Step 4 General information
    invoicingDetails: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/invoicing-details/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    invoicingDetailsFinish: (companyId, agencyId, data) => {
      return client.put(`/companies/onboardings/${companyId}/steps/invoicing-details/finish/`, data, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    invoicingDetailsSkip: (companyId, agencyId, data) => {
      return client.put(`/companies/onboardings/${companyId}/steps/invoicing-details/skip/`, data, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    // Step 5 Import accounting
    importAccounting: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/import-accounting/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    importAccountingSkip: (companyId, agencyId) => {
      return client.put(`/companies/onboardings/${companyId}/steps/import-accounting/skip/`, null, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    // Step 6 Company Settings
    companySettings: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/company-settings/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    companySettingsFinish: (companyId, agencyId, data) => {
      return client.put(`/companies/onboardings/${companyId}/steps/company-settings/finish/`, data, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    // Step 7 Owner & Employees
    ownerAndEmployees: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/owner-and-employees/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    ownerAndEmployeesFinish: (companyId, agencyId, data) => {
      return client.put(`/companies/onboardings/${companyId}/steps/owner-and-employees/finish/`, data, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    // Step 8 Finished
    finished: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/finished/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    finishedFinish: (companyId, agencyId, data) => {
      return client.put(`/companies/onboardings/${companyId}/steps/finished/finish/`, data, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    // Step F4 Import Fortnox
    importFortnox: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/import-fortnox/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    importFortnoxStart: (companyId, agencyId, data) => {
      return client.put(
        `/companies/onboardings/${companyId}/steps/import-fortnox/start/`,
        { ids: data },
        {
          headers: {
            "x-agency": agencyId,
          },
        }
      );
    },
    // Step F6 Save Accounting
    saveAccounting: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/save-accounting/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    saveAccountingFinish: (companyId, agencyId, data) => {
      return client.put(`/companies/onboardings/${companyId}/steps/save-accounting/finish/`, data, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    // Step F7 Save Ledger
    saveLedger: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/save-ledger/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    saveLedgerStart: (companyId, agencyId, data) => {
      return client.put(`/companies/onboardings/${companyId}/steps/save-ledger/start/`, data, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    // Step Fortnox data
    fortnoxData: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/fortnox-data/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    fortnoxDataCustomersUpdate: (companyId, agencyId, data) => {
      return client.put(`/companies/onboardings/${companyId}/steps/fortnox-data/customers/update/`, data, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    fortnoxDataCustomersRecheck: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/fortnox-data/customers/recheck/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    fortnoxDataProjectsUpdate: (companyId, agencyId, data) => {
      return client.put(`/companies/onboardings/${companyId}/steps/fortnox-data/projects/update/`, data, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    fortnoxDataProjectsRecheck: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/fortnox-data/projects/recheck/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    fortnoxDataCostCentersUpdate: (companyId, agencyId, data) => {
      return client.put(`/companies/onboardings/${companyId}/steps/fortnox-data/cost-centers/update/`, data, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    fortnoxDataCostCentersRecheck: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/fortnox-data/cost-centers/recheck/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    fortnoxDataProductsUpdate: (companyId, agencyId, data) => {
      return client.put(`/companies/onboardings/${companyId}/steps/fortnox-data/products/update/`, data, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    fortnoxDataProductsRecheck: (companyId, agencyId) => {
      return client.get(`/companies/onboardings/${companyId}/steps/fortnox-data/products/recheck/`, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
    fortnoxDataFinish: (companyId, agencyId) => {
      return client.put(`/companies/onboardings/${companyId}/steps/fortnox-data/finish/`, null, {
        headers: {
          "x-agency": agencyId,
        },
      });
    },
  },
};

export const approvalFlows = {
  list: (companyId, filters, config) => {
    return client
      .get(`/approval-flows/`, {
        params: filters,
        headers: {
          "x-company": companyId,
        },
        ...config,
      })
      .then((response) => {
        const transformedData = response.data.map((flow) => {
          const newSteps = flow.steps.map((step) => {
            let approvalOption = "other";
            const approversCount = step.approvers_ids?.length ?? 0;

            if (step.min_approvers_counter_condition === 1) {
              approvalOption = "any";
            }
            if (step.min_approvers_counter_condition === approversCount) {
              approvalOption = "all";
            }

            return {
              ...step,
              approval_option: approvalOption,
            };
          });

          return {
            ...flow,
            steps: newSteps,
          };
        });

        return transformedData;
      });
  },
  details: (companyId, approvalFlowId, config) => {
    return client.get(`/approval-flows/${approvalFlowId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  save: (companyId, data, config) => {
    if (data.id) {
      return client.put(`/approval-flows/${data.id}/update/`, data, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    return client.post(`/approval-flows/create/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  delete: (companyId, approvalFlowId, config) => {
    return client.delete(`/approval-flows/${approvalFlowId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  getDefaultFlow: (companyId, approvalFlowId, config) => {
    return client.get(`/approval-flows/default-for-supplier-invoices/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  changeDefault: (companyId, approvalFlowId, config) => {
    return client.patch(
      `/approval-flows/${approvalFlowId}/change-default/`,
      {},
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
};
