import React, { useCallback, useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import * as ciAPI from "api2/customer-invoices";
import useTable from "components/tables/btable/useTable";
import { withInitialAsync } from "hooks/useAsync";
import CustomerForm from "./CustomerForm";

function CustomerAddWidget({ data: { customer_number }, companyId }) {
  const { t } = useTranslation("ci");
  const nextCustomerNumber = useRef(customer_number);
  const {
    dataActions: { reload },
  } = useTable();

  const onSuccess = useCallback(() => {
    return ciAPI.customers.prelimCustomerNumber(companyId).then((response) => {
      nextCustomerNumber.current = `${response.data.customer_number}`;
      reload();
    });
  }, [companyId, reload]);

  React.useEffect(() => {
    // After change time tracker active task in calendar, update task on navbar as well
    document.body.addEventListener("customer/removed", onSuccess);
    return () => document.body.removeEventListener("customer/removed", onSuccess);
  }, [onSuccess]);

  const customer = {
    name: "",
    identification: "",
    customer_type: "private",
    customer_number: `${nextCustomerNumber.current || 1}`,
    is_active: true,
    vat_number: "",
    address: "",
    address2: "",
    zip_code: "",
    phone: "",
    emails: [],
    your_reference: "",
    our_reference: "",
    default_delivery_method: "email",
    city: "",
    country: "SE",
    language: "sv",
  };

  return (
    <Row>
      <Col>
        <Card className="card-border" id="create-customer-section">
          <Card.Header className="border-success">
            <Card.Title>{t("actions.addCustomer")}</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <CustomerForm companyId={companyId} customer={customer} successCallback={onSuccess} resetOnSuccess />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

const EnhancedCustomerAddWidget = withInitialAsync(
  CustomerAddWidget,
  ({ companyId }) =>
    React.useCallback((cancelToken) => ciAPI.customers.prelimCustomerNumber(companyId, { cancelToken }), [companyId]),
  {},
  false
);

export default EnhancedCustomerAddWidget;
