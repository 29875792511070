import client from "api2/client";
import qs from "qs";

export function agencyConsults(filters, config) {
  return client
    .get("/selects/agency-consults/", {
      params: filters,
      paramsSerializer: (params2) => {
        return qs.stringify(params2, { indices: false });
      },
      ...config,
    })
    .then((response) => {
      return response.data.map((item) => ({
        value: item.id,
        label: item.name,
        id: item.id,
        name: item.name,
      }));
    });
}

export function agencyUsers(filters, config) {
  return client
    .get("/selects/agency-users/", {
      params: filters,
      paramsSerializer: (params2) => {
        return qs.stringify(params2, { indices: false });
      },
      ...config,
    })
    .then((response) => {
      return response.data.map((item) => ({
        value: item.id,
        label: item.name,
        id: item.id,
        name: item.name,
        email: item.email,
      }));
    });
}

export function companyConsults(filters, config) {
  return client
    .get("/selects/company-consults/", {
      params: filters,
      paramsSerializer: (params2) => {
        return qs.stringify(params2, { indices: false });
      },
      ...config,
    })
    .then((response) => {
      return response.data.map((item) => ({
        value: item.id,
        label: item.name,
        id: item.id,
        name: item.name,
      }));
    });
}

export function companyEmployees(companyId, filters, valueField = "user_id", config) {
  return client
    .get("/selects/company-employees/", {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) => {
      return response.data.map((item) => ({
        value: item[valueField],
        label: item.user_name,
        id: item.id,
        user_id: item.user_id,
        user_name: item.user_name,
        user_first_name: item.user_first_name,
        user_last_name: item.user_last_name,
        account: item.account,
        permission_codes: item.permission_codes,
      }));
    });
}

export function companyFinancialYears(companyId, filters, config) {
  return client
    .get("/companies/config/financial-years/", {
      params: { page_size: 25, ...filters },
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) =>
      response.data.map((item) => ({
        value: item.id,
        label: `${item.date_start} - ${item.date_end}`,
        id: item.id,
        date_start: item.date_start,
        date_end: item.date_end,
      }))
    );
}

export function myCompanies(companyId, filters, config) {
  return client
    .get("/selects/my-companies/", {
      params: filters,
      paramsSerializer: (param) => {
        return qs.stringify(param, { indices: false });
      },
      ...config,
    })
    .then((response) =>
      response.data.map((item) => ({
        value: item.id,
        label: item.name,
        id: item.id,
        org_no: item.org_no,
      }))
    );
}

export function accounts(filters, config) {
  return client
    .get("/selects/accounts/", {
      params: filters,
      ...config,
    })
    .then((response) =>
      response.data.map((item) => ({
        value: item.number,
        label: `${item.number} - ${item.name}`,
        isDisabled: !item.is_active,
      }))
    );
}

export function companyAccounts(companyId, filters, config) {
  return client
    .get("/selects/company-accounts/", {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) =>
      response.data.map((item) => ({
        value: item.number,
        label: `${item.number} - ${item.name}`,
        isDisabled: !item.is_active,
      }))
    );
}

export function companySuppliers(companyId, filters, config) {
  return client
    .get("/selects/company-suppliers/", {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) => {
      return [
        {
          label: "local",
          options: response.data.locals.map((item) => ({
            ...item,
            group: "local",
            value: item.id,
            label: `${item.supplier_name} | ${item.payment_method_str}`,
          })),
        },
        {
          label: "global",
          options: response.data.globals.map((item) => ({
            ...item,
            group: "global",
            value: item.id,
            label: `${item.supplier_name} | ${item.payment_method_str}`,
          })),
        },
      ];
    });
}

export function suppliersToConnect(companyId, filters, config) {
  return client
    .get("/selects/suppliers-to-connect/", {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) => {
      response.data = response.data.map((item) => ({
        ...item,
        value: item.id,
        label: `${item.supplier_name} | ${item.payment_method_str}`,
        id: item.id,
      }));
      return response;
    });
}

export function agencies(filters, config) {
  return client
    .get("/selects/agencies/", {
      params: filters,
      ...config,
    })
    .then((response) =>
      response.data.map((item) => ({
        value: item.id,
        label: item.name,
        id: item.id,
        name: item.name,
      }))
    );
}

export function offices(filters, config) {
  return client
    .get("/selects/offices/", {
      params: filters,
      paramsSerializer: (param) => {
        return qs.stringify(param, { indices: false });
      },
      ...config,
    })
    .then((response) =>
      response.data.map((item) => ({
        value: item.id,
        label: item.name,
        id: item.id,
        name: item.name,
      }))
    );
}

export function historyUsers(companyId, filters, config) {
  return client
    .get("/selects/history-users/", {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) =>
      response.data.map((item) => ({
        value: item.id,
        label: item.name,
        id: item.id,
        name: item.name,
      }))
    );
}

export function companyScheduleTemplates(companyId, filters, config) {
  return client
    .get("/selects/company-schedule-templates/", {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) =>
      response.data.map((tpl) => ({
        value: tpl.id,
        label: `${tpl.template_name} (${tpl.monday}h, ${tpl.tuesday}h, ${tpl.wednesday}h, ${tpl.thursday}h, ${tpl.friday}h, ${tpl.saturday}h, ${tpl.sunday}h)`,
        id: tpl.id,
      }))
    );
}
