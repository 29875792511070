import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomerForm from "components/forms/CustomerForm";
import * as ciAPI from "api2/customer-invoices";
import { withInitialAsync } from "hooks/useAsync";
import { DraggableModalDialog } from "../DraggableModalDialog";

function CustomerAddModal({ data: { customer_number }, companyId, handleClose, onSuccess }) {
  const { t } = useTranslation("ci");

  const customer = {
    name: "",
    identification: "",
    customer_type: "private",
    customer_number: `${customer_number}`,
    is_active: true,
    vat_number: "",
    address: "",
    address2: "",
    zip_code: "",
    phone: "",
    emails: [],
    your_reference: "",
    our_reference: "",
    default_delivery_method: "email",
    city: "",
    country: "SE",
    language: "sv",
  };

  return (
    <Modal show onHide={handleClose} size="xl" scrollable dialogAs={DraggableModalDialog}>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.addCustomer")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomerForm companyId={companyId} customer={customer} successCallback={onSuccess} />
      </Modal.Body>
    </Modal>
  );
}

const EnhancedCustomerAddModal = withInitialAsync(
  CustomerAddModal,
  ({ companyId }) =>
    React.useCallback((cancelToken) => ciAPI.customers.prelimCustomerNumber(companyId, { cancelToken }), [companyId]),
  {},
  false
);

export default EnhancedCustomerAddModal;
