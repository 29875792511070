import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import * as options from "api/options";
import { toast } from "react-toastify";

import { useCompanyState } from "hooks/useCompany";
import { ConfirmButton, SubmitButton } from "components/ui/buttons";
import { AllError, FormGroup } from "components/formik";
import * as ciAPI from "api2/customer-invoices";
import { codesForConsultManage, codesForCustomerInvoices } from "components/perms/PermCodes";
import { HasPermCode, PermCodeRequired } from "components/perms";
import { filterActiveCC } from "utils/others";
import { filterProvidedAccounts, getDefaultAccountForRevenue, getListWithAccountsForRevenue } from "./utils";
import { confirmExecute } from "../../modals/ConfirmModal";

function ProductForm({ companyId, product, successCallback, resetOnSuccess = false }) {
  const { t } = useTranslation("ci");
  const vatOptions = options.vatOptions.asList();
  const productTypes = options.productTypes.asList();
  const [showMore, setShowMore] = useState(false);

  const {
    accounts: { asOptions: accountOptions, byId: accountOptionsById },
  } = useCompanyState();
  const [revenuesAccountsOptions, setRevenuesAccountsOptions] = useState(
    accountOptions.filter(
      filterProvidedAccounts(getListWithAccountsForRevenue(product.vat_percent, product.product_type))
    )
  );
  const canEditAccount = HasPermCode(codesForConsultManage.enabled);

  const {
    costCenters: { byId: centerById, asOptions: centerOptions },
  } = useCompanyState();
  const initial_center = centerById[product.cost_center];
  const listWithAccountsForRevenue = getListWithAccountsForRevenue(product.vat_percent, product.product_type);
  const initialAccount =
    product.account && listWithAccountsForRevenue.includes(product.account)
      ? product.account
      : getDefaultAccountForRevenue(product.vat_percent, product.product_type);
  const formikProps = {
    initialValues: {
      ...product,
      cost_center: initial_center && initial_center.is_active ? initial_center : null,
      product_number: product.product_number || "",
      product_type: options.productTypes.getOption(product.product_type) || productTypes[0],
      vat_percent: options.vatOptions.getOption(parseInt(product.vat_percent, 10)) || vatOptions[0],
      account: accountOptionsById[initialAccount],
    },
    validationSchema: yup.object().shape({
      product_number: yup.string().nullable(),
      name: yup.string().required(),
      product_type: yup.object().required(),
      unit: yup.string().required(),
      vat_percent: yup.object().required(),
      unit_price: yup.number().nullable().required(),
      account: yup.object().nullable().notRequired(),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      if (
        (product.id &&
          !(
            product.account === 3001 &&
            values.account.value === getDefaultAccountForRevenue(values.vat_percent.value, values.product_type.value)
          ) &&
          values.account.value !== product.account) ||
        (product.id && values.vat_percent.value !== product.vat_percent)
      ) {
        const answer = await confirmExecute(t("common:confirm.newVatSettingsOnProductUpdate"));
        if (!answer) {
          return;
        }
      }
      await ciAPI.products
        .save(companyId, {
          ...product,
          ...values,
          product_type: values.product_type.value,
          vat_percent: parseInt(values.vat_percent.value, 10),
          b_unit_price: values.b_unit_price || null,
          c_unit_price: values.c_unit_price || null,
          d_unit_price: values.d_unit_price || null,
          account: values.account?.value,
          cost_center: values.cost_center?.value,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (successCallback) {
            successCallback({
              ...product,
              ...values,
              cost_center: values.cost_center ? values.cost_center.id : null,
              product_type: values.product_type.value,
              account: values.account?.value,
              vat_percent: values.vat_percent.value,
            });
            if (resetOnSuccess) {
              resetForm();
            }
          } else {
            resetForm();
            if (successCallback) {
              successCallback(response.data);
            }
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  async function activeToggle(newProduct) {
    if (newProduct.is_active) {
      await ciAPI.products.toggleActivate(companyId, newProduct.id).then((response) => {
        toast.success(t("msg:deactivated"), { autoClose: 2000 });
        if (successCallback) {
          successCallback({ ...newProduct, is_active: false });
        }
      });
    } else {
      await ciAPI.products.toggleActivate(companyId, newProduct.id).then((response) => {
        toast.success(t("msg:activated"), { autoClose: 2000 });
        if (successCallback) {
          successCallback({ ...newProduct, is_active: true });
        }
      });
    }
  }

  const activeCenters = centerOptions.filter(filterActiveCC);
  const showCostCenters = activeCenters.length !== 0;

  function updateRevenueAccountOptions(vat, productType, setFieldValue) {
    setRevenuesAccountsOptions(
      accountOptions.filter(filterProvidedAccounts(getListWithAccountsForRevenue(vat, productType)))
    );
    setFieldValue("account", accountOptionsById[getDefaultAccountForRevenue(vat, productType)]);
  }

  function onVatChange(selected, setFieldValue, values) {
    setFieldValue("vat_percent", selected);
    updateRevenueAccountOptions(selected.value, values.product_type.value, setFieldValue);
  }

  function onProductTypeChange(selected, setFieldValue, values) {
    setFieldValue("product_type", selected);
    updateRevenueAccountOptions(values.vat_percent.value, selected.value, setFieldValue);
  }

  return (
    <Formik {...formikProps}>
      {({ values, setFieldValue, isSubmitting, errors }) => {
        return (
          <Form noValidate>
            <Row>
              <Col sm={6} xl={3}>
                <FormGroup.SimpleSelect
                  name="product_type"
                  label={t("common:type")}
                  options={productTypes}
                  onChange={(selected) => onProductTypeChange(selected, setFieldValue, values)}
                  required
                />
              </Col>
              <Col sm={6} xl={3}>
                <FormGroup.Input label={t("articleNumber")} name="product_number" />
              </Col>
              <Col sm={6} xl={6}>
                <FormGroup.Input label={t("common:description")} name="name" required />
              </Col>
            </Row>
            <Row>
              <Col sm={4} xl={3}>
                <FormGroup.Input label={`${t("unit")}`} name="unit" helpText={t("helpUnit")} required />
              </Col>
              <Col sm={4} xl={3}>
                <FormGroup.MoneyInput
                  label={`${t("common:money.unitPrice")} (SEK)`}
                  name="unit_price"
                  helpText={t("helpPrice")}
                  required
                />
              </Col>
              <Col sm={4} xl={3}>
                <FormGroup.SimpleSelect
                  name="vat_percent"
                  label={t("common:money.vat")}
                  options={vatOptions}
                  onChange={(selected) => onVatChange(selected, setFieldValue, values)}
                  required
                />
              </Col>
              {showCostCenters && (
                <Col sm={4} xl={3}>
                  <FormGroup.SimpleSelect
                    name="cost_center"
                    label={t("common:costCenter")}
                    options={activeCenters}
                    required={false}
                    isClearable
                  />
                </Col>
              )}
            </Row>
            <button
              type="button"
              className="btn btn-link p-0 mb-3 mt-2"
              onClick={() => setShowMore((prevValue) => !prevValue)}
            >
              <span style={{ color: "#ADADAD" }}>
                {showMore ? t("common:actions.showLessSettings") : t("common:actions.showMoreSettings")}
              </span>{" "}
              <i className={showMore ? "fas fa-chevron-up" : "fas fa-chevron-down"} />
            </button>
            {showMore && (
              <Row>
                <Col>
                  <fieldset>
                    <legend className="font-20">{t("priceGroups")}</legend>
                    <Row>
                      <Col sm={4} xl={3}>
                        <FormGroup.MoneyInput label={`B - ${t("common:money.unitPrice")} (SEK)`} name="b_unit_price" />
                      </Col>
                      <Col sm={4} xl={3}>
                        <FormGroup.MoneyInput label={`C- ${t("common:money.unitPrice")} (SEK)`} name="c_unit_price" />
                      </Col>
                      <Col sm={4} xl={3}>
                        <FormGroup.MoneyInput label={`D - ${t("common:money.unitPrice")} (SEK)`} name="d_unit_price" />
                      </Col>
                      <Col sm={4} xl={3}>
                        <FormGroup.SimpleSelect
                          options={revenuesAccountsOptions}
                          name="account"
                          label={t("common:account")}
                          isDisabled={!canEditAccount}
                          filterOptionStartsWith
                        />
                      </Col>
                    </Row>
                  </fieldset>
                </Col>
              </Row>
            )}
            <AllError errors={errors} />
            <hr />
            <PermCodeRequired code={codesForCustomerInvoices.manage}>
              <SubmitButton isSubmitting={isSubmitting} />
              {product.id &&
                (product.is_active ? (
                  <ConfirmButton
                    variant="orange"
                    label={t("common:actions.deactivate")}
                    confirmMessage={t("confirm.deactivateProduct", {
                      productName: product.name,
                    })}
                    className="float-right"
                    onClick={() => activeToggle(product)}
                  />
                ) : (
                  <ConfirmButton
                    variant="orange"
                    label={t("common:actions.activate")}
                    confirmMessage={t("confirm.activateProduct", {
                      productName: product.name,
                    })}
                    className="float-right"
                    onClick={() => activeToggle(product)}
                  />
                ))}
            </PermCodeRequired>
          </Form>
        );
      }}
    </Formik>
  );
}

export default ProductForm;
