import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import useInitialAsync from "hooks/useInitialAsync";
import * as ciAPI from "api2/customer-invoices";
import { RocketLoader } from "components/ui/loaders";
import ContractInvoiceForm from "components/forms/ContractInvoiceForm";

function ContractInvoiceDuplicatePage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const { id: contractId } = useParams();
  const { loading, item } = useInitialAsync(
    ({ cancelToken }) => {
      return ciAPI.contractInvoices.details(company.id, contractId, { cancelToken });
    },
    {},
    [company.id, contractId]
  );

  const onCreate = (invoice) => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }

  return (
    <ContractInvoiceForm
      invoice={{
        ...item,
        id: null,
      }}
      company={company}
      onSave={onCreate}
    />
  );
}

export default ContractInvoiceDuplicatePage;
