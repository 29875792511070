import { formatDiscount, toBillectaAmount } from "utils/calc/ci";
import { roundMoney } from "utils/money";

export function formatData(data) {
  return {
    ...data,
    payment_terms: data.payment_terms.value,
    records: data.records.map((record, idx) => {
      if (record.record_type === "product") {
        return {
          old: record.product.old || {}, // TODO: TO remove
          record_type: "product",
          message: null,
          rot_rut: null,
          discount: formatDiscount(record, "SEK"),
          product: {
            ...record.product,
            unit_price: roundMoney(toBillectaAmount(record.product.unit_price, "SEK")),
            quantity: roundMoney(record.product.quantity),
            currency_code: "SEK",
            vat_percent:
              record.product.vat_percent && !data.reversed_vat ? parseInt(record.product.vat_percent.value, 10) : 0,
          },
          sequence_no: idx + 1,
        };
      }
      return {
        old: record.old || {}, // TODO: TO remove
        record_type: "message",
        message: {
          ...record.message,
        },
        product: null,
        rot_rut: null,
        discount: null,
        sequence_no: idx + 1,
      };
    }),
  };
}
