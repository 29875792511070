import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import * as yup from "yup";

import * as options from "api/options";
import * as companyAPI from "api2/companies";
import { AllError, FormGroup } from "components/formik";
import { PreviewInput } from "components/formik/PreviewInput";
import { SubmitButton } from "components/ui/buttons";

function InvoicingForm({ company }) {
  const { t } = useTranslation("company");
  const paymentTerms = options.paymentTermsOptionsWithoutDefault();
  const reminderFeeOptions = options.invoiceReminderFees.asList();

  const formikProps = {
    initialValues: {
      ...company,
      default_invoice_payment_terms: options.paymentTerms.getOption(company.default_invoice_payment_terms),
      billecta_reminder_fee: options.invoiceReminderFees.getOption(company.billecta_reminder_fee),
    },
    enableReinitialize: true,
    validationSchema: yup.object(),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await companyAPI
        .partialUpdate(company.id, {
          address: values.address,
          zip_code: values.zip_code,
          city: values.city,
          phone: values.phone,
          contact_first_name: values.contact_first_name,
          contact_last_name: values.contact_last_name,
          contact_email: values.contact_email,
          contact_phone: values.contact_phone,
          default_invoice_payment_terms: values.default_invoice_payment_terms.value,
          billecta_reminder_fee: values.billecta_reminder_fee.value,
          invoice_interest_fee: values.invoice_interest_fee,
          next_invoice_no: values.next_invoice_no,
        })
        .then((response) => {
          resetForm({ values });
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, touched, errors }) => {
        return (
          <Form>
            <Card className="pt-2">
              <Card.Body>
                <Card.Title className="mt-2">{t("billingAddress")}</Card.Title>
                <Row>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:contact.address")} name="address" />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:contact.zipCode")} name="zip_code" />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:contact.city")} name="city" />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:contact.phone")} name="phone" />
                  </Col>
                </Row>
                <Card.Title className="mt-2">{t("common:contact.contactPerson")}</Card.Title>
                <Row>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:contact.firstName")} name="contact_first_name" />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:contact.lastName")} name="contact_last_name" />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:contact.emailLong")} name="contact_email" type="email" />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input label={t("common:contact.phone")} name="contact_phone" />
                  </Col>
                </Row>
                {company.customer_invoice_enabled && (
                  <>
                    <Card.Title className="mt-2">{t("invoiceSettings")}</Card.Title>
                    <Row>
                      <Col sm={6} xl={3}>
                        <FormGroup.Input type="number" step={1} name="next_invoice_no" label={t("nextInvoiceNumber")} />
                      </Col>
                      <Col sm={6} xl={3}>
                        <FormGroup.SimpleSelect
                          name="default_invoice_payment_terms"
                          label={t("defaultInvoicePaymentTerms")}
                          options={paymentTerms}
                        />
                      </Col>
                      <Col sm={6} xl={3}>
                        <FormGroup.SimpleSelect
                          name="billecta_reminder_fee"
                          label={t("invoiceReminderFee")}
                          options={reminderFeeOptions}
                        />
                      </Col>
                      <Col sm={6} xl={3}>
                        <FormGroup.MoneyInput name="invoice_interest_fee" label={t("interestFee")} />
                      </Col>
                    </Row>
                  </>
                )}
                <Card.Title className="mt-2">{t("bankDetails")}</Card.Title>
                <Row>
                  <Col sm={6} xl={2}>
                    <PreviewInput value={t(`${company.payment_method}`)} label={t("common:paymentMethod")} />
                  </Col>
                  {values.payment_method === "bankgiro" && (
                    <Col sm={6} xl={2}>
                      <PreviewInput value={values.bankgiro_no} label={`BankGiro ${t("common:no")}`} />
                    </Col>
                  )}
                  <>
                    <Col sm={6} xl={3}>
                      <PreviewInput value={values.bank_name} label={t("bankName")} />
                    </Col>
                    <Col sm={6} xl={2}>
                      <PreviewInput value={values.bic} label="BIC" />
                    </Col>
                    <Col sm={6} xl={3}>
                      <PreviewInput value={values.iban} label="IBAN" />
                    </Col>
                  </>
                </Row>
                <AllError errors={errors} />
              </Card.Body>
              <Card.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default InvoicingForm;
