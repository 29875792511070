import React, { useState } from "react";
import { ButtonGroup, Card, Col, Row } from "react-bootstrap";
import _ from "lodash";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { SubmitButton } from "components/ui/buttons";
import { NewDocuments } from "components/ui/documents/verification-documents";
import { SavedDocuments } from "components/ui/documents-refactored/documents";
import * as documentAPI from "api/document";
import { handleFormErrors } from "api/errors";
import { AllError, FormGroup } from "components/formik";
import * as documentsAPI from "api2/documents";
import { allowedFileExtensionsFor } from "utils/file";
import FileArchiveSwitcher from "./FileArchiveSwitcher";

function FileArchiveForm({ companyId, archive, onSave }) {
  const { t } = useTranslation("common");
  const [newFiles, setNewFiles] = useState(!archive.id && [{ key: _.uniqueId("nd.") }]);

  const formikProps = {
    initialValues: {
      ...archive,
    },
    validationSchema: yup.object().shape({
      title: yup.string().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      let _newFiles = [];
      if (!archive.id) {
        _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
        if (!_newFiles.length) {
          toast.error(t("msg:fixErrors"));
          setErrors({ __all__: t("file.noFile") });
          return false;
        }
      }
      return documentsAPI.archiveDocuments
        .save(companyId, _newFiles ? _newFiles[0] : null, {
          ...values,
          title: values.title,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (onSave) {
            onSave(response.data);
          }
        })
        .catch((errors) => {
          handleFormErrors(errors, setErrors);
        });
    },
  };

  function onFileSelected(files, index, setFieldValue, currentTitle) {
    if (!files) {
      setNewFiles([{ key: _.uniqueId("nd.") }]);
      return;
    }
    if (files && files.length > 1) {
      toast.error("Only 1 file allowed");
      return;
    }
    if (!currentTitle && files && files[0] && files[0].name) {
      setFieldValue("title", files[0].name);
    }
    documentAPI.onNewFileChange({
      file: files,
      index,
      allFiles: newFiles,
      setFile: setNewFiles,
      max: 1,
    });
  }

  return (
    <Formik {...formikProps}>
      {({ values: { title }, isSubmitting, errors, setFieldValue }) => {
        return (
          <Form id="archiveForm">
            <Card>
              <Card.Body>
                <Row>
                  <Col xl={6}>
                    <Row>
                      <Col>
                        <FormGroup.Input label={t("common:name")} name="title" required />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup.Input as="textarea" label={t("common:description")} name="description" rows={3} />
                      </Col>
                    </Row>
                    <AllError errors={errors} />
                    <Row>
                      <Col>
                        <hr />
                        <ButtonGroup>
                          <SubmitButton isSubmitting={isSubmitting} />
                        </ButtonGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={6} className="verification-files">
                    {archive.id && <FileArchiveSwitcher companyId={companyId} archiveId={archive.id} />}
                    {archive.id && (
                      <SavedDocuments
                        companyId={companyId}
                        documents={[archive.id]}
                        partUrl="/documents/archive"
                        removable={false}
                      />
                    )}
                    <NewDocuments
                      documents={newFiles}
                      fileTypes={allowedFileExtensionsFor.file_archive}
                      onChange={({ file, index }) => onFileSelected(file, index, setFieldValue, title)}
                      multiple={false}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default FileArchiveForm;
