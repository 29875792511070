import { formatDuration, isBefore, parseDate } from "utils/date";
import qs from "qs";
import client from "./client";

const PREFIX = "/table";

function list(filters, config, endpoint) {
  return client.get(`${PREFIX}${endpoint}`, { params: filters, ...config });
}

export function manageYearEndClosure(filters, config) {
  return list(filters, config, "/manage/year-end-closure/");
}

export function manageDayToDay(filters, config) {
  return list(filters, config, "/manage/day-to-day/");
}

export function manageProfitability(filters, config) {
  return list(filters, config, "/manage/profitability/");
}

export function manageOngoing(filters, config) {
  return list(filters, config, "/manage/ongoing-overview/");
}

export function trackerTasks(filters, config) {
  return list(filters, config, `/tracker-tasks/`).then((response) => {
    response.data.outdated_tasks.forEach((task) => {
      task.playing = task.id === response.data.running_task_id;
      task.durationStr = formatDuration(task.duration);
      task.permanent = !task.company_id;
      task.overdue = true;
    });
    Object.values(response.data.tasks_by_day).forEach((tasks) => {
      tasks.forEach((task) => {
        task.overdue = false;
        task.playing = task.id === response.data.running_task_id;
        task.durationStr = formatDuration(task.duration);
        task.permanent = false;
      });
    });
    return response;
  });
}

export function trackerTasksByCompany(filters, config) {
  return list(filters, config, `/tracker-tasks/by_company/`).then((response) => {
    Object.values(response.data.companies_tasks).forEach((company) => {
      company.tasks.forEach((task) => {
        task.overdue = isBefore(parseDate(task.deadline_date), new Date());
        task.durationStr = formatDuration(task.duration);
        if (task.main_company_task) {
          task.overdue = false;
          task.permanent = true;
        }
        if (task.id === response.data.running_task_id) {
          task.playing = true;
        }
      });
    });
    return response;
  });
}

export function trackerTimeLogs(filters, config) {
  return list(
    filters,
    {
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    },
    `/tracker-logs/`
  );
}

export function trackerConsultReport(filters, config) {
  return list(
    filters,
    {
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
    },
    `/tracker-company-summary/`
  ).then((response) => {
    response.data.forEach((item) => ({
      ...item,
      percentage: item.max_time ? `${item.time_spent / item.max_time}%` : "0%",
    }));
    return response;
  });
}

export function companyRepeatedTasks(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/repeated-tasks/`);
}

export function skvCustomerEventsVAT(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/skv-customer-events/vat_report/`);
}

export function skvCustomerEventsSocSec(companyId, filters, config) {
  return list(filters, config, `/companies/${companyId}/skv-customer-events/soc_sec/`);
}
