import React from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { addMonths } from "date-fns";

import * as ciAPI from "api2/customer-invoices";
import { formatDate } from "utils/date";
import { FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import { DraggableModalDialog } from "../DraggableModalDialog";

function CreateDraftPeriodModal({ companyId, onCancel }) {
  const { t } = useTranslation("ci");
  const formikProps = {
    initialValues: {
      date_from: new Date(),
      date_to: addMonths(new Date(), 1),
    },
    validationSchema: yup.object().shape({
      date_from: yup.date().nullable().required(),
      date_to: yup.date().nullable().required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      await ciAPI.contractInvoices
        .createDraftInvoices(companyId, {
          date_from: formatDate(values.date_from),
          date_to: formatDate(values.date_to),
        })
        .then((response) => {
          onCancel();
          toast.success(t("msg:generated"), { autoClose: 2000 });
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          }
        });
    },
  };

  return (
    <Modal animation={false} show onHide={onCancel} size="lg" dialogAs={DraggableModalDialog} scrollable>
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.contractCreateAllDrafts")}</Modal.Title>
      </Modal.Header>
      <Formik {...formikProps}>
        {({ errors, isSubmitting }) => {
          return (
            <Form>
              <Modal.Body>
                <Row>
                  <Col>
                    <FormGroup.DatePicker
                      name="date_from"
                      label={t("common:starts")}
                      popperClassName="popper-in-modal"
                      required
                    />
                  </Col>
                  <Col>
                    <FormGroup.DatePicker
                      name="date_to"
                      label={t("common:ends")}
                      popperClassName="popper-in-modal"
                      required
                    />
                  </Col>
                </Row>
                <Alert variant="info">{t("contractDraftModalInfo")}</Alert>
              </Modal.Body>
              <Modal.Footer className="space">
                <Button type="button" variant="secondary" onClick={onCancel}>
                  {t("common:actions.cancel")}
                </Button>
                <SubmitButton isSubmitting={isSubmitting} />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default CreateDraftPeriodModal;
