import React from "react";
import cx from "classnames";

import { formatMoney } from "utils/money";
import AddonTable from "./AddonTable";

function CashIn({
  options,
  cashIn,
  cashInAddons,
  toggle,
  paymentsFromCustomers,
  simulation,
  handleSimulationCIChange,
  handleAddonChange,
  t,
}) {
  return (
    <>
      <tr className={cx("cash-in", options.cashIn ? "ex-on" : "ex-off")} onClick={() => toggle("cashIn")}>
        <th>{t("cash.cashIn")}</th>
        {cashIn.map((amount, i) => (
          <td key={i}>{formatMoney(amount, 1, 1)}</td>
        ))}
      </tr>
      {options.cashIn && (
        <tr
          className={cx("nd2 payments-from-customers", options.paymentsFromCustomers ? "ex-on" : "ex-off")}
          onClick={() => toggle("paymentsFromCustomers")}
        >
          <th>{t("cash.paymentsFromCustomers")}</th>
          {paymentsFromCustomers.weeks.map((amount, i) => (
            <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
          ))}
        </tr>
      )}
      {options.cashIn && options.paymentsFromCustomers && (
        <>
          <tr className={cx("nd3", options.due0 ? "ex-on" : "ex-off")}>
            <th className="ci-simulation" onClick={() => toggle("due0")}>
              <CISimulation
                title={t("cash.due0")}
                initial={simulation.ci.due0}
                inTimeEnabled
                sum={paymentsFromCustomers.due0.sum}
                onChange={(newData) => handleSimulationCIChange("due0", newData)}
              />
            </th>
            {paymentsFromCustomers.due0.weeks.map((amount, i) => (
              <td key={i}>{formatMoney(amount, 2, 2, "-")}</td>
            ))}
          </tr>
          {options.due0 && <CITable data={paymentsFromCustomers.due0} t={t} />}

          <tr className={cx("nd3", options.due30 ? "ex-on" : "ex-off")}>
            <th className="ci-simulation" onClick={() => toggle("due30")}>
              <CISimulation
                title={t("cash.due30")}
                initial={simulation.ci.due30}
                sum={paymentsFromCustomers.due30.sum}
                onChange={(newData) => handleSimulationCIChange("due30", newData)}
              />
            </th>
            {paymentsFromCustomers.due30.weeks.map((amount, i) => (
              <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
            ))}
          </tr>
          {options.due30 && <CITable data={paymentsFromCustomers.due30} t={t} />}

          <tr className={cx("nd3", options.due90 ? "ex-on" : "ex-off")}>
            <th className="ci-simulation" onClick={() => toggle("due90")}>
              <CISimulation
                title={t("cash.due90")}
                initial={simulation.ci.due90}
                sum={paymentsFromCustomers.due90.sum}
                onChange={(newData) => handleSimulationCIChange("due90", newData)}
              />
            </th>
            {paymentsFromCustomers.due90.weeks.map((amount, i) => (
              <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
            ))}
          </tr>
          {options.due90 && <CITable data={paymentsFromCustomers.due90} t={t} />}

          <tr className={cx("nd3", options.due90p ? "ex-on" : "ex-off")}>
            <th className="ci-simulation" onClick={() => toggle("due90p")}>
              <CISimulation
                title={t("cash.due90p")}
                initial={simulation.ci.due90p}
                sum={paymentsFromCustomers.due90p.sum}
                onChange={(newData) => handleSimulationCIChange("due90p", newData)}
              />
            </th>
            {paymentsFromCustomers.due90p.weeks.map((amount, i) => (
              <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
            ))}
          </tr>
          {options.due90p && <CITable data={paymentsFromCustomers.due90p} t={t} />}
        </>
      )}

      {options.cashIn && (
        <>
          <tr
            className={cx("nd2 addon", options.addonsCashIn ? "ex-on" : "ex-off")}
            onClick={() => toggle("addonsCashIn")}
          >
            <th>{t("cash.myAddons")}</th>
            {cashInAddons.weeks.map((amount, i) => (
              <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
            ))}
          </tr>
          {options.addonsCashIn && <AddonTable data={cashInAddons} onUpdate={handleAddonChange} t={t} />}
        </>
      )}
    </>
  );
}

function CISimulation({ title, initial, inTimeEnabled = false, onChange, sum = 0 }) {
  function handleChange(event) {
    const { target } = event;
    const newData = { ...initial, [target.name]: target.value };
    if (onChange) {
      onChange(newData);
    }
  }

  return (
    <div>
      <div>{title}</div>
      <div onClick={(e) => e.stopPropagation()} style={{ alignItems: "center" }}>
        <small className="pr-1">{formatMoney(sum, 1, 1)} Ksek</small>
        <select className="form-control" defaultValue={initial.part1} name="part1" onChange={handleChange}>
          <option value="0">Is paid to 0%</option>
          <option value="10">Is paid to 10%</option>
          <option value="20">Is paid to 20%</option>
          <option value="30">Is paid to 30%</option>
          <option value="40">Is paid to 40%</option>
          <option value="50">Is paid to 50%</option>
          <option value="60">Is paid to 60%</option>
          <option value="70">Is paid to 70%</option>
          <option value="80">Is paid to 80%</option>
          <option value="90">Is paid to 90%</option>
          <option value="100">Is paid to 100%</option>
        </select>
        {initial.part1 !== "0" && (
          <>
            <select className="form-control" defaultValue={initial.part2} name="part2" onChange={handleChange}>
              {inTimeEnabled && <option value="0">in Time</option>}
              <option value="1">in 1 week</option>
              <option value="2">in 2 weeks</option>
              <option value="3">in 3 weeks</option>
              <option value="4">in 4 weeks</option>
              <option value="5">in 5 weeks</option>
              <option value="6">in 6 weeks</option>
              <option value="7">in 7 weeks</option>
              <option value="8">in 8 weeks</option>
            </select>
            {initial.part1 !== "100" && (
              <select className="form-control" defaultValue={initial.part3} name="part3" onChange={handleChange}>
                <option value="0">and the rest never</option>
                <option value="2">and the rest in 2 weeks</option>
                <option value="3">and the rest in 3 weeks</option>
                <option value="4">and the rest in 4 weeks</option>
                <option value="5">and the rest in 5 weeks</option>
                <option value="6">and the rest in 6 weeks</option>
                <option value="7">and the rest in 7 weeks</option>
                <option value="8">and the rest in 8 weeks</option>
              </select>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function CITable({ t, data }) {
  return (
    <>
      <tr className="t-wrap t-header">
        <th>
          <div className="t-row">
            <div className="t-col">{t("common:customer")}</div>
            <div className="t-col text-right">{t("common:money.amount")}</div>
            <div className="t-col text-right">{t("common:dates.dueDate")}</div>
          </div>
        </th>
      </tr>
      {data.items.map((row) => (
        <tr key={row.item.id} className="t-wrap">
          <th>
            <div className="t-row">
              <div className="t-col">{row.item.customer_name}</div>
              <div className="t-col text-right">{formatMoney(row.item.amount, 2, 2)}</div>
              <div className="t-col text-right">{row.item.due_date}</div>
            </div>
          </th>
          {row.weeks.map((amount, i) => (
            <td key={i}>{formatMoney(amount, 2, 2, "-")}</td>
          ))}
        </tr>
      ))}
      {!data.items.length && (
        <tr className="t-wrap">
          <th>{t("common:noResultsFound")}</th>
        </tr>
      )}
    </>
  );
}

export default CashIn;
