import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DraggableModalDialog } from "../DraggableModalDialog";

function ConfirmInfoModal({ description, giveAnswer }) {
  const { t } = useTranslation("common");
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={() => giveAnswer(true)}
      dialogClassName="confirmation-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.fyi")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Modal.Body>
      <Modal.Footer className="space">
        <Button type="button" variant="secondary" onClick={() => giveAnswer(true)}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmInfoModal;
