import client from "api2/client";
import { parseISO } from "date-fns";
import _ from "lodash";

export function companySupplierInvoices(companyId, filters, config) {
  return client.get(`/supplier-invoices/`, {
    params: filters,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function companyApprovalSupplierInvoices(companyId, filters, config) {
  return client.get(`/supplier-invoices/for-approval/`, {
    params: filters,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function globalSupplierInvoices(filters, config) {
  return client.get(`/supplier-invoices/global/`, {
    params: filters,
    ...config,
  });
}

export function save(companyId, data, config) {
  if (data.id) {
    return client.put(`/supplier-invoices/${data.id}/update/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  }
  return client.post(`/supplier-invoices/create/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function previewVerifications(companyId, data, config) {
  if (data.id) {
    return client.put(`/supplier-invoices/${data.id}/update/verifications-preview/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  }
  return client.post(`/supplier-invoices/verifications-preview/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function approvalVerificationPreview(companyId, data, config) {
  return client.patch(`/supplier-invoices/${data.id}/update/accounting-info/verifications-preview/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}
export function updateAccountingInfo(companyId, invoiceId, data, config) {
  return client.patch(`/supplier-invoices/${invoiceId}/update/accounting-info/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function updatePaymentInfo(companyId, invoiceId, data, config) {
  return client.patch(`/supplier-invoices/${invoiceId}/update/payment-info/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function updateRepresentationInfo(companyId, invoiceId, data, config) {
  return client.patch(`/supplier-invoices/${invoiceId}/update/representation-info/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function createDraftsFromFiles(companyId, files, config) {
  const formData = new FormData();
  formData.append("merge_files", false);
  files.forEach((file, i) => {
    if (file) {
      formData.append("files", file, file.name);
    }
  });

  return client.post(`/supplier-invoices/create-from-files/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-company": companyId,
    },
    ...config,
  });
}

export function details(companyId, invoiceId, config) {
  return client
    .get(`/supplier-invoices/${invoiceId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) => ({
      ...response.data,
      booking_date: response.data.booking_date ? parseISO(response.data.booking_date) : null,
      due_date: response.data.due_date ? parseISO(response.data.due_date) : null,
      periodisation_date_start: response.data.periodisation_date_start
        ? parseISO(response.data.periodisation_date_start)
        : null,
      newDocuments: [{ key: _.uniqueId("nd.") }],
    }));
}

export function toggleDeactivatePayment(companyId, invoiceId, config) {
  return client.patch(`/supplier-invoices/${invoiceId}/toggle-deactivate-payment/`, null, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function moveToArchiveDocument(companyId, invoiceId, config) {
  return client.post(`/supplier-invoices/${invoiceId}/archive-document/create/`, null, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function remove(companyId, invoiceId, config) {
  return client.delete(`/supplier-invoices/${invoiceId}/delete/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function moveDraft(companyId, invoiceId, config) {
  return client.patch(`/supplier-invoices/${invoiceId}/draft/`, null, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function getNextInvoice(companyId, invoiceId, mode, config, filters = {}) {
  return client
    .get(`/supplier-invoices/${invoiceId}/next/`, {
      params: { mode, ...filters },
      headers: {
        "x-company": companyId,
      },
      ...config,
    })
    .then((response) => ({ next: response.data.next }));
}

export function getNextDraft(companyId, invoiceId, config) {
  return getNextInvoice(companyId, invoiceId, "draft", config);
}

export function getNextApproval(companyId, invoiceId, userId, config) {
  return getNextInvoice(companyId, invoiceId, "approval", config, {
    approval_flow_approver: userId,
    approval_flow_approver_current_active_only: true,
  });
}

export function approve(companyId, invoiceId, config) {
  return client.patch(`/supplier-invoices/${invoiceId}/approve/`, null, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function investigate(companyId, invoiceId, comment, config) {
  return client.patch(`/supplier-invoices/${invoiceId}/investigate/`, comment, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function checkDuplicates(companyId, data, config) {
  return client.get(`/supplier-invoices/has-duplicates/`, {
    params: data,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function reInvoiceWithNewCustomerInvoice(companyId, invoiceId, data, config) {
  return client.post(`/supplier-invoices/${invoiceId}/reinvoice-new/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function reInvoiceWithConnectCustomerInvoice(companyId, invoiceId, data, config) {
  return client.post(`/supplier-invoices/${invoiceId}/reinvoice-connect/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function payBGC(companyId, invoiceId, bankIDToken, config) {
  return client.post(
    `/supplier-invoices/${invoiceId}/payments/pay-bgc/`,
    {
      bank_id_token: bankIDToken,
    },
    {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }
  );
}

export function payBGCAtOnce(companyId, invoiceId, bankIDToken, config) {
  return client.post(
    `/supplier-invoices/${invoiceId}/payments/pay-bgc-at-once/`,
    {
      bank_id_token: bankIDToken,
    },
    {
      headers: {
        "x-company": companyId,
      },
      ...config,
    }
  );
}

export function cancelPayment(companyId, invoiceId, config) {
  return client.delete(`/supplier-invoices/${invoiceId}/payments/cancel/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function manualPay(companyId, invoiceId, data, config) {
  return client.post(`/supplier-invoices/${invoiceId}/payments/pay-manual/`, data, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function rescanInvoice(companyId, invoiceId, config) {
  return client.get(`/supplier-invoices/${invoiceId}/ocr-scan/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export const credits = {
  list: (companyId, debitInvoiceId, config) => {
    return client.get(`/supplier-invoices/${debitInvoiceId}/credits/`, {
      params: { page_size: 25 },
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  create: (companyId, debitInvoiceId, creditInvoiceId) => {
    return client.post(`/supplier-invoices/${debitInvoiceId}/credits/${creditInvoiceId}/create/`, null, {
      headers: {
        "x-company": companyId,
      },
    });
  },
};

export function history(companyId, invoiceId, config) {
  return client.get(`/supplier-invoices/${invoiceId}/history/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}
