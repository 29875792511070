import { format, isAfter as _isAfter, isBefore as _isBefore, isSameDay as _isSameDay, parseISO } from "date-fns";
import { sv } from "date-fns/locale";

const DATE_FORMAT = "yyyy-MM-dd";
const SHORT_DATE_FORMAT = "yyMMdd";
const DATETIME_FORMAT = "yyyy-MM-dd, HH:mm";

function parseDate(date) {
  if (date instanceof Date) {
    return date;
  }
  return date ? parseISO(date) : null;
}

function formatDate(date) {
  if (!date) {
    return null;
  }
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }
  try {
    return date ? format(date, DATE_FORMAT) : null;
  } catch (e) {
    return null;
  }
}

function formatMonth(date, pattern = "MM/yyyy", language = "en") {
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }
  return date ? format(date, pattern, { locale: language === "sv" ? sv : null }) : null;
}

function formatQuarter(date) {
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }
  return date ? format(date, "QQQ/yyyy") : null;
}

function formatQuarterSolo(date) {
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }
  return date ? format(date, "QQQ") : null;
}

function formatYear(date) {
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }
  return date ? format(date, "yyyy") : null;
}

function formatMonthName(date) {
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }
  return date ? format(date, "MMM") : null;
}

function formatMonthTrailingNumber(date) {
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }
  return date ? format(date, "MM") : null;
}

function formatDatetime(date) {
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }
  return date ? format(date, DATETIME_FORMAT) : null;
}

function formatDay(date, language = "en") {
  if (!(date instanceof Date)) {
    date = parseISO(date);
  }
  return date ? format(date, "eee, d MMM", { locale: language === "sv" ? sv : null }) : null;
}

function formatDuration(duration) {
  const minutes = Math.floor(duration / 60) % 60;
  const hours = Math.floor(duration / 3600);
  const seconds = duration % 60;
  return `${hours ? `${hours.toString()}h ` : ""} ${minutes ? `${minutes.toString()}min ` : ""} ${seconds}sec`;
}

function formatLazyDuration(duration) {
  if (duration > 3600 * 48) {
    // more than 2 days - show only {days}d
    const days = Math.ceil(duration / (3600 * 24));
    return `${days.toString()}d`;
  }
  if (duration > 5400) {
    // more than 1.5h show only hours
    const hours = Math.ceil(duration / 3600);
    return `${hours.toString()}h`;
  }
  // less than 1.5h - show with minutes
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor(duration / 60) % 60;
  return `${hours ? `${hours.toString()}h ` : ""} ${minutes.toString()}m`;
}

function formatDurationShort(duration) {
  const minutes = Math.floor(Math.abs(duration) / 60) % 60;
  const hours = Math.floor(Math.abs(duration) / 3600);
  if (hours === 0 && minutes === 0) {
    return "0min";
  }
  let prefix = "";
  if (duration < 0) {
    prefix = "- ";
  }
  return `${prefix}${hours ? `${hours.toString()}h ` : ""} ${minutes ? `${minutes.toString()}min ` : ""}`;
}

function isBefore(date1, date2) {
  // Omit different hours, compare only date
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  return _isBefore(date1, date2);
}

function isAfter(date1, date2) {
  // Omit different hours, compare only date
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  return _isAfter(date1, date2);
}

function isSameDay(date1, date2) {
  // Omit different hours, compare only date
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  return _isSameDay(date1, date2);
}

function isSameDayOrAfter(date1, date2) {
  // Omit different hours, compare only date
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  return _isAfter(date1, date2) || _isSameDay(date1, date2);
}

function isSameDayOrBefore(date1, date2) {
  // Omit different hours, compare only date
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  return _isBefore(date1, date2) || _isSameDay(date1, date2);
}

export {
  DATE_FORMAT,
  SHORT_DATE_FORMAT,
  DATETIME_FORMAT,
  parseDate,
  formatDate,
  formatDatetime,
  formatDay,
  formatYear,
  formatMonth,
  formatQuarter,
  formatQuarterSolo,
  formatMonthName,
  formatMonthTrailingNumber,
  formatDuration,
  formatLazyDuration,
  formatDurationShort,
  isBefore,
  isAfter,
  isSameDay,
  isSameDayOrAfter,
  isSameDayOrBefore,
};
