import React from "react";
import { DocumentBox } from "./document-box";

export function SavedDocuments({ documents, companyId, partUrl, removable = true }) {
  return (
    <>
      {documents.map((docId) => (
        <DocumentBox
          key={`sd.${docId}`}
          partUrl={partUrl}
          companyId={companyId}
          documentId={docId}
          removable={removable}
        />
      ))}
    </>
  );
}

export function SavedVerificationDocuments({ verificationId, documents, companyId, removable = true }) {
  return (
    <SavedDocuments
      documents={documents}
      companyId={companyId}
      removable={removable}
      partUrl={`/verifications/${verificationId}/documents`}
    />
  );
}

export function SavedSpecificationDocuments({ specificationId, documents, companyId, removable = true }) {
  return (
    <SavedDocuments
      documents={documents}
      companyId={companyId}
      removable={removable}
      partUrl={`/specifications/${specificationId}/documents`}
    />
  );
}

export function SavedOfferDocuments({ offerId, documents, companyId, removable = true }) {
  return (
    <SavedDocuments
      documents={documents}
      companyId={companyId}
      removable={removable}
      partUrl={`/offers/${offerId}/documents`}
    />
  );
}

export function SavedOrderDocuments({ orderId, documents, companyId, removable = true }) {
  return (
    <SavedDocuments
      documents={documents}
      companyId={companyId}
      removable={removable}
      partUrl={`/orders/${orderId}/documents`}
    />
  );
}
