import { ProductAddModal } from "components/modals/invoices";
import React, { useState } from "react";
import { Button, ButtonGroup, Table } from "react-bootstrap";

import { TableGroup } from "components/formik";
import { BSelectAsync } from "components/formik/pickers";
import { formatMoney, roundMoney } from "utils/money";
import { getCustomerCountryArea } from "utils/countries";
import { formatDate } from "utils/date";
import _, { debounce } from "lodash";
import * as ciAPI from "api2/customer-invoices";
import { vies as viesAPI } from "api2/integrations";
import useModal from "hooks/useModal";
import { confirmInfo } from "components/modals/ConfirmModal";

import { calculateRecordTotal, getSummaryNet, getSummaryVat, getTaxReduction } from "utils/calc/ci";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import * as options from "api/options";

const vatOptions = options.vatOptions.asList();

const isReversedVATAllowed = (customer) => {
  if (!customer) {
    return false;
  }
  const countryArea = getCustomerCountryArea(customer.country);
  const isOutsideEU = countryArea === "outside_eu";
  const isCustomerCompany = customer.customer_type !== "private";
  const hasVATNumber = customer.vat_number;
  return isCustomerCompany && (!!hasVATNumber || isOutsideEU);
};

const isReversedVATInitiallyTrue = (customer) => {
  if (!customer) {
    return false;
  }
  const countryArea = getCustomerCountryArea(customer.country);
  if (countryArea === "sweden") {
    return false;
  }
  const isOutsideEU = countryArea === "outside_eu";
  const isCustomerCompany = customer.customer_type !== "private";
  const hasVATNumber = customer.vat_number;
  return isCustomerCompany && (!!hasVATNumber || isOutsideEU);
};

function handlePrice(originalProduct, group) {
  let fieldName;
  if (!group || group === "A") {
    fieldName = "unit_price";
  } else {
    fieldName = `${group.toLowerCase()}_unit_price`;
  }
  let originalPrice = originalProduct[fieldName];
  if (originalPrice === undefined || originalPrice === null) {
    originalPrice = originalProduct.unit_price;
  }
  return originalPrice;
}

async function onChangePriceGroup(priceGroup, values, setFieldValue, connectedProducts) {
  const newRecords = values.records.map((record) => {
    if (record.record_type === "product") {
      return {
        ...record,
        product: {
          ...record.product,
          unit_price: handlePrice(connectedProducts[record.product.product_id], priceGroup.value),
        },
      };
    }
    return record;
  });
  setFieldValue("price_group", priceGroup);
  setFieldValue("records", newRecords);
}

const isVatDisabled = (company, values) => {
  return !!company.vat_disabled || !!values.reversed_vat;
};
const getValuesOnReverseVatChange = (isChecked, customer, values, connectedProducts, companyVatDisabled = false) => {
  const reversedVatReceiverNo = customer ? customer.vat_number || "" : "";
  return {
    ...values,
    customer,
    reversed_vat: isChecked,
    reversed_vat_receiver_no: isChecked ? reversedVatReceiverNo : "",
    records: values.records.map((r) => {
      if (r.record_type === "product") {
        return {
          ...r,
          product: {
            ...r.product,
            vat_percent:
              isChecked || companyVatDisabled
                ? vatOptions[0]
                : options.vatOptions.getOption(
                    connectedProducts[r.product.product_id]?.vat_percent || r.product.vat_percent.value
                  ),
            account: connectedProducts[r.product.product_id]?.account || r.product.account,
          },
        };
      }

      return { ...r };
    }),
  };
};

async function onReversedVatChange(isChecked, customer, values, setValues, connectedProducts, companyVatDisabled) {
  const newValues = getValuesOnReverseVatChange(isChecked, customer, values, connectedProducts, companyVatDisabled);
  setValues(newValues);
}

const onCustomerReset = (setFieldValue, values, setValues, connectedProducts, company) => {
  // remove RotRut data
  values = {
    ...values,
    rot_rut: null,
    records: values.records.map((record) => {
      return { ...record, rot_rut: null };
    }),
  };
  onReversedVatChange(false, null, values, setValues, connectedProducts, company.vat_disabled);
};
const onCustomerChange = async (
  company,
  customer,
  values,
  setValues,
  setFieldValue,
  t,
  connectedProducts = {},
  originalPrices = {},
  setOriginalPrices = null,
  forContract = false
) => {
  const countryArea = getCustomerCountryArea(customer && customer.country);
  const newValues = {
    ...values,
    our_reference: customer ? customer.our_reference : "",
    your_reference: customer ? customer.your_reference : "",
    price_group:
      !forContract && customer?.price_group
        ? options.priceGroups.getOption(customer.price_group)
        : options.priceGroups.getOption("A"), // only A for contracts
  };
  if (!forContract) {
    const shouldWarn = await ciAPI.customers.shouldWarnOldMailDelivery(company.id, customer.value);
    if (shouldWarn) {
      await confirmInfo(t("ci:confirm.oldMailDelivery"));
    }
    newValues.payment_terms =
      customer && customer.default_payment_terms !== 365
        ? options.paymentTerms.getOption(customer.default_payment_terms)
        : options.paymentTerms.getOption(company.default_invoice_payment_terms);
  }
  if (!values.id) {
    newValues.delivery_method = customer.default_delivery_method || "email";
  }
  const _newValuesOnReverseVatChange = getValuesOnReverseVatChange(
    isReversedVATInitiallyTrue(customer), // if reverse is allowed and customer is not from sweden then set is a true
    customer,
    newValues,
    connectedProducts,
    company.vat_disabled
  );
  Object.keys(_newValuesOnReverseVatChange).forEach((key) => {
    newValues[key] = _newValuesOnReverseVatChange[key];
  });

  const isCustomerPrivate = customer && customer.customer_type === "private";
  if (countryArea === "within_eu" && !isCustomerPrivate) {
    viesAPI.vatCheck(company.id, customer.vat_number).catch((error) => {
      if (error.data.__all__) {
        confirmInfo(error.data.__all__);
      }
    });
  }
  setValues(newValues, true);
  // logic (price groups) not used in contracts
  if (!forContract && newValues.price_group.value !== values.price_group.value) {
    await onChangePriceGroup(newValues.price_group, newValues, setFieldValue, connectedProducts);
  }
};

function RecordsForm({ values, currency, arrayHelper, forCreditInvoice, t }) {
  const standardRecords = values.records.filter((item) => item.record_type === "product");
  const customerLang = values.customer ? values.customer.language : "sv";
  const summaryNet = getSummaryNet(standardRecords);
  const summaryVat = getSummaryVat(values.reversed_vat, standardRecords);
  const basis = summaryNet + summaryVat;
  const taxReduction = getTaxReduction(standardRecords);
  const summaryTotal = summaryNet + summaryVat + taxReduction;
  const summaryTotalRounded = roundMoney(summaryTotal, 0);
  const centRounding = summaryTotalRounded - summaryTotal;

  return (
    <Table bordered size="sm" className="records">
      <thead>
        <tr>
          <th style={{ width: "10%", minWidth: 100 }}>{t("artNo")}</th>
          <th style={{ width: "40%" }}>{t("common:description")}</th>
          <th style={{ width: "5%", minWidth: 100 }}>{t("common:money.quantity")}</th>
          <th style={{ width: "5%", minWidth: 100 }}>{t("unit")}</th>
          <th style={{ width: "5%", minWidth: 200 }} className="text-right">
            {t("common:money.unitPrice")} ({currency})
          </th>
          <th style={{ width: "5%", minWidth: 100 }}>{t("common:money.vat")}</th>
          <th style={{ width: "5%", minWidth: 100 }}>{t("common:money.discount")}</th>
          <th style={{ width: "10%", minWidth: 190 }} className="text-right">
            {t("common:money.total")}
          </th>
          <th style={{ width: "5%", minWidth: 120 }} />
        </tr>
      </thead>
      <tbody>
        {values.records
          // .filter((e) => !["RotRutMsg"].includes(e.ArticleNumber))
          .map((record, index) => (
            <tr key={record.key}>
              {record.record_type === "message" && (
                <>
                  <td />
                  <TableGroup.Input
                    tdProps={{ colSpan: 7 }}
                    placeholder={t("freeTextRow")}
                    name={`records[${index}].message.text`}
                  />
                </>
              )}
              {record.record_type === "product" && (
                <>
                  <td className="disabled">
                    <span>{record.product.product_number}</span>
                  </td>
                  <TableGroup.Input name={`records[${index}].product.name`} disabled={forCreditInvoice} />
                  {forCreditInvoice ? (
                    <TableGroup.MoneyInput // BFLAKT-3857  use Money for handle it with comma only
                      name={`records[${index}].product.quantity`}
                      onBlur={(e) => {
                        if (record.maxQtyForCredit && e.target.value >= record.maxQtyForCredit) {
                          arrayHelper.replace(index, {
                            ...record,
                            quantity: record.maxQtyForCredit,
                          });
                        }
                      }}
                      maxvalue={record.maxQtyForCredit ? record.maxQtyForCredit : undefined}
                    />
                  ) : (
                    <TableGroup.MoneyInput name={`records[${index}].product.quantity`} /> // BFLAKT-3857
                  )}
                  <TableGroup.Input name={`records[${index}].product.unit`} disabled={forCreditInvoice} />
                  <TableGroup.MoneyInput name={`records[${index}].product.unit_price`} disabled={forCreditInvoice} />
                  <TableGroup.SimpleSelect
                    isDisabled
                    name={`records[${index}].product.vat_percent`}
                    options={vatOptions}
                  />
                  <td className="text-right disabled">
                    <span>
                      {record.discount ? (
                        <>
                          {record.discount.value} {record.discount.type === "amount" ? currency : "%"}
                        </>
                      ) : (
                        "-"
                      )}
                    </span>
                  </td>
                  <td className="text-right disabled">
                    <span>
                      {formatMoney(calculateRecordTotal(record), 2, 2)} {currency}
                    </span>
                  </td>
                </>
              )}
              <td className="text-center">
                <ButtonGroup>
                  <Button
                    variant="toggle"
                    size="sm"
                    onClick={() => arrayHelper.swap(index, index - 1)}
                    disabled={index === 0}
                  >
                    <i className="fas fa-long-arrow-alt-up" />
                  </Button>
                  <Button
                    variant="toggle"
                    size="sm"
                    onClick={() => arrayHelper.swap(index, index + 1)}
                    disabled={index === values.records.length - 1}
                  >
                    <i className="fas fa-long-arrow-alt-down" />
                  </Button>
                  <Button variant="toggle" size="sm" onClick={() => arrayHelper.remove(index)}>
                    <i className="fas fa-trash" />
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        {values.reversed_vat && (
          <tr>
            <td colSpan={9} className="p-1">
              <i>
                {customerLang === "en"
                  ? "Reverse VAT according to chapter 1 2§ first section 4b in the VAT regulation"
                  : "Omvänd moms enligt 1 kap. 2 § 1 st. 4b Momsförordningen"}
              </i>
            </td>
          </tr>
        )}
        {taxReduction !== 0 && (
          <tr>
            <td colSpan={9} className="p-1">
              <i>
                {customerLang === "en"
                  ? "We reserve the right to invoice the remaining amount, corresponding to the tax reduction, if the request from Skatteverket is rejected."
                  : "Vi förbehåller oss rätten att fakturera återstående belopp, motsvarande skattereduktionen, om begäran från Skatteverket avslås."}
              </i>
            </td>
          </tr>
        )}
        {values?.rot_rut?.work_start && values?.rot_rut?.work_end && (
          <tr>
            <td colSpan={9} className="p-1">
              <i>
                {customerLang === "en"
                  ? `Time period when work was performed: From ${formatDate(values?.rot_rut?.work_start)} to ${formatDate(values.rot_rut.work_end)}`
                  : `Tidsperiod då arbetet utfördes: Från ${formatDate(values?.rot_rut?.work_start)} till ${formatDate(values.rot_rut.work_end)}`}
              </i>
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={7} className="text-right">
            {t("common:money.net")}
          </td>
          <td className="text-right">
            {formatMoney(summaryNet, 2, 2)} {currency}
          </td>
          <td />
        </tr>
        <tr>
          <td colSpan={7} className="text-right">
            {t("common:money.vat")}
          </td>
          <td className="text-right">
            {formatMoney(summaryVat, 2, 2)} {currency}
          </td>
          <td />
        </tr>
        {centRounding !== 0 && (
          <tr>
            <td colSpan={7} className="text-right">
              {t("common:money.centRounding")}
            </td>
            <td className="text-right">
              {formatMoney(centRounding, 2, 2)} {currency}
            </td>
            <td />
          </tr>
        )}
        {taxReduction !== 0 && (
          <tr>
            <td colSpan={7} className="text-right">
              {t("basisTaxReduction")}
            </td>
            <td className="text-right">
              {formatMoney(basis)} {currency}
            </td>
            <td />
          </tr>
        )}
        {taxReduction !== 0 && (
          <tr>
            <td colSpan={7} className="text-right">
              {t("taxReduction")}
            </td>
            <td className="text-right">
              {formatMoney(taxReduction)} {currency}
            </td>
            <td />
          </tr>
        )}
        <tr>
          <td colSpan={7} className="text-right">
            <strong>{t("common:money.totalInclVat")}</strong>
          </td>
          <td className="text-right">
            <strong>
              {formatMoney(summaryTotalRounded, 2, 2)} {currency}
            </strong>
          </td>
          <td />
        </tr>
      </tfoot>
    </Table>
  );
}

const getProducts = debounce((companyId, params, callback) => {
  params.mode = "active";
  params.page_size = 9999;
  params.ordering = "-product_number";
  ciAPI.products
    .list(companyId, params)
    .then((response) => {
      return callback(
        response.data.map((item) => ({
          ...item,
          value: item.id,
          label: `${item.product_number ? `${item.product_number} - ` : ""} ${item.name}`.trim(),
        }))
      );
    })
    .catch(() => callback([]));
}, 500);

// TODO DOUBLE TEST?RECHECK CYRRENCY - CI CURRENCY PROVIDE INDEASTED VALUES
function RecordsTool({
  t,
  companyId,
  arrayHelper,
  priceGroup,
  vatDisabled,
  rotRutEnabled,
  discountModal,
  rotRutModal,
  ccProjModal,
  centerOptions,
  projectOptions,
  connectedProducts,
  setConnectedProducts,
  originalPrices,
  setOriginalPrices,
  forCreditInvoice,
  forOffer = false,
  forOrder = false,
  bookingDate,
}) {
  const productModal = useModal();
  const activeProjects = filterActiveProjects(projectOptions, bookingDate);
  const activeCenters = centerOptions.filter(filterActiveCC);
  const showProjCCBtn = !forOffer && !forOrder && (activeProjects.length > 0 || activeCenters.length > 0);
  const [productsCacheKey, setProductsCacheKey] = useState(_.uniqueId());

  const appendProduct = (product) => {
    originalPrices[product.id] = product.unit_price;
    setOriginalPrices(originalPrices);
    connectedProducts[product.id] = product;
    setConnectedProducts(connectedProducts);
    arrayHelper.push({
      key: _.uniqueId("ln"),
      record_type: "product",
      message: null,
      product: {
        product_id: product.id,
        product_number: product.product_number,
        product_type: product.product_type,
        name: product.name,
        account: product.account,
        cost_center: product.cost_center ? parseInt(product.cost_center, 10) : null,
        unit: product.unit,
        quantity: 1,
        unit_price: handlePrice(product, priceGroup.value),
        vat_percent: vatDisabled ? vatOptions[0] : options.vatOptions.getOption(product.vat_percent) || vatOptions[0],
      },
      rot_rut: null,
      discount: null,
    });
  };

  const onProductCreate = () => {
    // refresh options
    setProductsCacheKey(_.uniqueId());
    productModal.close();
  };

  const appendText = () => {
    arrayHelper.push({
      key: _.uniqueId("ln"),
      record_type: "message",
      message: { text: "" },
      product: null,
      rot_rut: null,
      discount: null,
    });
  };

  return (
    <>
      <div className="mb-4 records-tool">
        <div>
          <BSelectAsync
            key={productsCacheKey} // allow refreshing products if new product added
            className="react-select product-select"
            field={{ name: "product" }}
            menuPosition="portal"
            closeMenuOnSelect={false}
            isDisabled={forCreditInvoice}
            minSearchLength={0}
            maxMenuHeight={400}
            loadOptions={(params, callback) => getProducts(companyId, params, callback)}
            placeholder={t("actions.selectProduct")}
            value={null}
            styles={{
              menu: (provided) => ({ ...provided, width: 500 }),
            }}
            onChange={appendProduct}
          />
          <Button variant="secondary" className="text-row" onClick={productModal.open}>
            <i className="fas fa-plus" /> {t("actions.addProduct")}
          </Button>
          <Button variant="secondary" className="text-row" onClick={appendText}>
            <i className="fas fa-comment" /> {t("textRow")}
          </Button>
        </div>
        <div>
          <ButtonGroup>
            <Button variant="secondary" onClick={discountModal.open}>
              <i className="fas fa-percent" /> {t("common:money.discount")}
            </Button>
            {showProjCCBtn && (
              <Button variant="secondary" onClick={ccProjModal.open}>
                <i className="fas fa-align-justify" /> {t("common:ccAndProj")}
              </Button>
            )}
            {rotRutEnabled && (
              <Button variant="secondary" onClick={rotRutModal.open}>
                <i className="fas fa-home" /> {t("rotRut")}
              </Button>
            )}
          </ButtonGroup>
        </div>
      </div>
      {productModal.show && (
        <ProductAddModal companyId={companyId} onSuccess={onProductCreate} onCancel={productModal.close} />
      )}
    </>
  );
}

export {
  onCustomerChange,
  getValuesOnReverseVatChange,
  onChangePriceGroup,
  handlePrice,
  onCustomerReset,
  onReversedVatChange,
  isVatDisabled,
  isReversedVATAllowed,
  RecordsTool,
  RecordsForm,
};
