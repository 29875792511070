import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Alert, Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { Form, Formik } from "formik";
import { formatISO } from "date-fns";
import * as yup from "yup";
import _ from "lodash";
import { toast } from "react-toastify";
import cx from "classnames";

import { SubmitButton } from "components/ui/buttons";
import { NewDocuments } from "components/ui/documents/verification-documents";
import { SavedVerificationDocuments } from "components/ui/documents-refactored";
import * as verAPI from "api2/verification";
import * as documentAPI from "api/document";
import { collectionSum } from "utils/list";
import { useTranslation } from "react-i18next";
import { formatMoney, roundMoney } from "utils/money";
import { useVerificationDispatch, useVerificationState } from "hooks/useVerification";
import { useCompanyState } from "hooks/useCompany";
import { enterKeyDownAsTabForVerModal } from "utils/others";
import { FinancialYearDispatchContext, FinancialYearStateContext } from "state/providers/FinancialYearProvider";
import { HasPermCode, PermCodeRequired } from "components/perms";
import { codesForInventoryRegister, codesForVerifications } from "components/perms/PermCodes";
import { handleActionErrors } from "api/errors";
import BalanceProvider, { BalanceStateContext } from "state/providers/BalanceProvider";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { formatDatetime, parseDate } from "utils/date";
import { filterPeriodisationCostAccountsForVer, getPeriodisationStartDateInvalidMsg } from "utils/periodisation";
import { isAssetToAutoAddBasedOnTransactions } from "utils/assets";
import TableLoader from "components/tables/btable/TableLoader";
import * as documentsAPI from "api2/documents";
import * as assetAPI from "api2/assets";
import { allowedFileExtensionsFor, getFileNameDispositionContent } from "utils/file";
import { confirmExecute, confirmRemove } from "../ConfirmModal";
import RelatedInfo from "./RelatedBox";
import { DraggableModalDialog } from "../DraggableModalDialog";
import VerificationForm, { getTransactionPayload } from "./VerificationForm";
import useAsync from "../../../hooks/useAsync";
import { RocketLoader } from "../../ui/loaders";

import useModal from "../../../hooks/useModal";
import { FileArchiveDocumentModal } from "../DocumentsModal";
import { DocumentBox } from "../../ui/documents-refactored";

function ArchiveFilesToConnect({ companyId, docIDsAttached, setDocIDsAttached }) {
  const dataSource = useCallback(
    (cancelToken) => documentsAPI.archiveDocuments.list(companyId, { status: "new", page_size: 9999 }, { cancelToken }),
    [companyId]
  );
  const { t } = useTranslation("common");
  const [{ data: documents, loading: loadingProjects }] = useAsync(dataSource, []);
  const [searchText, setSearchText] = useState("");
  const fileModal = useModal();

  const openModal = (archiveId) => {
    fileModal.open(archiveId);
  };
  if (loadingProjects) {
    return <RocketLoader />;
  }
  const onArchiveDocConnectSwitch = (docId) => {
    const _docIDsAttached = [...docIDsAttached];
    if (!_docIDsAttached.includes(docId)) {
      _docIDsAttached.push(docId);
    } else {
      _docIDsAttached.splice(_docIDsAttached.indexOf(docId), 1);
    }
    setDocIDsAttached(_docIDsAttached);
  };
  const isDocChecked = (docId) => {
    return docIDsAttached.includes(docId);
  };
  const search = _.debounce((event) => {
    setSearchText(event.target.value.trim());
  }, 500);
  if (!documents.length) {
    return (
      <Table className="pt-2">
        <tbody>
          <tr>
            <td colSpan={3}>{t("common:noResultsFound")}</td>
          </tr>
        </tbody>
      </Table>
    );
  }
  return (
    <>
      <div className="search-input-wrapper">
        <input
          placeholder={t("common:search")}
          name="search"
          type="text"
          className="form-control"
          autoComplete="off"
          onChange={search}
        />
      </div>
      <Scrollbars className="body" style={{ height: documents.length < 9 ? documents.length * 50 : "450px" }}>
        <Table>
          <tbody>
            {documents
              .filter((doc) => doc.title.toLowerCase().includes(searchText.toLowerCase()))
              .map((doc) => (
                <tr key={doc.id} style={{ background: isDocChecked(doc.id) ? "rgba(52, 140, 212, 0.07)" : "initial" }}>
                  <td
                    className="text-right"
                    width="25"
                    onClick={() => {
                      openModal(doc.id);
                    }}
                  >
                    {isDocChecked(doc.id) ? (
                      <i className="icon-checked fas fa-check" />
                    ) : (
                      <i className="fas fa-search icon-unchecked" />
                    )}
                  </td>
                  <td
                    className="text-left float-left pl-0"
                    onClick={() => {
                      onArchiveDocConnectSwitch(doc.id);
                    }}
                  >
                    <span className={cx("title ", { checked: isDocChecked(doc.id) })}>{doc.title}</span>{" "}
                    <span className="file-type">{doc.file_type}</span>
                  </td>
                  <td className="created">{formatDatetime(doc.created)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Scrollbars>
      {fileModal.show && (
        <FileArchiveDocumentModal
          companyId={companyId}
          archiveId={fileModal.data}
          handleClose={fileModal.close}
          handleOpen={fileModal.open}
          connectConfig={{ onArchiveDocConnectSwitch, isDocChecked }}
        />
      )}
    </>
  );
}

function VerificationModalWrapper() {
  const { close, open: openVerificationModal } = useVerificationDispatch();
  const { t } = useTranslation("common");
  const { verification } = useVerificationState();
  const { pathname } = useLocation();
  const {
    company: { id: companyId },
    accounts: { byId: accountById, asOptions: accountOptions },
    costCenters: { asOptions: centerOptions },
    projects: { asOptions: projectOptions },
  } = useCompanyState();
  const isConsult = true;

  const onHide = async (isDirty) => {
    let confirmed = true;
    if (isDirty === true) {
      confirmed = await confirmExecute(t("common:confirm.unsavedData"), "actions.yes");
    }
    if (confirmed) {
      close();
    }
  };

  useEffect(() => {
    return () => {
      close();
    };
  }, [pathname, close]);

  if (!verification) {
    return null;
  }
  const initialAccounts = verification.transactions
    .filter((tr) => tr.is_deleted === false && !!tr.account)
    .map((tr) => tr.account.number);
  return (
    <Modal
      animation={null}
      backdrop="static"
      show
      onHide={onHide}
      dialogClassName="verification-modal"
      key={verification.id}
      dialogAs={DraggableModalDialog}
    >
      <BalanceProvider initialAccounts={isConsult ? initialAccounts : []} companyId={companyId}>
        <VerificationModal
          isConsult={isConsult}
          verification={verification}
          closeModal={onHide}
          openModal={openVerificationModal}
          companyId={companyId}
          accountOptions={accountOptions}
          accountById={accountById}
          centerOptions={centerOptions}
          projectOptions={projectOptions}
        />
      </BalanceProvider>
    </Modal>
  );
}

function VerificationModal({
  verification,
  closeModal,
  openModal,
  companyId,
  isConsult,
  accountOptions,
  accountById,
  projectOptions,
  centerOptions,
}) {
  const { company } = useCompanyState();
  const {
    financialYear: { id: financialYear },
  } = useContext(FinancialYearStateContext);
  const [newFiles, setNewFiles] = useState(verification.newDocuments || [{ key: _.uniqueId("nd.") }]);
  const [savedDocuments, setSavedDocuments] = useState(verification.documents);
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const { t } = useTranslation(["ver", "common", "ci"]);
  const isDeleted = verification.status === 2;
  const isManualVerification = verification.series === "M";
  const { checkYears } = useContext(FinancialYearDispatchContext);
  const hasPermForInventoryRead = HasPermCode(codesForInventoryRegister.enabled);
  const { pathname } = useLocation();
  const { fetchBalance } = useContext(BalanceStateContext);
  const [docIDsAttached, setDocIDsAttached] = useState(verification.archive ? [verification.archive] : []);
  const [showAttach, setShowAttach] = useState(docIDsAttached.length >= 1);

  const [isRescanAvailable, setIsRescanAvailable] = useState(false);
  const [isReceiptScanning, setIsReceiptScanning] = useState(false);
  const [isReinitialized, setIsReinitialized] = useState(false);

  const [scannedReceipt, setScannedReceiptReceipt] = useState(null);
  const [prelimNumber, setPrelimNumber] = useState(null);

  const [scannedFileId, setScannedFileId] = useState("");

  const [validateTransactions, setValidateTransactions] = useState([]);

  const confirmMessageOnEmptyVer = useMemo(() => t("confirm.wantToSendForInterpretation", { ns: "ci" }), [t]);

  const confirmMessageOnNonEmptyVer = useMemo(
    () => `
	${t("confirm.wantToSendForInterpretation", { ns: "ci" })} 
	${t("overwriteDataOnConfirm", { ns: "ci" })}`,
    [t]
  );

  const confirmMessageOnOldVerExiting = useMemo(
    () => `
	${t("confirm.wantToSendForInterpretation", { ns: "ci" })} 
	${t("confirm.appendNewDataOnConfirm", { ns: "ci" })} 
	${t("confirm.crossOverUnusedData", { ns: "ci" })}`,
    [t]
  );

  const initialReceipt = {
    ...verification,
    title: verification.title || "",
    transactions: verification.transactions.map((tran) => ({
      ...tran,
      credit: tran.credit || "",
      debit: tran.debit || "",
      uid: tran.id || _.uniqueId("tran"),
      periodisation_config: !tran.periodisation_config
        ? null
        : {
            ...tran.periodisation_config,
            date_start: parseDate(tran.periodisation_config.date_start),
            show: false,
            enabled: true,
            status: tran.periodisation_config.status,
          },
    })),
  };

  if (isManualVerification && verification.id === null) {
    verAPI.getPreliminaryNumber(companyId, verification.series, financialYear).then((response) => {
      setPrelimNumber(response.data.series_number);
    });
  }

  function async_delay(ts, val) {
    return new Promise((resolve) => {
      setTimeout(resolve, ts, val);
    });
  }

  const saveVer = (formData, setErrors) => {
    return verAPI
      .save(companyId, formData)
      .catch(async (error) => {
        if (error.data.__all__ === "vat_diff_error") {
          const answer = await confirmExecute(t("confirm.diffTransactionVerifications"));
          if (answer) {
            await saveVer({ ...formData, allow_incorrect_vat_deviation: true }, setErrors);
          }
          return;
        }
        if (error.status !== 403) {
          toast.error(t("msg:fixErrors"));
        }
        if (error.data.__all__) {
          error.data.transactions = error.data.__all__;
          delete error.data.__all__;
        }
        setErrors(error.data);
      })
      .then(async (response) => {
        if (!response) {
          return;
        }
        // used only in create as on update file is uploaded instantly on selection
        const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
        if (_newFiles.length) {
          await verAPI.document.uploadMany(companyId, response.data.id, _newFiles, scannedFileId).catch((error) => {
            toast.error(error.data?.__all__);
          });
          if (response.data.new_assets) {
            const assetPromises = [];
            for (let i = 0; i < response.data.new_assets.length; i++) {
              assetPromises.push(
                assetAPI.uploadAsset(companyId, response.data.new_assets[i], _newFiles).catch(() => {
                  // pass silently
                })
              );
            }
            await Promise.all(assetPromises);
          }
        }
        toast.success(t("msg:saved"));
        closeModal();
        if (
          hasPermForInventoryRead &&
          isAssetToAutoAddBasedOnTransactions(formData.transactions.filter((tran) => !tran.id))
        ) {
          window.open(
            `${
              pathname.includes("consult") ? "/consult" : ""
            }/inventory-register?draftsForVer=ver. ${response.data.series}${response.data.series_number}`,
            "_blank"
          );
        } else {
          document.body.dispatchEvent(
            new CustomEvent("verification/saved", {
              detail: { ...formData, ...response.data },
            })
          );
        }
        if (docIDsAttached) {
          async_delay(3000).then(function () {
            return reloadMenuNumbers();
          });
        }
        if (formData.create_another) {
          async_delay(200).then(function () {
            openModal(companyId, { booking_date: parseDate(formData.booking_date), create_another: true });
          });
        }
      });
  };
  const formikProps = {
    initialValues: scannedReceipt ?? initialReceipt,
    validationSchema: yup.object().shape({
      booking_date: yup
        .date()
        .nullable()
        .required()
        .test("error", function (value) {
          const { createError } = this;
          if (company.lock_accounting_date && value <= new Date(company.lock_accounting_date)) {
            return createError({
              message: t("errors.bookingInLockedDate", { lockAccountingDate: company.lock_accounting_date }),
            });
          }
          return true;
        }),
      title: yup.string().required(),
      transactions: yup
        .array()
        .ensure()
        .of(
          yup
            .object()
            .shape({
              credit: yup.number().positive(t("errors.creditPositive")).nullable(),
              debit: yup.number().nullable().positive(t("errors.debitPositive")),
              is_deleted: yup.bool(),
            })
            .test("error", function (transaction, context) {
              const { account, cost_center, project, debit, credit, uid } = transaction;
              const { booking_date } = context.options.from[1].value;
              if (validateTransactions.indexOf(uid) > -1) {
                if (account && !debit && !credit) {
                  return this.createError({
                    path: `${this.path}.error`,
                    message: t("errors.transactionNoDebitAndCredit"),
                  });
                }
                setValidateTransactions(validateTransactions.filter((i) => i !== uid));
              }
              if (transaction.is_deleted === true) {
                return true;
              }
              if (account) {
                if (account.project_option === "mandatory" && !project) {
                  return this.createError({
                    path: `${this.path}.error`,
                    message: t("errors.projectMustBeSet"),
                  });
                }
                if (account.cost_center_option === "mandatory" && !cost_center) {
                  return this.createError({
                    path: `${this.path}.error`,
                    message: t("errors.centerMustBeSet"),
                  });
                }
              }
              if (transaction.periodisation_config?.status === "creating") {
                if (!transaction.periodisation_config.date_start) {
                  return this.createError({
                    path: `${this.path}.error`,
                    message: t("errors.periodisationDateStartMustBeSet"),
                  });
                }
                if (!transaction.periodisation_config.months) {
                  return this.createError({
                    path: `${this.path}.error`,
                    message: t("errors.periodisationMonthsMustBeSet"),
                  });
                }
                if (transaction.periodisation_config.months > 60) {
                  return this.createError({
                    path: `${this.path}.error`,
                    message: `${t("common:months", { max: 60 })}: ${t("errors.invalid.max", { max: 60 })}`,
                  });
                }
                if (transaction.periodisation_config.date_start && booking_date) {
                  const error_msg = getPeriodisationStartDateInvalidMsg(
                    t,
                    transaction.periodisation_config.date_start,
                    booking_date,
                    false
                  );
                  if (error_msg) {
                    // earliest day is first day of 1 month before from booking_date
                    return this.createError({
                      path: `${this.path}.error`,
                      message: error_msg,
                    });
                  }
                }
                if (!transaction.periodisation_config.pl_account) {
                  return this.createError({
                    path: `${this.path}.error`,
                    message: t("errors.periodisationPlAccountMustBeSet"),
                  });
                }
                if (
                  transaction.periodisation_config.date_start &&
                  transaction.periodisation_config.months &&
                  transaction.project &&
                  transaction.project.date_end
                ) {
                  const finishDateOfPeriodisation = new Date(transaction.periodisation_config.date_start.getTime());
                  const endMonth =
                    parseInt(finishDateOfPeriodisation.getMonth(), 10) +
                    parseInt(transaction.periodisation_config.months, 10) -
                    1;
                  finishDateOfPeriodisation.setMonth(endMonth);
                  const projectEndDate = new Date(transaction.project.date_end);
                  if (finishDateOfPeriodisation > projectEndDate) {
                    return this.createError({
                      path: `${this.path}.error`,
                      message: t("ver:errors.periodisationFinishAfterProjectEnds"),
                    });
                  }
                }
              }
              return true;
            })
        )
        .test("min2", function (values) {
          const { createError } = this;
          const validTrans = values.filter((tr) => !!tr.account);
          if (validateTransactions.indexOf("min2") > -1) {
            if (validTrans.length < 2) {
              return createError({
                path: "transactionsArray",
                message: t("errors.min2transactions"),
              });
            }
            setValidateTransactions(validateTransactions.filter((i) => i !== "min2"));
          }
          return true;
        })
        .test("diffEqual", function (values) {
          const { createError } = this;
          const trans = values.filter((tr) => !tr.is_deleted);
          const debit = roundMoney(collectionSum(trans, "debit"));
          const credit = roundMoney(collectionSum(trans, "credit"));
          const diff = roundMoney(debit - credit);
          if (validateTransactions.indexOf("diffEqual") > -1) {
            if (diff !== 0) {
              return createError({
                path: "transactionsArray",
                message: t("errors.transactionDoesNotBalance", {
                  diff: formatMoney(diff),
                }),
              });
            }
            setValidateTransactions(validateTransactions.filter((i) => i !== "diffEqual"));
          }
          return true;
        }),
    }),

    onSubmit: async (values, { setErrors }) => {
      const formData = {
        ...verification,
        ...values,
        title: values?.title?.trim?.() ?? values?.title ?? "",
        booking_date: formatISO(values.booking_date, {
          representation: "date",
        }),
        archives_to_connect: docIDsAttached,
        newDocuments: undefined,
        allow_incorrect_vat_deviation: false,
        transactions: values.transactions
          .filter((tr) => !!tr.account)
          .map((transaction) => getTransactionPayload(transaction)),
      };
      const executeAnyway = await checkYears(values.booking_date);
      if (!executeAnyway) {
        return null;
      }
      return saveVer(formData, setErrors);
    },
  };

  const onDeleteNoTrace = async () => {
    const answer = await confirmRemove(
      t("confirm.removeNoTrace", {
        invoiceNo: `${verification.series}${verification.series_number}`,
      })
    );
    if (answer) {
      verAPI
        .removeLastSeries(companyId, verification.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reloadMenuNumbers();
          document.body.dispatchEvent(
            new CustomEvent("verification/saved", {
              detail: { ...verification },
            })
          );
          document.body.dispatchEvent(
            new CustomEvent("verification/deleted", {
              detail: { ...verification },
            })
          );
          closeModal();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    }
  };

  const onRemoveVer = async () => {
    const answer = await confirmRemove(
      t(verification.has_assets ? "confirm.removeWithAssetInfo" : "confirm.remove", {
        invoiceNo: `${verification.series}${verification.series_number}`,
      })
    );
    let answerForAVSDelete = false;
    if (answer && verification.series === "AVS") {
      answerForAVSDelete = await confirmRemove(t("confirm.removeAVS"));
    } else {
      answerForAVSDelete = true;
    }
    if (answer && answerForAVSDelete) {
      return verAPI
        .remove(companyId, verification.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reloadMenuNumbers();
          document.body.dispatchEvent(
            new CustomEvent("verification/saved", {
              detail: { ...verification },
            })
          );
          document.body.dispatchEvent(
            new CustomEvent("verification/deleted", {
              detail: { ...verification },
            })
          );
          closeModal();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    }
    return null;
  };

  const handleConfirmRescan = useCallback(
    async (file, verName) => {
      try {
        setIsReceiptScanning(true);

        const response = await verAPI.document.ocrScan(companyId, file);

        setScannedFileId(response.data.file_id);

        const transactions = response.data.transactions.map((item) => {
          fetchBalance(item.account);

          return {
            ...item,
            account: accountOptions.find((a) => a.number === item.account),
            debit: item.amount > 0 ? item.amount : null,
            credit: item.amount < 0 ? Math.abs(item.amount) : null,
          };
        });

        const data = {
          ...verification,
          ...response.data,
          title: response.data.title || verName,
          transactions: [...[...verification.transactions].slice(0, -1), ...transactions],
        };

        setScannedReceiptReceipt(() => {
          setIsReinitialized(true);

          return data;
        });
      } catch (error) {
        // pass silently
      } finally {
        setIsReinitialized(false);
        setIsReceiptScanning(false);
      }
    },
    [accountOptions, companyId, fetchBalance, verification]
  );

  const handleNewDocumentChange = async (verId, file, index, isFormDirty) => {
    setIsReinitialized(false);
    if (verId) {
      // if ver. is already created then auto upload, no need to press save btn
      // remove from NewDocuments
      documentAPI.onNewFileChange({
        file: null,
        index,
        allFiles: newFiles,
        setFile: setNewFiles,
      });
      verAPI.document
        .uploadMany(companyId, verId, file)
        .then((response) => {
          setSavedDocuments((docs) => [...docs, response.data.documents[0]]);
          document.body.dispatchEvent(
            new CustomEvent("verification/saved", {
              detail: { id: verId, documents: savedDocuments },
            })
          );
        })
        .catch(() => {
          // pass silently
        });
    } else {
      documentAPI.onNewFileChange({
        file,
        index,
        allFiles: newFiles,
        setFile: setNewFiles,
      });
    }

    if (isManualVerification && file && !isFormDirty && !verification.id) {
      const answer = await confirmExecute(confirmMessageOnEmptyVer);

      if (answer) {
        handleConfirmRescan(file[0]);
      }
    }
  };

  useEffect(() => {
    const isNewFileExist = newFiles.some((item) => !!item?.file);
    const isSavedDocumentsExist = !!savedDocuments.length;

    setIsRescanAvailable(isNewFileExist || isSavedDocumentsExist);
  }, [newFiles, savedDocuments]);

  const setFormDirty = (setFieldValue, fieldValue) => {
    const modifiedValue = fieldValue?.length === fieldValue.trim()?.length ? `${fieldValue} ` : fieldValue.trim();

    setFieldValue("title", modifiedValue);
  };

  const handleRescanReceipt = async (isFormDirty, verName, setFieldValue) => {
    let answer = false;

    if (isFormDirty && !verification.id) {
      answer = await confirmExecute(confirmMessageOnNonEmptyVer);
    } else if (!isFormDirty && !verification.id) {
      answer = await confirmExecute(confirmMessageOnEmptyVer);
    } else if (verification.id) {
      answer = await confirmExecute(confirmMessageOnOldVerExiting);
    }

    if (answer) {
      let file = null;

      if (savedDocuments.length) {
        const receiptFile = await verAPI.document
          .file(companyId, verification.id, savedDocuments[0])
          .then((response) => {
            const blob = response.data;
            const fileName = getFileNameDispositionContent(response) || "";

            return {
              url: blob,
              fileName,
              type: blob.type,
              size: blob.size,
              loading: false,
              error: "",
            };
          });

        file = new File([receiptFile.url], receiptFile.fileName, { type: receiptFile.type });
      } else {
        file = newFiles[0].file;
      }

      await handleConfirmRescan(file, verName);

      setFormDirty(setFieldValue, verName);
    }
  };

  const accountsForPeriodisationCostAccount = useMemo(() => {
    return accountOptions.filter(filterPeriodisationCostAccountsForVer);
  }, [accountOptions]);
  const disconnectDoc = (docId) => {
    setDocIDsAttached([...docIDsAttached.filter((eachDocId) => docId !== eachDocId)]);
  };
  return (
    <Formik {...formikProps} key={verification.id} enableReinitialize={isReinitialized}>
      {({ values, errors, isSubmitting, dirty, setFieldValue }) => {
        return (
          <Form noValidate onKeyDown={enterKeyDownAsTabForVerModal}>
            {isReceiptScanning && <TableLoader />}
            <Row noGutters className="verification-container">
              <Col md={8} className="verification-details">
                <Modal.Header className="bg-primary">
                  <h5 className="text-white">
                    <VerificationTitle
                      t={t}
                      preliminary={verification.preliminary}
                      series={verification.series}
                      series_number={verification.series_number}
                      prelim_number={prelimNumber}
                    />
                  </h5>
                </Modal.Header>
                <Modal.Body>
                  <RelatedInfo
                    related={verification.related}
                    current={verification}
                    onSelect={(item) => openModal(companyId, item)}
                    sie_file_name={verification.sie_file_name}
                  />
                  <fieldset disabled={isDeleted}>
                    <VerificationForm
                      isConsult={isConsult}
                      accountOptions={accountOptions}
                      perCostAccountOptions={accountsForPeriodisationCostAccount}
                      accountById={accountById}
                      projectOptions={projectOptions}
                      centerOptions={centerOptions}
                      series={verification ? verification.series : "M"}
                      isDeleted={isDeleted}
                      verificationId={verification ? verification.id : null}
                      verificationSeries={verification.series}
                      hasPaymentSpecification={verification ? verification.has_payment_specification : false}
                      companyId={companyId}
                    />
                  </fieldset>
                  {verification?.has_assets && <Alert variant="info">{t("verificationIsContainingAssetsMsg")}</Alert>}
                  {isManualVerification && (
                    <Row>
                      <Col>
                        <Button
                          variant="secondary"
                          onClick={() => handleRescanReceipt(dirty, values.title, setFieldValue)}
                          disabled={!isRescanAvailable}
                        >
                          {t("actions.scanDocument", { ns: "common" })}
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Modal.Body>
              </Col>
              <Col md={4} className="verification-files">
                <Modal.Header className="bg-primary">
                  <h5 className="text-white">{t("verificationFiles")}</h5>
                  <button
                    type="button"
                    style={{ marginTop: -5 }}
                    className="btn font-20 float-right"
                    onClick={() => closeModal(dirty || docIDsAttached.length >= 1)}
                  >
                    <i className="mdi mdi-close" />
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <SavedVerificationDocuments
                    verificationId={verification.id}
                    companyId={companyId}
                    documents={savedDocuments}
                  />
                  <Card className="file-archive-attachments">
                    <fieldset disabled={isDeleted}>
                      <div className="control show-hide" onClick={() => setShowAttach(!showAttach)}>
                        {showAttach ? <i className="fe-chevron-up" /> : <i className="fe-chevron-down" />}
                        {t("actions.showAttachFileArchive")}
                      </div>
                      {showAttach && (
                        <div>
                          <ArchiveFilesToConnect
                            companyId={companyId}
                            docIDsAttached={docIDsAttached}
                            setDocIDsAttached={setDocIDsAttached}
                          />
                        </div>
                      )}
                    </fieldset>
                  </Card>
                  <>
                    {docIDsAttached.map((docId) => (
                      <DocumentBox
                        key={`sd.${docId}`}
                        partUrl="/documents/archive"
                        companyId={companyId}
                        documentId={docId}
                        removable={false}
                        extraActionData={{
                          divClassName: "remove",
                          actionName: t("common:actions.disconnect").toLowerCase(),
                          onAction: disconnectDoc,
                          documentId: docId,
                        }}
                      />
                    ))}
                  </>
                  <NewDocuments
                    documents={newFiles}
                    fileTypes={allowedFileExtensionsFor.verification}
                    multiple
                    onChange={({ file, index }) => handleNewDocumentChange(values.id, file, index)}
                  />
                </Modal.Body>
              </Col>
            </Row>
            <Footer
              t={t}
              isDeleted={verification.status === 2}
              canBeDeletedForever={verification.can_be_deleted_forever || false}
              onDeleteNoTrace={onDeleteNoTrace}
              onRemoveVer={onRemoveVer}
              closeModal={() => closeModal(dirty)}
              companyId={companyId}
              formProps={{ isSubmitting, values, errors, setFieldValue }}
              dirty={dirty}
              setValidateTransactions={setValidateTransactions}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

function Footer({
  isDeleted,
  formProps,
  closeModal,
  companyId,
  canBeDeletedForever,
  onDeleteNoTrace,
  onRemoveVer,
  t,
  dirty,
  setValidateTransactions,
}) {
  const { duplicate } = useVerificationDispatch();
  const isRemovable = formProps.values.id && formProps.values.series && formProps.values.series !== "C";
  const onDuplicate = async () => {
    await duplicate(companyId, formProps.values);
  };

  return (
    <Modal.Footer className="space" style={{ justifyContent: "center" }}>
      <div className="space-1">
        <Button variant="secondary" onClick={closeModal}>
          <i className="fas fa-arrow-left" /> {t("common:actions.cancel")}
        </Button>
        {isDeleted ? (
          <>
            <PermCodeRequired code={codesForVerifications.manage}>
              {canBeDeletedForever && isRemovable && (
                <Button variant="red" onClick={onDeleteNoTrace}>
                  <i className="fas fa-exclamation" /> {t("common:actions.deleteNoTrace")}
                </Button>
              )}
            </PermCodeRequired>
            <p className="font-20 mb-0 text-danger text-uppercase">{t("common:removed")}</p>
          </>
        ) : (
          <PermCodeRequired code={codesForVerifications.manage}>
            {formProps.values.id && formProps.values.status === 1 && (
              <Button variant="secondary" onClick={onDuplicate}>
                {t("common:actions.duplicate")}
              </Button>
            )}
            {isRemovable && (
              <Button variant="red" onClick={onRemoveVer}>
                <i className="fas fa-trash" /> {t("common:actions.removeVer")}
              </Button>
            )}
            {canBeDeletedForever && isRemovable && (
              <Button variant="danger" onClick={onDeleteNoTrace}>
                <i className="fas fa-exclamation" /> {t("common:actions.deleteNoTrace")}
              </Button>
            )}
            <SubmitButton
              isSubmitting={formProps.isSubmitting}
              onClick={(event) => {
                if (dirty) {
                  setValidateTransactions(
                    ["min2", "diffEqual"].concat(
                      formProps.values.transactions.filter((item) => item.account).map((item) => item.uid)
                    )
                  );
                }
              }}
            />
            <span
              className={cx("checkbox checkbox-primary")}
              onClick={() => formProps.setFieldValue("create_another", !formProps.values.create_another)}
            >
              <input
                type="checkbox"
                name={t("common:createAnother")}
                readOnly
                checked={!!formProps.values.create_another}
              />
              <label htmlFor="create_another">{t("common:createAnother")}</label>
            </span>
          </PermCodeRequired>
        )}
      </div>
    </Modal.Footer>
  );
}

function VerificationTitle({ t, preliminary, series, series_number, prelim_number }) {
  return (
    <>
      {t("verificationDetails")}
      <strong>
        {series_number || !prelim_number ? " " : " *"}
        {series}
        {series_number || prelim_number}
      </strong>
      {preliminary && <small> - {t("preliminary")}</small>}
    </>
  );
}

export default VerificationModalWrapper;
