import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { BankIDLoader } from "components/ui/loaders";
import { BasketContext } from "../BasketProvider";

function DecoupledStage() {
  const { t } = useTranslation("common");
  const {
    bic,
    error,
    isSameDevice,
    actions: { checkSigning, reset },
  } = useContext(BasketContext);
  const statusRef = useRef(false);
  const [data, setData] = useState({
    info: "",
    qrCode: null,
  });

  const checkStatusInterval = useMemo(() => {
    if (isSameDevice) {
      return 5000;
    }
    return bic === "NDEASESS" ? 2000 : 1000;
  }, [bic, isSameDevice]); // BFLAKT-2981

  useEffect(() => {
    const interval = setInterval(() => {
      if (!statusRef.current) {
        statusRef.current = true;
        checkSigning()
          .then((response) => {
            if (response.data.stage !== "") {
              setData({
                info: response.data.info,
                qrCode: response.data.image,
              });
            }
          })
          .finally(() => {
            statusRef.current = false;
          });
      }
    }, checkStatusInterval);

    return () => clearInterval(interval);
  }, [checkSigning, checkStatusInterval]);

  useEffect(() => {
    if (isSameDevice && data.qrCode) {
      console.log("OPEN", data.qrCode);
      window.open(data.qrCode, "_blank");
    }
  }, [isSameDevice, data.qrCode]);

  useEffect(() => {
    checkSigning().then((response) => {
      setData({
        info: response.data.info,
        qrCode: response.data.image,
      });
    });
  }, [checkSigning]);

  return (
    <>
      <div className="title">
        <h2>{t("basket.paymentsSigning")}</h2>
        {data.info && <h4>{data.info}</h4>}
        {error && <h4 className="text-error">{error || t("basket.error")}</h4>}
      </div>
      {isSameDevice ? (
        <Modal.Body className="stage-decoupled">
          <div>
            <i className="fas fa-spinner fa-spin font-24" />
            <p className="mt-1 font-18">{t("basket.searchBankId")}...</p>
          </div>
          <div>
            <Button variant="link" onClick={reset}>
              {t("actions.cancel")}
            </Button>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body className="stage-decoupled">
          {data.info && !error && !data.qrCode && <BankIDLoader bankIdLogo text={false} />}
          {!error && data.qrCode && (
            <>
              <img src={data.qrCode} alt="SCAN ME" />
              <div>
                <Button variant="link" onClick={reset}>
                  {t("actions.cancel")}
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      )}
    </>
  );
}

export default DecoupledStage;
