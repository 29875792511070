import { Button, Card } from "react-bootstrap";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as documentsAPI from "api2/documents";
import { archiveAuditorCategories } from "api/options";
import { confirmExecute, confirmRemove } from "components/modals/ConfirmModal";
import useModal from "hooks/useModal";
import { DocumentsModalRefactored } from "components/modals";
import { HasPermCode } from "components/perms";
import { codesForCompanyArchive } from "components/perms/PermCodes";
import cx from "classnames";
import { EXTRA_ALLOWED_FILE_SIZE_FOR } from "utils/file";
import "./CompanyArchiveCard.scss";

function getArchiveTemplate(initialCategory, financialYearId) {
  if (!initialCategory) {
    return {
      category: "other",
      title: "",
      description: "",
      financial_year: financialYearId,
      for_auditor: false,
      auditor_category: "",
    };
  }
  const auditorCats = archiveAuditorCategories.asDict();
  if (initialCategory in auditorCats) {
    let swappedCat = "other";
    if (initialCategory === "commitment_notice" || initialCategory === "largest_invoices") {
      swappedCat = "bank";
    } else if (initialCategory === "leasing_insurance") {
      swappedCat = "sup_agreement";
    } else if (initialCategory === "authorities_grants") {
      swappedCat = "auth_grant";
    }
    return {
      category: swappedCat,
      title: "",
      description: "",
      financial_year: financialYearId,
      for_auditor: true,
      auditor_category: initialCategory,
    };
  }
  return {
    category: initialCategory,
    title: "",
    description: "",
    financial_year: financialYearId,
    for_auditor: false,
    auditor_category: "",
  };
}

function UploadBtn({ onFilesSelected }) {
  const fileRef = useRef(null);

  return (
    <div className="upload-btn upload">
      <Button variant="secondary" size="sm" onClick={() => fileRef.current.click()}>
        <i className="fe-upload" />
      </Button>
      <input ref={fileRef} type="file" onChange={onFilesSelected} multiple />
    </div>
  );
}

function FetchButton({
  title = "actions.fetch",
  fetchFunction,
  disabled = false,
  icon = "fa-plus",
  companyId,
  financialYearId,
  reloadData,
  ...props
}) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);

  const fetch = () => {
    setLoading(true);
    fetchFunction().finally(() => {
      setLoading(false);
      reloadData();
    });
  };

  return (
    <div className="upload-btn fetch-btn">
      <Button type="submit" variant="secondary" size="sm" onClick={fetch} disabled={disabled} {...props}>
        {loading ? (
          <span>
            <i className="fas fa-spinner fa-pulse mr-1" /> {t("actions.processing")}...
          </span>
        ) : (
          <span>
            {icon && <i className={`fas mr-1 ${icon}`} />}
            {t(title)}
          </span>
        )}
      </Button>
    </div>
  );
}

function CardItem({ companyId, item, onDelete, hasPermForWrite, onToggleArchive }) {
  const navigate = useNavigate();
  const fileModal = useModal();
  return (
    <>
      <li
        key={item.id}
        className={cx("card-item", {
          archived: !!item.is_archived,
        })}
      >
        <div className="share">
          {item.for_customer && <i className="fe-user" />}
          {item.for_auditor && <i className="fe-clipboard" />}
        </div>
        <div className="details" onClick={fileModal.open}>
          <span>{item.title}</span>
        </div>
        {hasPermForWrite && (
          <>
            <i
              className="edit-btn fa fa-pencil-alt"
              onClick={() => navigate(`/archive/company-archive/${item.id}/change`)}
            />
            <i
              className={cx({
                "fe-rotate-cw edit-btn ml-0": !!item.is_archived,
                "fe-archive del-btn pr-1": !item.is_archived,
              })}
              onClick={() => onToggleArchive(item)}
            />
            <i className="del-btn fa fa-times-circle" onClick={() => onDelete(item)} />
          </>
        )}
      </li>
      {fileModal.show && (
        <DocumentsModalRefactored
          partUrl="documents/company"
          companyId={companyId}
          documentIds={[item.id]}
          handleClose={fileModal.close}
          removable={false}
        />
      )}
    </>
  );
}

function CompanyArchiveCard({ companyId, category, items, financialYearId, reloadData, projection, isConsult }) {
  const [dragging, setDragging] = useState(false);
  const { t } = useTranslation("options");
  const hasPermForWrite = HasPermCode(codesForCompanyArchive.manage);
  const onDelete = async (row) => {
    const answer = await confirmRemove(t("others:confirm.removeFileArchive", { title: row.title }));
    if (answer) {
      documentsAPI.companyDocuments
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          reloadData();
        })
        .catch(() => {});
    }
  };
  const onToggleArchive = async (row) => {
    const answer = await confirmExecute(
      t(row.is_archived ? "others:confirm.unarchive" : "others:confirm.archive"),
      t(row.is_archived ? "actions.yesUnarchive" : "actions.yesArchive"),
      "actions.cancel",
      true
    );
    if (answer) {
      documentsAPI.companyDocuments
        .toggleArchive(companyId, row.id)
        .then(() => {
          toast.success(t(row.is_archived ? "common:unarchived" : "common:archived"));
          reloadData();
        })
        .catch(() => {});
    }
  };
  const onFilesSelected = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!hasPermForWrite) {
      toast.error(t("msg:canNotExecuteAction"));
      setDragging(false);
      return false;
    }
    const selectedFiles = event.target.files || event.dataTransfer.files;
    if (!selectedFiles.length) {
      setDragging(false);
      return false;
    }

    const maxCount = 10;
    if (selectedFiles) {
      if (selectedFiles.length > maxCount) {
        setDragging(false);
        toast.error(t("msg:fileLengthLimit", { max: maxCount }));
        return false;
      }
    }
    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles.item(i).size > EXTRA_ALLOWED_FILE_SIZE_FOR.company_archive) {
        toast.error(
          t("msg:fileToBig", { max: (EXTRA_ALLOWED_FILE_SIZE_FOR.company_archive / (1024 * 1024)).toFixed(1) }),
          {
            autoClose: 4000,
          }
        );
        setDragging(false);
        return false;
      }
    }
    const promises = [];
    const body = getArchiveTemplate(category, financialYearId);
    let promise;
    for (let i = 0; i < selectedFiles.length; i++) {
      promise = documentsAPI.companyDocuments
        .save(companyId, [selectedFiles.item(i)], {
          ...body,
          title: selectedFiles.item(i).name,
          for_auditor: true,
          for_customer: true,
        })
        .then((response) => {
          toast.success(`${selectedFiles.item(i).name} ${t("msg:uploaded")}`);
          return response.data;
        })
        .catch((error) => {
          toast.error(error.data.__all__);
          return error.data;
        });
      promises.push(promise);
    }
    Promise.all(promises).then((results) => {
      setDragging(false);
      reloadData();
    });

    return true;
  };

  const onDragOver = (event) => {
    event.preventDefault();
    return false;
  };
  const onDragEnter = (event) => {
    setDragging(true);
    event.preventDefault();
    return false;
  };
  const onDragLeave = (event) => {
    event.preventDefault();
    setDragging(false);
  };

  if ((projection && !items.length) || (!isConsult && !items.length)) {
    return null;
  }

  const fetchCategories = ["bs_pl", "sie", "ledgers_today"];
  const fetchFunctions = {
    bs_pl: documentsAPI.companyDocuments.fetchBalanceSheetAndProfitAndLoss,
    sie: documentsAPI.companyDocuments.fetchSIEFile,
    ledgers_today: documentsAPI.companyDocuments.fetchCurrentCustomerAndSupplierLedgers,
  };
  const currentFetchFunction = fetchFunctions[category];
  const shouldDisplayFetchButton = isConsult && !projection && fetchCategories.includes(category);

  return (
    <div className="draggable" onDragEnter={onDragEnter}>
      {dragging && (
        <div
          className="draggable-layer"
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={onFilesSelected}
        />
      )}
      <Card className="archive-category-card">
        <Card.Header>{t(`cdc.${category}`)}</Card.Header>
        {items.length ? (
          <Card.Body className="files">
            <ul>
              {items.map((item) => (
                <CardItem
                  key={item.id}
                  companyId={companyId}
                  item={item}
                  onDelete={onDelete}
                  onToggleArchive={onToggleArchive}
                  hasPermForWrite={hasPermForWrite}
                />
              ))}
            </ul>
            {shouldDisplayFetchButton && (
              <FetchButton
                fetchFunction={() => currentFetchFunction(companyId, financialYearId)}
                companyId={companyId}
                financialYearId={financialYearId}
                reloadData={reloadData}
              />
            )}
            {hasPermForWrite && <UploadBtn onFilesSelected={onFilesSelected} />}
          </Card.Body>
        ) : (
          <Card.Body className="text-muted">
            <ul>
              <li className="text-muted">{t("common:noFilesUploaded")}</li>
            </ul>
            {shouldDisplayFetchButton && (
              <FetchButton
                fetchFunction={() => currentFetchFunction(companyId, financialYearId)}
                companyId={companyId}
                financialYearId={financialYearId}
                reloadData={reloadData}
              />
            )}
            {hasPermForWrite && <UploadBtn onFilesSelected={onFilesSelected} />}
          </Card.Body>
        )}
      </Card>
    </div>
  );
}

export default CompanyArchiveCard;
