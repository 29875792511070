import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import * as billectaAPI from "api/billecta";

function BankPaymentDate({ selectedDate }) {
  const { t } = useTranslation("common");
  const [paymentDate, setPaymentDate] = useState(null);

  useEffect(() => {
    const signal = axios.CancelToken.source();
    if (selectedDate) {
      billectaAPI
        .bankPaymentDate(selectedDate, { cancelToken: signal.token })
        .then((response) => {
          setPaymentDate(response.data.bank_payment_date);
        })
        .catch((error) => {
          // pass
        });
    } else {
      setPaymentDate(null);
    }
    return () => {
      signal.cancel("aborted");
    };
  }, [selectedDate]);

  return (
    <>
      {t("dates.billectaPaymentDate")}
      <br /> {paymentDate || "-"}
    </>
  );
}

export default BankPaymentDate;
