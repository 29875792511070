import _ from "lodash";

import { addDays } from "date-fns";
import { formatDate } from "utils/date";
import { rotRutByValue } from "./rotRutTypes";
import { roundMoney } from "../money";

function floor(value) {
  // Math.floor(10.3) => 10, Math.floor(-10.3) => -11
  // needed 10.3 -> 10, -10.3 -> -10
  if (value < 0) {
    return Math.ceil(value);
  }
  return Math.floor(value);
}

export function toBillectaAmount(amount, currency) {
  // billecta require that all amounts are sent in cents (not all currencies have cents)
  return ["ISK", "JPY", "KRW"].includes(currency) ? roundMoney(amount, 0) : amount * 100;
}

export function fromBillectaAmount(amount, currency) {
  // billecta require that all amounts are sent in cents (not all currencies have cents)
  return ["ISK", "JPY", "KRW"].includes(currency) ? amount : amount / 100;
}

export function calculateRecordTotal(record) {
  if (!record || !record.product) {
    return 0;
  }

  const partial = record.product.unit_price * record.product.quantity;
  if (record.discount && record.discount.value) {
    const discountType = _.isObject(record.discount.type) ? record.discount.type.value : record.discount.type;
    if (discountType === "percent") {
      return partial - (Math.abs(record.discount.value) / 100) * partial;
    }
    return partial - record.discount.value;
  }

  return partial;
}

export function calculateRecordRotRutTotal(record) {
  const total = calculateRecordTotal(record);

  let type;
  if (!record.product || record.product.unit_price < 0) {
    return 0;
  }
  if (record.rot_rut_activated) {
    type = typeof record.rot_rut.type === "string" ? rotRutByValue(record.rot_rut.type).type : record.rot_rut.type.type;
    if (type === "ROT") {
      return floor(total * (1 + record.product.vat_percent.value / 100) * 0.3);
    }
    if (type === "RUT") {
      return floor(total * (1 + record.product.vat_percent.value / 100) * 0.5);
    }
  }
  return 0;
}

export function calculateRecordsRotRutTotal(records) {
  return records.reduce((total, record) => total + calculateRecordRotRutTotal(record), 0);
}

export function autoFillRotRut(values) {
  const newRecords = values.records.map((record) => ({
    ...record,
    rot_rut: record.rot_rut_activated
      ? {
          ...record.rot_rut,
          amount: calculateRecordRotRutTotal(record),
          hours: record.product ? record.product.quantity || 1 : 0,
        }
      : { ...record.rot_rut, amount: 0, hours: 0 },
  }));
  const total = newRecords.reduce((subtotal, record) => subtotal + record.rot_rut.amount, 0);
  const newCustomers = values.RotRutDetails.customers.map((customer) => ({
    ...customer,
    amount: floor(total / values.RotRutDetails.customers.length),
  }));
  const customersTotal = newCustomers.reduce((subtotal, customer) => subtotal + customer.amount, 0);
  const diff = total - customersTotal;
  if (newCustomers.length && diff !== 0) {
    newCustomers[newCustomers.length - 1].amount += diff;
  }
  return {
    records: newRecords,
    RotRutDetails: {
      ...values.RotRutDetails,
      customers: newCustomers || [],
    },
  };
}

export function validRotRut(records) {
  const toDeduct = calculateRecordsRotRutTotal(records);
  const deducted = records.reduce((total, item) => total + item.RotRutAmount || 0, 0);
  return Math.abs(deducted) <= Math.abs(toDeduct);
}

export function getSummaryNet(standardRecords) {
  return standardRecords.reduce((sum, record) => {
    return sum + calculateRecordTotal(record);
  }, 0);
}

export function getSummaryVat(reversedVat, standardRecords) {
  if (reversedVat) {
    return 0;
  }
  let totalNet = 0;
  return standardRecords.reduce((sum, record) => {
    totalNet = calculateRecordTotal(record);
    return sum + (totalNet * record.product.vat_percent.value) / 100;
  }, 0);
}

export function getTaxReduction(standardRecords) {
  return -1 * standardRecords.reduce((total, item) => total + (item.rot_rut ? item.rot_rut.amount : 0), 0);
}

export function formatRotRut(invoiceData, currency) {
  if (!invoiceData.rot_rut) {
    return null;
  }
  const taxReduction = getTaxReduction(invoiceData.records.filter((item) => !!item.rot_rut));
  if (taxReduction === 0) {
    return null;
  }
  return {
    property_designation: invoiceData.rot_rut.property_designation,
    residence_org_no: invoiceData.rot_rut.residence_org_no,
    customers: invoiceData.rot_rut.customers.map((item) => ({
      ...item,
      amount: toBillectaAmount(item.amount, currency),
    })),
    work_start: formatDate(invoiceData.rot_rut.work_start),
    work_end: formatDate(invoiceData.rot_rut.work_end),
  };
}

export function formatRotRutRecord(record, currency) {
  if (!record.rot_rut) {
    return null;
  }
  return {
    ...record.rot_rut,
    amount: toBillectaAmount(record.rot_rut.amount, currency),
    material_cost: toBillectaAmount(record.rot_rut.material_cost, currency),
  };
}

export function formatDiscount(record) {
  if (!record.discount) {
    return null;
  }
  if (record.discount.type === "amount") {
    return {
      type: record.discount.type,
      value: roundMoney(record.discount.value),
    };
  }
  return {
    type: record.discount.type,
    value: Math.abs(parseFloat(record.discount.value)),
  };
}

export function formatData(values, forOffer = false, forOrder = false) {
  const data = _.cloneDeep({ ...values, records: [...values.records] });
  const dueDate = addDays(data.booking_date, parseInt(data.payment_terms.value, 10) || 30);
  const currency = data.amount_currency.value;

  const extraOfferData = forOffer
    ? {
        valid_date: formatDate(addDays(data.booking_date, parseInt(data.valid_period.value, 10) || 30)),
      }
    : {};

  const extraOrderData = forOrder
    ? {
        order_date: formatDate(data.order_date),
        delivery_date: formatDate(data.delivery_date),
      }
    : {};
  return {
    ...data,
    extra_receivers: data.extra_receivers || [],
    draft: data.draft,
    customer: data.customer.value,
    delivery_method: data.delivery_method || "email",
    payment_terms: data.payment_terms.value,
    amount: 0,
    amount_currency: data.amount_currency.value,
    booking_date: formatDate(data.booking_date),
    due_date: formatDate(dueDate),
    price_group: data.price_group.value,
    rot_rut: formatRotRut(data, currency),
    your_reference: data.your_reference || "",
    ...extraOfferData,
    ...extraOrderData,
    records: data.records.map((record, idx) => {
      if (record.record_type === "product") {
        return {
          old: record.product.old || {}, // TODO: TO remove
          record_type: "product",
          message: null,
          rot_rut: formatRotRutRecord(record, currency),
          discount: formatDiscount(record, currency),
          product: {
            ...record.product,
            product_number: record.product.product_number || "",
            unit_price: roundMoney(toBillectaAmount(record.product.unit_price, currency)),
            quantity: roundMoney(record.product.quantity),
            vat_percent:
              record.product.vat_percent && !data.reversed_vat ? parseInt(record.product.vat_percent.value, 10) : 0,
          },
          sequence_no: idx + 1,
        };
      }
      return {
        old: record.old || {}, // TODO: TO remove
        record_type: "message",
        message: {
          ...record.message,
        },
        rot_rut: null,
        discount: null,
        product: null,
        sequence_no: idx + 1,
      };
    }),
  };
}
