import React, { useCallback, useEffect, useRef, useState } from "react";
import { ErrorMessage, useField, useFormikContext } from "formik";
import cx from "classnames";
import { Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useDebouncedCallback } from "use-debounce";

import { toast } from "react-toastify";
import Mexp from "math-expression-evaluator";
import { useTranslation } from "react-i18next";
import { BDatePicker, BSelect, BSelectAsync } from "./pickers";
import {
  containsOnlyAllowedCharacters,
  formatForEval,
  formatWithDecimalComma,
  formatWithDecimalPoint,
  isStartsWithEqual,
  isValidExpression,
  removeSpacesFromString,
} from "./utils/moneyCalculation";
import { formatMoney } from "../../utils/money";

const mexp = new Mexp();

function Input({ name, type = "text", label, required = false, helpText, tdProps, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });

  const debounced = useDebouncedCallback((event) => {
    const target = event.target || event.currentTarget;
    helpers.setValue(target.value);
  }, 500);

  return (
    <td className={cx({ "single-has-error": !!meta.error })} {...tdProps}>
      <Form.Control
        name={field.name}
        onBlur={field.onBlur}
        defaultValue={field.value}
        onChange={debounced}
        type={type}
        isInvalid={!!meta.error}
        autoComplete="off"
        {...props}
      />
    </td>
  );
}

function ControlledInput({ name, type = "text", label, required = false, helpText, tdProps, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });

  const onChange = (event) => {
    const target = event.target || event.currentTarget;
    helpers.setValue(target.value);
  };

  return (
    <td className={cx({ "single-has-error": !!meta.error })} {...tdProps}>
      <Form.Control
        name={field.name}
        onBlur={field.onBlur}
        value={field.value}
        onChange={onChange}
        type={type}
        isInvalid={!!meta.error}
        autoComplete="off"
        {...props}
      />
    </td>
  );
}

function NumberInput({ name, label, required = false, helpText, tdProps, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });
  return (
    <td className={cx({ "single-has-error": !!meta.error })} {...tdProps}>
      <Form.Control
        {...field}
        name={field.name}
        onChange={(event) => {
          helpers.setValue((event.target || event.currentTarget).value);
        }}
        type="number"
        isInvalid={!!meta.error}
        autoComplete="off"
        {...props}
      />
    </td>
  );
}

function MoneyInput({ name, tdProps, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });
  const maxValue = props.maxvalue ?? 9999999999.99;
  const minValue = props.minvalue ?? -9999999999.99;
  return (
    <td className={cx({ "single-has-error": !!meta.error })} {...tdProps}>
      <NumberFormat
        name={field.name}
        thousandSeparator={" "}
        decimalSeparator=","
        autoComplete="off"
        decimalScale={2}
        value={field.value}
        isAllowed={({ floatValue }) => !floatValue || (floatValue >= minValue && floatValue <= maxValue)}
        className={cx(["money-input form-control", { "is-invalid": !!meta.error }, props.className])}
        fixedDecimalScale
        onValueChange={(values) => helpers.setValue(values.floatValue)}
        {...props}
      />
    </td>
  );
}

function MoneyCalculationInput({ index, name, tdProps, ...props }) {
  const { t } = useTranslation("ci");
  const inputRef = useRef();
  const { setStatus } = useFormikContext();
  const [focused, setFocused] = useState(false);
  const [field, meta, helpers] = useField({ name, ...props });

  const [expression, setExpression] = useState(field.value && formatWithDecimalComma(field.value));
  const isDisabled = props?.disabled;
  const setFormInvalidToSubmit = useCallback(() => {
    setStatus(true);
  }, [setStatus]);

  const handleChange = (event) => {
    setStatus(false);

    const exp = removeSpacesFromString(event.target.value);

    if (!isValidExpression(exp) && exp.length) {
      return;
    }

    if (!isStartsWithEqual(exp) && containsOnlyAllowedCharacters(exp)) {
      const resultWithDecimalPoint = formatWithDecimalPoint(exp);

      helpers.setValue(resultWithDecimalPoint);
      setExpression(exp);

      return;
    }

    helpers.setValue(field.value);
    setExpression(exp);
  };

  const handleSubmit = useCallback(
    (event) => {
      const exp = event.target.value;

      if (isStartsWithEqual(exp)) {
        if (exp.length === 1) {
          const resultWithDecimalComma = formatWithDecimalComma(field.value);

          setExpression(resultWithDecimalComma);
        } else {
          try {
            const expressionForEval = formatForEval(exp);

            const resultOfEval = mexp.eval(expressionForEval);

            if (!Number.isFinite(resultOfEval)) {
              setFormInvalidToSubmit();

              throw new Error(t("isNotValidAmount"));
            }

            const resultWithDecimalPoint = formatWithDecimalPoint(resultOfEval);

            const resultWithDecimalComma = formatWithDecimalComma(resultWithDecimalPoint);

            helpers.setValue(resultWithDecimalPoint);
            setExpression(resultWithDecimalComma);
          } catch (error) {
            setFormInvalidToSubmit();

            toast.error(`${t("cannotRead")} "${exp}"`);
          }
        }
      } else if (exp.length && !containsOnlyAllowedCharacters(exp)) {
        setFormInvalidToSubmit();

        toast.error(`"${expression}" ${t("isNotValidAmount")}`);
      } else {
        const resultWithDecimalPoint = formatWithDecimalPoint(exp);
        const resultWithDecimalComma = formatWithDecimalComma(resultWithDecimalPoint);

        helpers.setValue(resultWithDecimalPoint);
        setExpression(resultWithDecimalComma);
      }
    },
    [expression, field.value, helpers, setFormInvalidToSubmit, t]
  );
  const onFocusTd = async (event) => {
    if (isDisabled) {
      return;
    }
    await setFocused(true);
    inputRef.current.focus();
  };
  const handleEnterPress = useCallback(
    (event) => {
      if (event.key !== "Enter") {
        return;
      }

      handleSubmit(event);
      setFocused(false);
    },
    [handleSubmit]
  );
  const value = expression === undefined || expression === null ? "" : expression; // BFLAKT-2210
  return (
    <td className={cx({ "single-has-error": !!meta.error, disabled: isDisabled })} onClick={onFocusTd} {...tdProps}>
      <Form.Control
        ref={inputRef}
        type="input"
        name={field.name}
        value={value}
        disabled={isDisabled}
        className={cx(["money-input form-control", { "is-invalid": !!meta.error }, props.className])}
        style={{ opacity: focused ? 1 : 0 }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={(event) => {
          props?.onBlur?.(event);
          handleSubmit(event);
          setFocused(false);
        }}
        onChange={(event) => {
          props?.onChange?.(event);
          handleChange(event);
        }}
        onKeyDown={(event) => {
          props?.onKeyDown?.(event);
          handleEnterPress(event);
        }}
      />
      {!focused && <div className="faked-input">{field.value ? formatMoney(field.value) : ""}</div>}
    </td>
  );
}

function MoneyCalculationTableInput({ index, name, tdProps, ...props }) {
  const { t } = useTranslation("ci");

  const { setStatus } = useFormikContext();
  const inputRef = useRef();
  const [field, meta, helpers] = useField({ name, ...props });

  const [expression, setExpression] = useState("");
  const [focused, setFocused] = useState(false);
  const isDisabled = props?.disabled;
  const setFormInvalidToSubmit = useCallback(() => {
    setStatus(true);
  }, [setStatus]);

  useEffect(() => {
    if (field.value === "") {
      setExpression("");
    }
  }, [field.value]);

  function handleChange(event) {
    setStatus(false);

    const exp = event.target.value;

    if (!isValidExpression(exp) && exp.length) {
      return;
    }

    if (!isStartsWithEqual(exp) && containsOnlyAllowedCharacters(exp)) {
      const resultWithDecimalPoint = formatWithDecimalPoint(exp);

      helpers.setValue(resultWithDecimalPoint);
      setExpression(exp);

      return;
    }

    helpers.setValue(field.value);
    setExpression(exp);
  }

  const handleSubmit = useCallback(
    (event) => {
      const exp = event.target.value;

      if (isStartsWithEqual(exp)) {
        if (exp.length === 1) {
          const resultWithDecimalComma = formatWithDecimalComma(field.value);

          setExpression(resultWithDecimalComma);
        } else {
          try {
            const expressionForEval = formatForEval(exp);

            const resultOfEval = mexp.eval(expressionForEval);

            if (!Number.isFinite(resultOfEval)) {
              setFormInvalidToSubmit();

              throw new Error(t("isNotValidAmount"));
            }

            const resultWithDecimalPoint = formatWithDecimalPoint(resultOfEval);

            const resultWithDecimalComma = formatWithDecimalComma(resultWithDecimalPoint);

            helpers.setValue(resultWithDecimalPoint);
            setExpression(resultWithDecimalComma);
          } catch (error) {
            setFormInvalidToSubmit();

            toast.error(`${t("cannotRead")} "${exp}"`);
          }
        }
      } else if (exp.length && !containsOnlyAllowedCharacters(exp)) {
        setFormInvalidToSubmit();

        toast.error(`"${expression}" ${t("isNotValidAmount")}`);
      } else {
        const resultWithDecimalPoint = formatWithDecimalPoint(exp);
        const resultWithDecimalComma = formatWithDecimalComma(resultWithDecimalPoint);

        if (!exp.length) {
          helpers.setValue(undefined);
          setExpression("");

          return;
        }

        helpers.setValue(resultWithDecimalPoint);
        setExpression(resultWithDecimalComma);
      }
    },
    [expression, field.value, helpers, setFormInvalidToSubmit, t]
  );

  const handleEnterPress = useCallback(
    (event) => {
      if (event.key !== "Enter") {
        return;
      }

      handleSubmit(event);
      setFocused(false);
    },
    [handleSubmit]
  );

  useEffect(() => {
    if (typeof field.value === "number") {
      setExpression(formatWithDecimalComma(field.value));
    }
  }, [field.value]);

  const onFocusTd = async (event) => {
    if (isDisabled) {
      return;
    }
    await setFocused(true);
    inputRef.current.focus();
  };

  return (
    <td className={cx({ "single-has-error": !!meta.error, disabled: isDisabled })} {...tdProps} onClick={onFocusTd}>
      <Form.Control
        type="input"
        ref={inputRef}
        name={field?.name}
        value={expression || ""}
        disabled={isDisabled}
        className={cx([
          "money-input form-control",
          {
            "is-invalid": !!meta.error,
          },
          props.className,
        ])}
        style={{ opacity: focused ? 1 : 0 }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={(event) => {
          props?.onBlur?.(event);
          handleSubmit(event);
          setFocused(false);
        }}
        onChange={(event) => {
          props?.onChange?.(event);
          handleChange(event);
        }}
        onKeyDown={(event) => {
          props?.onKeyDown?.(event);
          handleEnterPress(event);
        }}
      />
      {!focused && <div className="faked-input">{field.value ? formatMoney(field.value) : ""}</div>}
    </td>
  );
}

function SimpleSelect({
  name,
  options,
  tdProps,
  onChange,
  classNamePrefix = "table-select",
  filterOptionStartsWith = false,
  ...props
}) {
  const [field, meta, helpers] = useField({ name });
  const title = field.value ? field.value.label : "";

  const onBlur = (e) => {
    helpers.setTouched(e);

    if (props.onBlur) {
      props.onBlur();
    }
  };

  const onSelected = (selected, a, b, c) => {
    helpers.setValue(selected, true);
    if (onChange) {
      onChange(selected);
    }
  };

  return (
    <td
      className={cx({ "single-has-error": !!meta.error, disabled: props.isDisabled }, "select")}
      {...tdProps}
      title={title}
    >
      {props.rightComponent}
      <BSelect
        // field={field}
        name={field.name}
        tabSelectsValue
        value={field.value}
        {...props}
        options={options}
        onBlur={onBlur}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        menuPosition="fixed"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999, width: 320 }),
          ...props.styles,
          groupHeading: (base) => ({ ...base, fontSize: 16, color: "#666" }),
        }}
        className="table-select"
        classNamePrefix={classNamePrefix}
        onChange={onSelected}
        filterOptionStartsWith={filterOptionStartsWith}
      />
    </td>
  );
}

function AsyncSelect({
  name,
  loadOptions,
  minSearchLength = 1,
  required = false,
  onChange,
  tdProps,
  preventSetValue = false,
  ...props
}) {
  const [field, meta, helpers] = useField({ name, ...props });
  const title = field.value ? field.value.label : "";
  return (
    <td
      className={cx({ "single-has-error": !!meta.error, disabled: props.isDisabled }, "select")}
      {...tdProps}
      title={title}
    >
      <BSelectAsync
        field={field}
        {...props}
        loadOptions={loadOptions}
        minSearchLength={minSearchLength}
        onBlur={(e) => helpers.setTouched(e)}
        className="table-select"
        classNamePrefix="table-select"
        onChange={(selected) => {
          if (onChange) {
            onChange(selected);
          }
          if (!preventSetValue) {
            helpers.setValue(selected);
          }
        }}
      />
    </td>
  );
}

function DatePicker({ name, tdProps = {}, onChange, ...props }) {
  const { className, ...colProps } = tdProps;
  const [field, meta, helpers] = useField({ name, ...props });
  const handleChange = (date) => {
    helpers.setValue(date);
    if (onChange) {
      onChange(date);
    }
  };
  return (
    <td {...colProps} className={cx({ disabled: props.disabled, "single-has-error": !!meta.error }, className)}>
      <BDatePicker field={field} {...props} onChange={handleChange} />
    </td>
  );
}

function DateMonthPicker({ name, tdProps: { className, ...tdProps }, onChange, ...props }) {
  const [field, meta, helpers] = useField({ name, ...props });
  const dateFormat = "MM/yyyy";
  const handleChange = (date) => {
    helpers.setValue(date);
    if (onChange) {
      onChange(date);
    }
  };
  return (
    <td {...tdProps} className={cx({ "single-has-error": !!meta.error, disabled: props.disabled }, className)}>
      <BDatePicker field={field} dateFormat={[dateFormat]} showMonthYearPicker onChange={handleChange} {...props} />
      <Form.Control.Feedback type="invalid">
        <ErrorMessage name={name} />
      </Form.Control.Feedback>
    </td>
  );
}

function RowErrors({ errors, cols = 7 }) {
  if (!errors || (errors && typeof errors === "string")) {
    return null;
  }
  return (
    <tr>
      <td colSpan={cols} className="has-errors">
        <ul>
          {Object.values(errors).map((error, index) => (
            <li key={`er.${index}`}>{error}</li>
          ))}
        </ul>
      </td>
    </tr>
  );
}

function Checkbox({ name, id, label, wrapperClass, disabled = false, ...props }) {
  const [field, , helpers] = useField({ name, ...props });

  function checkToggle(event) {
    event.preventDefault();
    if (!disabled) {
      helpers.setValue(!field.value);
      if (props.onChange) {
        props.onChange(!field.value);
      }
    }
  }

  return (
    <td onClick={checkToggle}>
      <div className={cx("checkbox custom-control checkbox-primary", wrapperClass)}>
        <input
          type="checkbox"
          className="d-none"
          name={name}
          id={id || `id_${name}`}
          checked={field.value}
          onChange={checkToggle}
        />
        <label className="custom-control-label" htmlFor={id || `id_${name}`}>
          {label}
        </label>
      </div>
    </td>
  );
}

function TextAreaInput({ name, tdProps, required = false, ...props }) {
  const [field, meta] = useField({ name, ...props });
  return (
    <td className={cx("td-area", { "single-has-error": !!meta.error, disabled: props.disabled })} {...tdProps}>
      <Form.Control
        {...field}
        required={required}
        as="textarea"
        style={{
          resize: "none",
          overflow: "hidden",
        }}
        {...props}
      />
    </td>
  );
}

const TableGroup = {
  Input,
  TextAreaInput,
  DateMonthPicker,
  ControlledInput,
  NumberInput,
  MoneyInput,
  MoneyCalculationInput,
  MoneyCalculationTableInput,
  SimpleSelect,
  DatePicker,
  Checkbox,
  RowErrors,
  AsyncSelect,
};

export default TableGroup;
