import React from "react";
import useTable from "components/tables/btable/useTable";
import CustomerForm from "./CustomerForm";

function CustomerEditWidget({ companyId, customer }) {
  const {
    dataActions: { updateRow },
  } = useTable();

  const successCallback = (newData) => {
    updateRow(newData, "id");
  };
  const customerData = {
    ...customer,
    identification: customer.identification || "",
    address2: customer.address2 || "",
    customer_number: customer.customer_number || "",
    emails: customer.emails || [],
    vat_number: customer.vat_number || "",
    your_reference: customer.your_reference || "",
    our_reference: customer.our_reference || "",
    address: customer.address || "",
    zip_code: customer.zip_code || "",
    city: customer.city || "",
  };
  return <CustomerForm companyId={companyId} customer={customerData} successCallback={successCallback} />;
}

export default CustomerEditWidget;
