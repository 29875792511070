import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ProductForm from "components/forms/ProductForm";
import { DraggableModalDialog } from "../DraggableModalDialog";

function ProductAddModal({ companyId, onSuccess, onCancel }) {
  const { t } = useTranslation("ci");
  const product = {
    name: "",
    product_type: "service",
    product_number: "",
    unit: "",
    vat_percent: 25,
    unit_price: null,
    b_unit_price: null,
    c_unit_price: null,
    d_unit_price: null,
    cost_center: null,
    account: null,
  };

  const onSave = () => {
    if (onSuccess) {
      onSuccess();
    } else {
      onCancel();
    }
  };

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={onCancel}
      dialogClassName="product-modal"
      size="lg"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.addProduct")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProductForm companyId={companyId} product={product} successCallback={onSave} />
      </Modal.Body>
    </Modal>
  );
}

export default ProductAddModal;
