function filterProvidedAccounts(revenuesAccountsList) {
  return function (option) {
    return revenuesAccountsList.includes(option.number);
  };
}
// !!! it is also limited on backend - so on each change update list in backend also  !!!!!
const getListWithAccountsForRevenue = (vat, productType) => {
  const stringifiedVat = String(vat);
  if (productType === "service" && stringifiedVat === "25") {
    return [
      3010, 3011, 3012, 3013, 3014, 3015, 3016, 3017, 3018, 3019, 3040, 3041, 3060, 3115, 3125, 3135, 3145, 3155, 3330,
      3561, 3680, 3910, 3998,
    ];
  }
  if (productType === "product" && stringifiedVat === "25") {
    return [3010, 3011, 3012, 3013, 3014, 3015, 3016, 3017, 3018, 3019, 3040, 3051, 3561, 3998];
  }
  if (productType === "service" && stringifiedVat === "12") {
    return [3042, 3562, 3920];
  }
  if (productType === "product" && stringifiedVat === "12") {
    return [3052, 3562, 3920];
  }
  if (productType === "service" && stringifiedVat === "6") {
    return [3043, 3563, 3921];
  }
  if (productType === "product" && stringifiedVat === "6") {
    return [3053, 3563, 3921];
  }
  if (productType === "service" && stringifiedVat === "0") {
    return [3020, 3021, 3022, 3023, 3024, 3025, 3026, 3027, 3028, 3029, 3044, 3160, 3560];
  }
  if (productType === "product" && stringifiedVat === "0") {
    return [3020, 3021, 3022, 3023, 3024, 3025, 3026, 3027, 3028, 3029, 3054, 3560];
  }
  return [];
};

const getDefaultAccountForRevenue = (vat, productType) => {
  const stringifiedVat = String(vat);
  if (productType === "service" && stringifiedVat === "25") {
    return 3041;
  }
  if (productType === "product" && stringifiedVat === "25") {
    return 3051;
  }
  if (productType === "service" && stringifiedVat === "12") {
    return 3042;
  }
  if (productType === "product" && stringifiedVat === "12") {
    return 3052;
  }
  if (productType === "service" && stringifiedVat === "6") {
    return 3043;
  }
  if (productType === "product" && stringifiedVat === "6") {
    return 3053;
  }
  if (productType === "service" && stringifiedVat === "0") {
    return 3044;
  }
  if (productType === "product" && stringifiedVat === "0") {
    return 3054;
  }
  return null;
};

export { getListWithAccountsForRevenue, filterProvidedAccounts, getDefaultAccountForRevenue };
