import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

import CustomerInvoiceForm from "../../../../components/forms/CustomerInvoiceForm";

function OfferAddPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const offer = {
    customer: null,
    booking_date: new Date(),
    status: 0,
    sub_status: "",
    amount_currency: "SEK",
    vat_amount: 0,
    payment_terms: company.default_invoice_payment_terms,
    delivery_method: "email",
    reversed_vat: false,
    reversed_vat_receiver_no: "",
    records: [],
    rot_rut: null,
  };

  const onCreate = (invoice) => {
    navigate(-1);
  };

  return <CustomerInvoiceForm invoice={offer} company={company} onSave={onCreate} forOffer />;
}

export default OfferAddPage;
